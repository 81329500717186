// imports
import React from "react";

// styles
import styles from "./styles.module.css";

const BgBlurredBtn = () => {
  return (
    <button
      className={`${styles.button} rounded-md text-white hover:bg-sky-800 bg-sky-500 py-1.5 px-4 text-base transition-all`}
      onClick={() =>
        window.open(
          "https://www.risklens.com/my-industry-cybersecurity-benchmark",
          "_blank"
        )
      }
    >
      Find out more
    </button>
  );
};

export default BgBlurredBtn;
