// imports
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

// config
import config from "../../config";

// context
import Context from "../../context";

export default function IndustryMarketingIngest() {
  // use context
  const { setIndustryEmail } = useContext(Context);

  const [error, setError] = useState(false);

  // history
  const history = useHistory();

  //Match up UTM string from marketing site to industry Id
  function industryConversion(utmIndustry){
    switch(utmIndustry) {
      case "healthcare":
        return "Healthcare";
      case "public-administration":
        return "PublicAdministration";
      case "Finance-insurance":
        return "FinanceandInsurance";
      case "information":
        return "Information";
      case "Education":
        return "EducationalServices";
      case "professional-scientific-technical":
        return "ProfessionalScientificandTechnicalServices";
      case "accommodations-food":
        return "AccommodationandFoodServices";
      case "manufacturing":
        return "Manufacturing";
      case "retail":
        return "Retail"
      default:
        return "ProfessionalScientificandTechnicalServices";
    }
  }


  useEffect(() => {
    const location = window.location.href;
    try {
        const queryString = location.split('?')[1].split('&');
        const ingestIndustryString = queryString[0].split('=')[1];
        const ingestEmailString = queryString[1].split('=')[1];

        if (ingestEmailString && ingestIndustryString) {
          let industryId = industryConversion(ingestIndustryString);
          axios.post(config.apiGateway.URL + "/users", {
              email: ingestEmailString,
              industryId: industryId,
            })
            .then((response) => {
              if (!response.data) {
                setError(true);
              } else {
                localStorage.setItem("industryUserLoggedIn", true);
                localStorage.setItem(
                  "industryUserEmail",
                  response.data.pk.replace("USER#", "")
                );
                setIndustryEmail(response.data.pk.replace("USER#", ""));
                history.push("/industry/report");
              }
            })
            .catch((e) => console.log(e));
          return null;
        }

    } catch(e) {
      console.log(e);
    }
  }, []);
  
  return (
    <div className='bg-slate-50 h-screen w-screen flex flex-col justify-between'>
      <div className='flex-1 py-8 flex flex-col items-center justify-center'>
          {error && (
            <p className='text-red text-sm leading-5 font-medium pt-4'>
              An error has occurred. Please try again!
            </p>
          )}
      </div>
    </div>
  );
}
