// imports
import React from "react";

// utils
import { formatNumber, formatPercentage } from "../../../util/helpers";

// components
import IndustryDynamicText from "./IndustryDynamicText";
import BenchmarkDynamicText from "./BenchmarkDynamicText";

const BreakdownParagraph = ({
	data,
	industryData,
	benchmarkData,
	blur,
	descRef,
	dynamicDescRef,
}) => {
	// get percent difference
	const percentDifference = (industryPercent, benchmarkPercent) => {
		const diff = benchmarkPercent - industryPercent;
		return {
			diff: diff.toString().replaceAll("-", ""),
			text: diff > 0 ? "more" : "less",
		};
	};

	// get cost difference
	const lossDifference = (industryLoss, benchmarkLoss) => {
		const diff = benchmarkLoss - industryLoss;
		return {
			diff: diff.toString().replaceAll("-", ""),
			text: diff > 0 ? "more" : "less",
		};
	};

	// get title
	const selectTitle = (theme) => {
		switch (theme) {
			case "Error":
				return "An Insider Error";
			case "Insider Misuse":
				return "An Insider Misuse";
			case "System Intrusion":
				return "A System Intrusion";
			case "Ransomware":
				return "A Ransomware";
			case "Denial of Service":
				return "A Denial of Service";
			case "Basic Web Application Attacks":
				return "A Basic Web Application Attacks";
			case "Social Engineering (Phishing)":
				return "A Social Engineering";
			default:
				return "A Insider Error";
		}
	};

	return (
		<div className="text-primary mb-6 text-lg text-justify leading-normal">
			<div ref={descRef}>
				{benchmarkData && (
					<p className="mb-3">
						Compared to your industry segment, your company is{" "}
						<b>
							{formatPercentage(
								percentDifference(
									industryData.Probability[0].Value,
									benchmarkData.Probability[0].Value
								)?.diff
							)}
							%{" "}
							{
								percentDifference(
									industryData.Probability[0].Value,
									benchmarkData.Probability[0].Value
								)?.text
							}
						</b>{" "}
						likely to experience{" "}
						{selectTitle(benchmarkData.Theme).toLowerCase()} event per year.{" "}
						{selectTitle(benchmarkData.Theme)} event at your company is
						forecasted to cost{" "}
						<b>
							$
							{formatNumber(
								lossDifference(
									industryData.Loss[0].Value,
									benchmarkData.Loss[0].Value
								)?.diff
							)}{" "}
							{
								lossDifference(
									industryData.Loss[0].Value,
									benchmarkData.Loss[0].Value
								)?.text
							}
						</b>{" "}
						than an average event in your industry segment.
					</p>
				)}
				{data.breakdown_paragraph}
			</div>
			{benchmarkData && (
				<BenchmarkDynamicText
					dynamicDescRef={dynamicDescRef}
					industryData={industryData}
					benchmarkData={benchmarkData}
				/>
			)}
			{!blur && !benchmarkData && (
				<IndustryDynamicText
					dynamicDescRef={dynamicDescRef}
					data={industryData}
				/>
			)}
		</div>
	);
};

export default BreakdownParagraph;
