// imports
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// helpers
import { formatNumber } from "../../util/helpers";

// components
import ReportCardDescBenchmarkDynamic from "./ReportCardDescBenchmarkDyanmic";
import ReportCardDescIndustryDynamic from "./ReportCardDescIndustryDyanmic";

// styles
const styles = StyleSheet.create({
  desc: {
    marginBottom: 8,
  },
  textOne: {
    fontSize: 9,
    marginBottom: 8,
    color: "#1A2B3C",
  },
  bold: {
    fontWeight: "bold",
    color: "#1A2B3C",
  },
});

const ReportCardDescription = ({ industryData, benchmarkData, industry }) => {
  // select desc
  const selectDesc = (theme) => {
    switch (theme) {
      case "Error":
        return (
          <Text style={styles.textOne}>
            When you think of a large-scale cyber event, an evil villain often
            comes to mind. The more likely, and often just as costly, cause of
            those bad days is human error. Things like misconfigurations,
            failure to renew expired certificates, and improper publishing can
            lead to business interruption or data breach events that can have
            damaging consequences to the bottom line.
          </Text>
        );
      case "Insider Misuse":
        return (
          <>
            <Text style={styles.textOne}>
              Employees are valuable assets, entrusted with the knowledge,
              skills, and resources to ensure the success of the company in
              meeting its objectives. But what happens when the knowledge,
              skills, and resources are misused? Unfortunately, traditional
              preventative measures are often ineffective because users have
              elevated access to superseding countermeasures.
            </Text>
            <Text style={styles.textOne}>
              Whether it is the intentional and malicious disclosure or
              modification of sensitive data, a logic bomb taking down the
              network, or an innocent workaround that unwittingly compromises an
              asset, your most trusted employees have the ability to cause
              significant loss to the company.
            </Text>
          </>
        );
      case "System Intrusion":
        return (
          <Text style={styles.textOne}>
            System intrusion events are every CISO's nightmare. Through code
            exploitation or good old-fashioned brute force password guessing, a
            bad actor gets a foothold in your network and is able to access your
            most sensitive assets. Whether resulting in a data breach or
            business interruption, this type of event is sure to make headlines.
            This category does not include ransomware or social
            engineering-based attacks.
          </Text>
        );
      case "Ransomware":
        return (
          <Text style={styles.textOne}>
            Ransomware is a type of malware-based cyberattack that is designed
            to pressure a company to pay a ransom by encrypting and withholding
            access to systems or files. While traditionally thought of as a
            worm-based virus targeting low-hanging fruit, bad actors are now
            using more advanced forms referred to as ransomware 2.0 or double
            extortion ransomware. These new variants involve gaining a network
            foothold to target assets that are more sensitive in nature and then
            using data exfiltration and threatened leakage to apply additional
            pressure after the asset has been encrypted. This results in a data
            breach in addition to the original business interruption.
          </Text>
        );
      case "Denial of Service":
        return (
          <Text style={styles.textOne}>
            Denial of service events are designed to do exactly that: deny
            service to the intended users of the system by making the system
            unavailable, leading to a business interruption. This type of attack
            accomplishes this by flooding the target with traffic or sending it
            information that triggers a crash.
          </Text>
        );
      case "Basic Web Application Attacks":
        return (
          <Text style={styles.textOne}>
            Web application attacks considered in this category are any attacks
            that target and compromise a web application, either via brute force
            or code exploitation. These attacks can result in a business
            interruption or data breach, depending on the goals of the attacker.
            Web application attacks can also be used to gain a foothold in the
            environment which can be used to target other critical assets. Those
            types of complex, secondary motive attacks are captured in the
            system intrusion category.
          </Text>
        );
      case "Social Engineering (Phishing)":
        return (
          <Text style={styles.textOne}>
            Social engineering attacks are one of the most basic forms of
            attack, preying on human nature to manipulate individuals into
            sharing confidential or personal information or to perform an action
            that would benefit the attacker. These attacks typically involve a
            well-designed email appearing to be sent from a friend, colleague,
            or well-known brand. Other attacks use different mediums such as
            voice or SMS to achieve similar results. These attacks often result
            in a data breach, but can also lead to a business interruption.
          </Text>
        );
      default:
        return (
          <Text style={styles.textOne}>
            When you think of a large-scale cyber event, an evil villain often
            comes to mind. The more likely, and often just as costly, cause of
            those bad days is human error. Things like misconfigurations,
            failure to renew expired certificates, and improper publishing can
            lead to business interruption or data breach events that can have
            damaging consequences to the bottom line.
          </Text>
        );
    }
  };

  // get percent difference
  const percentDifference = (industryPercent, benchmarkPercent) => {
    const diff = benchmarkPercent - industryPercent;
    return {
      diff: diff.toString().replaceAll("-", ""),
      text: diff > 0 ? "more" : "less",
    };
  };

  // get cost difference
  const lossDifference = (industryLoss, benchmarkLoss) => {
    const diff = benchmarkLoss - industryLoss;
    return {
      diff: diff.toString().replaceAll("-", ""),
      text: diff > 0 ? "more" : "less",
    };
  };

  // get title
  const selectTitle = (theme) => {
    switch (theme) {
      case "Error":
        return "An Insider Error";
      case "Insider Misuse":
        return "An Insider Misuse";
      case "System Intrusion":
        return "A System Intrusion";
      case "Ransomware":
        return "A Ransomware";
      case "Denial of Service":
        return "A Denial of Service";
      case "Basic Web Application Attacks":
        return "A Basic Web Application Attacks";
      case "Social Engineering (Phishing)":
        return "A Social Engineering";
      default:
        return "A Insider Error";
    }
  };

  return (
    <View style={styles.desc}>
      {selectDesc(benchmarkData ? benchmarkData.Theme : industryData.Theme)}
      {benchmarkData && (
        <Text style={styles.textOne}>
          Compared to your industry segment, your company is{" "}
          <Text style={styles.bold}>
            {(
              100 *
              percentDifference(
                industryData.Probability[0].Value,
                benchmarkData.Probability[0].Value
              )?.diff
            ).toFixed(2)}
            %{" "}
            {
              percentDifference(
                industryData.Probability[0].Value,
                benchmarkData.Probability[0].Value
              )?.text
            }
          </Text>{" "}
          likely to experience {selectTitle(benchmarkData.Theme).toLowerCase()}{" "}
          event per year. {selectTitle(benchmarkData.Theme)} event at your
          company is forecasted to cost{" "}
          <Text style={styles.bold}>
            $
            {formatNumber(
              lossDifference(
                industryData.Loss[0].Value,
                benchmarkData.Loss[0].Value
              )?.diff
            )}{" "}
            {
              lossDifference(
                industryData.Loss[0].Value,
                benchmarkData.Loss[0].Value
              )?.text
            }
          </Text>{" "}
          than an average event in your industry segment.
        </Text>
      )}
      {benchmarkData && (
        <ReportCardDescBenchmarkDynamic
          industryData={industryData}
          benchmarkData={benchmarkData}
          industry={industry}
        />
      )}
      {!benchmarkData && (
        <ReportCardDescIndustryDynamic
          data={industryData}
          industry={industry}
        />
      )}
    </View>
  );
};

export default ReportCardDescription;
