// imports
import { Fragment, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";

// icons
import { XIcon, CheckCircleIcon } from "@heroicons/react/outline";

// assets
import LinkedinIcon from "../SocialShareCard/assets/linkedin.png";
import TwitterIcon from "../SocialShareCard/assets/twitter.png";
import MailIcon from "../common/Navbar/assets/mail.svg";
import { ShareIcon } from "@heroicons/react/solid";

// context
import Context from "../../context";

// utils
import { twitterShare } from "../../util/constants";

// config
import config from "../../config";

import axios from "axios";

export default function SlideOver() {
	// use context
	const { industryEmail, industry, showSlideOver, setShowSlideOver } = useContext(Context);

	const handleSocialSharingClick = async (sharingType) => {
		await axios.post(config.apiGateway.URL + "/socialshare", {
			sharedEmail: industryEmail,
			sharingType: sharingType
		})
	};

	return (
		<Transition.Root show={showSlideOver} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={setShowSlideOver}>
				<div className="fixed inset-0" />
				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full">
								<Dialog.Panel className="pointer-events-auto w-screen max-w-md">
									<div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
										<div className="bg-light-blue py-6 px-4 sm:px-6">
											<div className="flex items-center justify-between">
												<Dialog.Title className="text-lg font-medium text-white">
													Share to get My Cyber Risk Benchmark for free!
												</Dialog.Title>
												<div className="ml-3 flex h-7 items-center">
													<button
														type="button"
														className="rounded-md bg-blue text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
														onClick={() => setShowSlideOver(false)}>
														<span className="sr-only">Close panel</span>
														<XIcon className="h-6 w-6" aria-hidden="true" />
													</button>
												</div>
											</div>
										</div>
										<div className="relative flex-1 py-6 px-5 sm:px-6 text-primary mb-6 text-sm text-justify leading-normal">
											<ul className="flex flex-col gap-5 m-0 p-0">
												<li className="flex">
													<CheckCircleIcon className="h-4 mr-2 flex-shrink-0 mt-1" />{" "}
													Share a link to sign-up for the Industry Cyber Risk
													report via LinkedIn, Twitter, or email to start
													earning discounts on an annual My Cyber Risk Benchmark
													subscription.
												</li>
												<li className="flex">
													<CheckCircleIcon className="h-4 mr-2 flex-shrink-0 mt-1" />{" "}
													Automatically earn a 10% discount by sharing the link
													via LinkedIn or Twitter.
												</li>
												<li className="flex">
													<CheckCircleIcon className="h-4 mr-2 flex-shrink-0 mt-1" />{" "}
													Earn an additional 10% off for each user who signs up
													using one of your links. Discounts are cumulative and
													can add up to a free annual subscription.
												</li>
											</ul>
											<p className="mt-6">
												The discount code will be provided via email. The
												discount amount will be automatically updated to reflect
												the total cumulative discount earned. Limit of 2
												attributed sign-up discounts from new users signing up
												from your own company.
											</p>
										</div>
										<div className="py-6 px-5 sm:px-6 text-primary mb-6 text-sm leading-normal flex flex-row justify-between items-center gap-2">
											<p className="m-0">
												Earn your free discount today by sharing this report!
											</p>
											<div className="py-6 px-5 sm:px-6 text-primary mb-6 text-sm text-justify leading-normal flex flex-row justify-between items-center gap-1 flex-shrink-0">
												<ShareIcon className="mr-1 h-8 text-blue-icon" />
												<a
													href={"https://www.linkedin.com/sharing/share-offsite/?url=" + encodeURIComponent(document.location.origin + "/?sharedEmail=" + industryEmail + "&sharingType=linkedin")}
													target="_blank"
													className="block cursor-pointer flex items-center"
													rel="noreferrer">
													<img
														src={LinkedinIcon}
														alt="mail"
														className="mr-1 h-10"
														onClick={() => handleSocialSharingClick("linkedin")}/>
												</a>
												<a
													href={twitterShare(encodeURIComponent(industryEmail))}
													target="_blank"
													rel="noreferrer"
													className="block cursor-pointer flex items-center">
													<img
														src={TwitterIcon}
														alt="mail"
														className="mr-1 h-10"
														onClick={() => handleSocialSharingClick("twitter")}/>
												</a>
												<a
													href={`mailto:?subject=Here are our industry's most severe cyber event losses&body=${encodeURIComponent(document.location.origin + "/?sharedEmail=" + industryEmail + "&sharingType=email")}`}
													className="block cursor-pointer flex items-center">
													<img
														src={MailIcon}
														alt="mail"
														className="mr-1 h-10"
													/>
												</a>
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
