// imports
import { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// config
import config from "../../config";

// styles
import styles from "./styles.module.css";

// components
import Footer from "../../components/common/Footer";
import Input from "../../components/common/Input";

// assets
import Logo from "../../assets/logo/logo.svg";

// context
import Context from "../../context";

import { Auth } from "aws-amplify";

import { useAppContext } from "../../lib/contextLib";

export default function BenchmarkSignUp() {
	// use context
	const { setIndustryEmail } = useContext(Context);

	// states
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [error, setError] = useState(false);
	const [newUser, setNewUser] = useState(null);
	const [confirmationCode, setConfirmationCode] = useState("");
	const [subscriptionError, setSubscriptionError] = useState(false);
	const { userHasAuthenticated } = useAppContext();

	// history
	const history = useHistory();

	async function onConfirmationSubmit(e) {
		e.preventDefault();
		setLoading(true);

		try {
			await Auth.confirmSignUp(email, confirmationCode);
			await Auth.signIn(email, password);

			setLoading(false);
			userHasAuthenticated(true);
			localStorage.setItem("industryUserLoggedIn", true);
			localStorage.setItem("industryUserEmail", email);
			setIndustryEmail(email);
			history.push("/benchmark/report");
		} catch (e) {
			setError(e);
			setLoading(false);
		}
	}

	async function onSubmit(e) {
		e.preventDefault();
		if (email && password === confirmPassword) {
			setLoading(true);

			try {
				// Validate Stripe Payment
				let response = await (
					await fetch(`${config.apiGateway.URL}/subscriptions/${email}`)
				).json();

				if (response.subscriptionValid) {
					//Create the cognito user first, then create or update the dynamo user
					const newUser = await Auth.signUp({
						username: email,
						password: password,
						attributes: {
							email: email,
						},
					});
					setNewUser(newUser);
					setLoading(false);
				} else {
					// If not valid payment tell them to use the right email
					setSubscriptionError(true);
					setLoading(false);
				}
			} catch (e) {
				setError(e);
				setLoading(false);
			}
		}
	}

	function renderConfirmationForm() {
		return (
			<div className="bg-slate-50 h-screen w-screen flex flex-col justify-between">
				<div className="flex flex-col items-center justify-center">
					<img src={Logo} alt="logo" className="h-12 mt-12 mb-2" />
					<h1 className="text-gray-900 text-2xl font-extrabold mt-6">
						Create your My Cyber Risk Benchmark account
					</h1>
				</div>
				<div className="flex-1 py-8 flex flex-col items-center justify-center">
					<form
						onSubmit={onConfirmationSubmit}
						className={`${styles.form} rounded-lg bg-white py-8 px-10`}>
						<Input
							label="Confirmation Code"
							type="number"
							value={confirmationCode}
							onChange={(e) => setConfirmationCode(e.target.value)}
							placeholder="Confirmation Code"
							pattern="[0-9]{6}$"
							required
						/>
						<button
							type="submit"
							className={`${styles.button} bg-blue-800 w-96	flex items-center justify-center text-white text-sm leading-5 font-medium py-2 rounded-md	`}>
							{loading ? "Loading" : "Create Account"}
						</button>
						{error && (
							<p className="text-red text-sm leading-5 font-medium pt-4">
								An error has occurred. Please try again!
							</p>
						)}
						<p className="text-sm text-gray-500 text-center mt-4">
							Already have an account?{" "}
							<Link
								to={"./benchmarkSignIn"}
								className="text-blue-800 cursor-pointer">
								Sign in
							</Link>
						</p>
					</form>
				</div>
				<Footer transparent />
			</div>
		);
	}

	function renderForm() {
		return (
			<div className="bg-slate-50 h-screen w-screen flex flex-col justify-between">
				<div className="flex flex-col items-center justify-center">
					<img src={Logo} alt="logo" className="h-12 mt-12 mb-2" />
					<h1 className="text-gray-900 text-2xl font-extrabold mt-6">
						Create your My Cyber Risk Benchmark account
					</h1>
				</div>
				<div className="flex-1 py-8 flex flex-col items-center justify-center">
					<form
						onSubmit={onSubmit}
						className={`${styles.form} rounded-lg bg-white py-8 px-10`}>
						<Input
							label="Email Address"
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder="Email"
							pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
							required
						/>
						<Input
							label="Password"
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							placeholder="Password"
							pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,100}$"
							required
						/>
						<Input
							label="Confirm Password"
							type="password"
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
							placeholder="Confirm Password"
							pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,100}$"
							required
						/>
						<div class="mt-6">
							<div class="relative">
								<div class="relative flex justify-left text-sm mb-4">
									<p class="text-gray-500">
										By clicking Create Account, you agree to our&nbsp;
										<Link
											to="/terms-of-use"
											className="text-blue-800 cursor-pointer">
											Terms of Service
										</Link>
										.
									</p>
								</div>
							</div>
						</div>
						<button
							type="submit"
							className={`${styles.button} bg-blue-800 w-96	flex items-center justify-center text-white text-sm leading-5 font-medium py-2 rounded-md	`}>
							{loading ? "Loading" : "Create Account"}
						</button>
						{error && (
							<p className="text-red text-sm leading-5 font-medium pt-4">
								An error has occurred. Please try again!
							</p>
						)}
						{subscriptionError && (
							<p className="text-red text-sm leading-5 font-medium pt-4">
								No subscription found.
								<br />
								Be sure to use the same email as your payment.
								<br />
								Otherwise contact{" "}
								<a href="mailto:support@risklens.com">RiskLens Support</a>
							</p>
						)}
						<p className="text-sm text-gray-500 text-center mt-4">
							Already have an account?{" "}
							<Link
								to={"./benchmarkSignIn"}
								className="text-blue-800 cursor-pointer">
								Sign in
							</Link>
						</p>
					</form>
				</div>
				<Footer transparent />
			</div>
		);
	}

	return (
		<div className="flex-1 py-8 flex flex-col items-center justify-center">
			{newUser === null ? renderForm() : renderConfirmationForm()}
		</div>
	);
}
