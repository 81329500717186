// imports
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { formatNumber } from "../../util/helpers";

// styles
const styles = StyleSheet.create({
	textOne: {
		fontSize: 9,
		marginBottom: 8,
		color: "#1A2B3C",
	},
	textEnd: {
		fontSize: 9,
		color: "#1A2B3C",
	},
	bold: {
		fontWeight: "bold",
		color: "#1A2B3C",
	},
});

const ReportCardDescBenchmarkDynamic = ({
	industryData,
	benchmarkData,
	industry,
}) => {
	return (
		<View style={styles.desc}>
			<Text style={styles.textEnd}>
				In the {industry} industry, a {benchmarkData.Theme} event has a{" "}
				<Text style={styles.bold}>
					{(100 * industryData?.Probability[0]?.Value).toFixed(2)}%
				</Text>{" "}
				probability of occurring per year and is expected to cost approximately{" "}
				<Text style={styles.bold}>
					${formatNumber(industryData?.Loss[0]?.Value)}
				</Text>
				. In your company, the same event has an{" "}
				<Text style={styles.bold}>
					{(100 * benchmarkData.Probability[0].Value).toFixed(2)}%
				</Text>{" "}
				probability of occurring and is expected to cost approximately{" "}
				<Text style={styles.bold}>
					${formatNumber(benchmarkData.Loss[0].Value)}
				</Text>
				.
			</Text>
		</View>
	);
};

export default ReportCardDescBenchmarkDynamic;
