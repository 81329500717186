// imports
import { useContext } from 'react';
import { getQuarterYYYYAfter15 } from "../../util/helpers";

// components
import Card from '../../components/Card';
import Navbar from '../../components/common/Navbar';
import PageHeading from '../../components/common/PageHeading';
import LearnPracticesComponent from '../../components/LearnPracticesComponent';
import LearnMore from '../../components/LearnMore';
import Scorecard from '../../components/Scorecard';
import Filter from '../../components/common/Filter';
import Footer from '../../components/common/Footer';
import BenchmarkPDFReport from '../../components/BenchmarkPDFReport';

// utils
import {
	errorCardDataBenchmark,
	insiderMisuseCardDataBenchmark,
	systemIntrusionCardDataBenchmark,
	ransomwareCardDataBenchmark,
	denialOfServiceCardDataBenchmark,
	socialEngineeringCardDataBenchmark,
	webApplicationCardDataBenchmark,
} from '../../util/fakedata';

// api
import {
	useGetBenchmarkFilter,
	useGetIndustryReport,
	useGetBenchmarkReport,
} from '../../util/api';

// context
import Context from '../../context';

const Benchmark = () => {
	// context
	const {
		industryEmail,
		industry,
		region,
		employee,
		revenue,
		recordsNumber,
		recordsType,
		grade,
		showBenchmarkPdf,
	} = useContext(Context);

	// get data hook
	const {
		data: filterData,
		isLoading: filterLoading,
		error: filterError,
	} = useGetBenchmarkFilter();

	// get industry report data hook
	const {
		data: industryReportData,
		isLoading: industryReportLoading,
		error: industryReportError,
	} = useGetIndustryReport({
		email: industryEmail,
		industryId: industry.industryId || 'Information',
		regionId: region.regionId || 'NA',
		employeesId: employee.employeesId || '1_to_50',
	});

	// get benchmark report data hook
	const {
		data: benchmarkReportData,
		isLoading: benchmarkReportLoading,
		error: benchmarkReportError,
	} = useGetBenchmarkReport({
		email: industryEmail,
		industryId: industry.industryId,
		regionId: region.regionId,
		employeesId: employee.employeesId,
		revenueId: revenue.revenueId,
		recordsId: recordsNumber.recordsId,
		recordTypesId: recordsType.recordTypeId,
		SSCId: grade || 'B',
	});

	const staticData = (_theme) => {
		switch (_theme) {
			case 'Basic Web Application Attacks':
				return webApplicationCardDataBenchmark;
			case 'Denial of Service':
				return denialOfServiceCardDataBenchmark;
			case 'Error':
				return errorCardDataBenchmark;
			case 'Insider Misuse':
				return insiderMisuseCardDataBenchmark;
			case 'Ransomware':
				return ransomwareCardDataBenchmark;
			case 'Social Engineering (Phishing)':
				return socialEngineeringCardDataBenchmark;
			case 'System Intrusion':
				return systemIntrusionCardDataBenchmark;
			default:
				return errorCardDataBenchmark;
		}
	};

	return (
		<div className='bg-slate-100'>
			<div className='mx-auto'>
				<Navbar active='benchmark' />
				<div className='py-6 px-4'>
					<PageHeading
						heading={industry.industryName ?? 'Loading'}
						subtitle='Financial Impact of Top Cyber Events'
						date={
							benchmarkReportData?.length > 0
								? getQuarterYYYYAfter15(benchmarkReportData[0].DataLastUpdated)
								: "---- ----"
						}
					/>
					<div className='flex justify-between'>
						<div className='pr-4'>
							{benchmarkReportError || industryReportError ? (
								<p>An error has occurred</p>
							) : benchmarkReportLoading || industryReportLoading ? (
								<p>Loading...</p>
							) : benchmarkReportData.length === 0 ||
							  industryReportData.length === 0 ? (
								<p>Nothing to show.</p>
							) : (
								<div className='flex flex-col'>
									{benchmarkReportData
										.sort((a, b) => a.ThemeExposureRank - b.ThemeExposureRank)
										.map((item, index) => (
											<Card
												key={index}
												industryData={
													industryReportData?.find(
														(i) => i.Theme === item.Theme
													) ?? {}
												}
												benchmarkData={item}
												staticData={staticData(item.Theme)}
												order={index}
											/>
										))}
									<LearnMore order={0} report={benchmarkReportData[0].Report} />
									<LearnPracticesComponent order={2} />
									<Scorecard order={9} />
								</div>
							)}
						</div>
						<Filter
							full
							data={benchmarkReportData && benchmarkReportData[0]}
							industries={
								filterData &&
								filterData.industries?.sort((a, b) =>
									a.industryName.localeCompare(b.industryName)
								)
							}
							regions={filterData && filterData.regions}
							employees={filterData && filterData.employees}
							revenues={filterData && filterData.revenues}
							recordsTypes={filterData && filterData.recordTypes}
							recordsNumbers={filterData && filterData.records}
							loading={filterLoading}
							error={filterError}
						/>
					</div>
				</div>
				<Footer />
			</div>
			{showBenchmarkPdf && (
				<BenchmarkPDFReport
					data={industryReportData}
					isLoading={industryReportLoading}
					error={industryReportError}
					benchmarkReportData={benchmarkReportData}
					benchmarkReportLoading={benchmarkReportLoading}
					benchmarkReportError={benchmarkReportError}
				/>
			)}
		</div>
	);
};

export default Benchmark;
