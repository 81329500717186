// imports
import React from "react";

// utils
import { formatPercentage } from "../../../util/helpers";

const IndustryDesc = ({ data }) => {
	return (
		<p>
			{data.Theme === "Denial of Service" ? (
				<span></span>
			) : data.DataBreachProbability[0].Value >
			  data.BusinessInterruptionProbability[0].Value ? (
				<span>
					When looking more specifically at the resulting event, data breach
					events are more likely at{" "}
					<b>{formatPercentage(data.DataBreachProbability[0].Value)}%</b>{" "}
					probability per year, with business interruption events being
					forecasted at{" "}
					<b>
						{formatPercentage(data.BusinessInterruptionProbability[0].Value)}%
					</b>{" "}
					probability per year.
				</span>
			) : (
				<span>
					When looking more specifically at the resulting event, business
					interruption events are more likely at{" "}
					<b>
						{formatPercentage(data.BusinessInterruptionProbability[0].Value)}%
					</b>{" "}
					probability per year, with data breach events being forecasted at{" "}
					<b>{formatPercentage(data.DataBreachProbability[0].Value)}%</b>{" "}
					probability per year.
				</span>
			)}
		</p>
	);
};

export default IndustryDesc;
