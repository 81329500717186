// imports
import React, { useContext } from "react";

// context
import Context from "../../../context";

// utils
import { formatNumber, formatPercentage } from "../../../util/helpers";

const BenchmarkDynamicText = ({
	industryData,
	benchmarkData,
	dynamicDescRef,
}) => {
	// get context
	const { industry } = useContext(Context);

	return (
		<p className="my-6" ref={dynamicDescRef}>
			In the {industry.industryName} industry, a {benchmarkData.Theme} event has
			a <b>{formatPercentage(industryData?.Probability[0]?.Value)}%</b>{" "}
			probability of occurring per year and is expected to cost approximately{" "}
			<b>${formatNumber(industryData?.Loss[0]?.Value)}</b>. In your company, the
			same event has an{" "}
			<b>{formatPercentage(benchmarkData.Probability[0].Value)}%</b> probability
			of occurring and is expected to cost approximately{" "}
			<b>${formatNumber(benchmarkData.Loss[0].Value)}</b>.
		</p>
	);
};

export default BenchmarkDynamicText;
