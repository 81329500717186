// imports
import React, { useState, useContext, useEffect } from "react";

// styles
import styles from "./styles.module.css";

// icons
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import Loader from "../common/Select/assets/loader-light.gif";

// components
import List from "./List";

// api
import { useGetBenchmarkReport } from "../../util/api";

// context
import Context from "../../context";

const Scorecard = ({ order }) => {
	// context
	const {
		industryEmail,
		industry,
		region,
		employee,
		revenue,
		recordsNumber,
		recordsType,
		grade,
	} = useContext(Context);

	// states
	const [showDetails, setShowDetails] = useState(false);
	const [activeScore, setActiveScore] = useState(null);

	const descriptionText = `It's no longer a matter of if an attack will occur, but when. Security posture and overall control strength can be the difference between a near miss and a catastrophic event. See how your SecurityScorecard grade impacts your cyber risk below.`;

	// handle show details click
	const onClick = () => {
		setShowDetails(!showDetails);
	};

	// get benchmark report data hook
	const {
		data: gradeAData,
		isLoading: gradeALoading,
		error: gradeAError,
	} = useGetBenchmarkReport({
		email: industryEmail,
		industryId: industry.industryId,
		regionId: region.regionId,
		employeesId: employee.employeesId,
		revenueId: revenue.revenueId,
		recordsId: recordsNumber.recordsId,
		recordTypesId: recordsType.recordTypeId,
		SSCId: "A",
	});
	// get benchmark report data hook
	const {
		data: gradeBData,
		isLoading: gradeBLoading,
		error: gradeBError,
	} = useGetBenchmarkReport({
		email: industryEmail,
		industryId: industry.industryId,
		regionId: region.regionId,
		employeesId: employee.employeesId,
		revenueId: revenue.revenueId,
		recordsId: recordsNumber.recordsId,
		recordTypesId: recordsType.recordTypeId,
		SSCId: "B",
	});
	// get benchmark report data hook
	const {
		data: gradeCData,
		isLoading: gradeCLoading,
		error: gradeCError,
	} = useGetBenchmarkReport({
		email: industryEmail,
		industryId: industry.industryId,
		regionId: region.regionId,
		employeesId: employee.employeesId,
		revenueId: revenue.revenueId,
		recordsId: recordsNumber.recordsId,
		recordTypesId: recordsType.recordTypeId,
		SSCId: "C",
	});
	// get benchmark report data hook
	const {
		data: gradeDData,
		isLoading: gradeDLoading,
		error: gradeDError,
	} = useGetBenchmarkReport({
		email: industryEmail,
		industryId: industry.industryId,
		regionId: region.regionId,
		employeesId: employee.employeesId,
		revenueId: revenue.revenueId,
		recordsId: recordsNumber.recordsId,
		recordTypesId: recordsType.recordTypeId,
		SSCId: "D",
	});
	// get benchmark report data hook
	const {
		data: gradeFData,
		isLoading: gradeFLoading,
		error: gradeFError,
	} = useGetBenchmarkReport({
		email: industryEmail,
		industryId: industry.industryId,
		regionId: region.regionId,
		employeesId: employee.employeesId,
		revenueId: revenue.revenueId,
		recordsId: recordsNumber.recordsId,
		recordTypesId: recordsType.recordTypeId,
		SSCId: "F",
	});

	useEffect(() => {
		if ((gradeAData, gradeBData, gradeCData, gradeDData, gradeFData)) {
			switch (grade) {
				case "A":
					setActiveScore(
						gradeAData
							?.sort((a, b) => a.ThemeExposureRank - b.ThemeExposureRank)
							?.slice(0, 3),
					);
					break;
				case "B":
					setActiveScore(
						gradeBData
							?.sort((a, b) => a.ThemeExposureRank - b.ThemeExposureRank)
							?.slice(0, 3),
					);
					break;
				case "C":
					setActiveScore(
						gradeCData
							?.sort((a, b) => a.ThemeExposureRank - b.ThemeExposureRank)
							?.slice(0, 3),
					);
					break;
				case "D":
					setActiveScore(
						gradeDData
							?.sort((a, b) => a.ThemeExposureRank - b.ThemeExposureRank)
							?.slice(0, 3),
					);
					break;
				case "F":
					setActiveScore(
						gradeFData
							?.sort((a, b) => a.ThemeExposureRank - b.ThemeExposureRank)
							?.slice(0, 3),
					);
					break;
				default:
					setActiveScore(
						gradeAData
							?.sort((a, b) => a.ThemeExposureRank - b.ThemeExposureRank)
							?.slice(0, 3),
					);
					break;
			}
		}
	}, [gradeAData, gradeBData, gradeCData, gradeDData, gradeFData, grade]);

	return (
		<div
			className={`${styles.scorecard} rounded-md bg-white p-4 mb-6`}
			style={{ order: order }}>
			<h1 className="text-primary text-3xl font-light">
				How Does Your Control Posture Impact Risk?
			</h1>
			<div className="flex flex-row items-end justify-between text-slate-400 text-lg font-light mb-4 pr-1">
				<p className={`w-full ${showDetails ? styles.show : styles.hide}`}>
					{descriptionText}
				</p>
				{showDetails ? (
					<ChevronUpIcon
						className="w-5 cursor-pointer text-blue"
						onClick={onClick}
					/>
				) : (
					<ChevronDownIcon className="w-5 cursor-pointer" onClick={onClick} />
				)}
			</div>
			<div className="flex flex-row items-end justify-end text-slate-400 text-lg font-light mb-4 pr-1">
				<p>
					<b>Powered by SecurityScorecard</b>
				</p>
			</div>
			{gradeALoading ||
			gradeBLoading ||
			gradeCLoading ||
			gradeDLoading ||
			gradeFLoading ? (
				<div className="flex items-center justify-center h-60">
					<img src={Loader} alt="loading" className="h-16" />
				</div>
			) : gradeAError ||
			  gradeBError ||
			  gradeCError ||
			  gradeDError ||
			  gradeFError ? (
				<div className="flex items-center justify-center h-60">
					<p>An error has occurred. Please try again.</p>
				</div>
			) : (
				activeScore && (
					<List
						gradeAData={[
							gradeAData.find((i) => i.Theme === activeScore[0].Theme),
							gradeAData.find((i) => i.Theme === activeScore[1].Theme),
							gradeAData.find((i) => i.Theme === activeScore[2].Theme),
						]}
						gradeBData={[
							gradeBData.find((i) => i.Theme === activeScore[0].Theme),
							gradeBData.find((i) => i.Theme === activeScore[1].Theme),
							gradeBData.find((i) => i.Theme === activeScore[2].Theme),
						]}
						gradeCData={[
							gradeCData.find((i) => i.Theme === activeScore[0].Theme),
							gradeCData.find((i) => i.Theme === activeScore[1].Theme),
							gradeCData.find((i) => i.Theme === activeScore[2].Theme),
						]}
						gradeDData={[
							gradeDData.find((i) => i.Theme === activeScore[0].Theme),
							gradeDData.find((i) => i.Theme === activeScore[1].Theme),
							gradeDData.find((i) => i.Theme === activeScore[2].Theme),
						]}
						gradeFData={[
							gradeFData.find((i) => i.Theme === activeScore[0].Theme),
							gradeFData.find((i) => i.Theme === activeScore[1].Theme),
							gradeFData.find((i) => i.Theme === activeScore[2].Theme),
						]}
					/>
				)
			)}
		</div>
	);
};

export default Scorecard;
