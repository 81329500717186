// imports
import { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

// styles
import styles from "./styles.module.css";

// components
import Footer from "../../components/common/Footer";
import Input from "../../components/common/Input";

// assets
import Logo from "../../assets/logo/logo.svg";

// config
import config from "../../config";

// context
import { useAppContext } from "../../lib/contextLib";
import Context from "../../context";

export default function BenchmarkSignUp() {
	// use context
	const { setIndustryEmail } = useContext(Context);

	// states
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState(false);
	const [subscriptionError, setSubscriptionError] = useState(false);
	const { userHasAuthenticated } = useAppContext();

	// history
	const history = useHistory();

	async function onSubmit(e) {
		e.preventDefault();
		setLoading(true);

		try {
			// Validate Stripe Payment
			let response = await (
				await fetch(`${config.apiGateway.URL}/subscriptions/${email}`)
			).json();

			if (response.subscriptionValid) {
				await Auth.signIn(email, password);

				setLoading(false);
				userHasAuthenticated(true);
				localStorage.setItem("industryUserLoggedIn", true);
				localStorage.setItem("industryUserEmail", email);
				setIndustryEmail(email);
				history.push(`/benchmark/report/${window.location.search}`);
			} else {
				// If not valid payment tell them to use the right email
				setSubscriptionError(true);
				setLoading(false);
			}
		} catch (e) {
			setError(e);
			setLoading(false);
		}
	}

	return (
		<div className="bg-slate-50 min-h-screen w-screen flex flex-col justify-between">
			<div className="flex flex-col items-center justify-center">
				<img src={Logo} alt="logo" className="h-12 mt-12 mb-2" />
				<h1 className="text-gray-900 text-2xl font-extrabold mt-6">
					Sign in to My Cyber Risk Benchmark
				</h1>
				<p className="text-sm text-gray-600 mt-3">
					Or{" "}
					<a
						href={
							"https://www.risklens.com/my-industry-cybersecurity-benchmark"
						}
						target="_blank"
						className="text-blue-800 cursor-pointer"
						rel="noreferrer">
						Purchase your Subscription
					</a>
				</p>
			</div>
			<div className="flex-1 py-8 flex flex-col items-center justify-center">
				<form
					onSubmit={onSubmit}
					className={`${styles.form} rounded-lg bg-white py-8 px-10`}>
					<Input
						label="Email Address"
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						placeholder="Email"
						pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
						required
					/>
					<Input
						label="Password"
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						placeholder="Password"
						pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,100}$"
						required
					/>
					<button
						type="submit"
						className={`${styles.button} bg-blue-800 w-96	flex items-center justify-center text-white text-sm leading-5 font-medium py-2 rounded-md	`}>
						{loading ? "Loading" : "Sign in"}
					</button>
					{error && (
						<p className="text-red text-sm leading-5 font-medium pt-4">
							An error has occurred. Please try again!
						</p>
					)}
					{subscriptionError && (
						<div>
							<p className="text-red text-sm leading-5 font-medium pt-4">
								Subscription Expired.
							</p>
							<p>
								<a
									href="https://buy.stripe.com/bIY4h96QB4lLaTmeUV"
									className="mr-10 text-base text-slate-500">
									Click here to renew
								</a>
							</p>
						</div>
					)}
					<Link
						to={"./benchmark/forgotPassword"}
						className="text-blue-800 cursor-pointer block mt-3 text-center">
						Forgot your password?
					</Link>
				</form>
			</div>
			<Footer transparent />
		</div>
	);
}
