// imports
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// utils
import { formatNumber } from "../../util/helpers";

// styles
const styles = StyleSheet.create({
	desc: {
		marginBottom: 16,
	},
	textOne: {
		fontSize: 9,
		marginBottom: 8,
		color: "#1A2B3C",
	},
	textEnd: {
		fontSize: 9,
		color: "#1A2B3C",
	},
	bold: {
		fontWeight: "bold",
		color: "#1A2B3C",
	},
});

const ReportCardIndustryLossDynamicText = ({ data, industry }) => {
	return (
		<View style={styles.desc}>
			<Text style={styles.textEnd}>
				{data.Theme === "Denial of Service" ? (
					<Text style={styles.textOne}>
						The estimated {data.Theme} event cost for the {industry} industry (
						<Text style={styles.bold}>${formatNumber(data.Loss[0].Value)}</Text>
						) is driven by two loss categories: incident management (
						<Text style={styles.bold}>
							${formatNumber(data.ResponseCosts[0].Value)}
						</Text>
						) and lost revenue (
						<Text style={styles.bold}>
							${formatNumber(data.LostRevenue[0].Value)}
						</Text>
						).
					</Text>
				) : data.DataBreachProbability[0].Value >
				  data.BusinessInterruptionProbability[0].Value ? (
					<Text style={styles.textOne}>
						The{" "}
						<Text style={styles.bold}>${formatNumber(data.Loss[0].Value)}</Text>{" "}
						average per-event cost is driven by three loss categories: incident
						management (
						<Text style={styles.bold}>
							${formatNumber(data.ResponseCosts[0].Value)}
						</Text>
						), lost revenue (
						<Text style={styles.bold}>
							${formatNumber(data.LostRevenue[0].Value)}
						</Text>
						), and fines and judgements (
						<Text style={styles.bold}>
							${formatNumber(data.Fines[0].Value)}
						</Text>
						). The likelihood of fines and judgements occurring in an event is
						based on the event severity, applicable regulations, and company
						history, among other factors. The fines and judgements value shown
						below capture both the probability of fines and judgements occurring
						in this type of event (
						<Text style={styles.bold}>
							{(100 * data.FinesProbability[0].Value).toFixed(2)}%
						</Text>
						) as well as the cost (
						<Text style={styles.bold}>
							${formatNumber(data.FinesAmount[0].Value)}
						</Text>
						).
					</Text>
				) : (
					<Text style={styles.textOne}>
						The{" "}
						<Text style={styles.bold}>${formatNumber(data.Loss[0].Value)}</Text>{" "}
						average per-event cost is driven by three loss categories: incident
						management (
						<Text style={styles.bold}>
							${formatNumber(data.ResponseCosts[0].Value)}
						</Text>
						), lost revenue (
						<Text style={styles.bold}>
							${formatNumber(data.LostRevenue[0].Value)}
						</Text>
						), and fines and judgements (
						<Text style={styles.bold}>
							${formatNumber(data.Fines[0].Value)}
						</Text>
						). The likelihood of fines and judgements occurring in an event is
						based on the event severity, applicable regulations, and company
						history, among other factors. The fines and judgements value shown
						below capture both the probability of fines and judgements occurring
						in this type of event (
						<Text style={styles.bold}>
							{(100 * data.FinesProbability[0].Value).toFixed(2)}%
						</Text>
						) as well as the cost (
						<Text style={styles.bold}>
							${formatNumber(data.FinesAmount[0].Value)}
						</Text>
						).
					</Text>
				)}
			</Text>
		</View>
	);
};

export default ReportCardIndustryLossDynamicText;
