// imports
import React, { useContext } from "react";

// context
import Context from "../../../context";

// utils
import { formatNumber, formatPercentage } from "../../../util/helpers";

const IndustryDynamicText = ({ data, dynamicDescRef }) => {
	// get context
	const { industry } = useContext(Context);

	return (
		<p className="my-6" ref={dynamicDescRef}>
			In the {industry.industryName} industry, a {data.Theme} event has a{" "}
			<b>{formatPercentage(data.Probability[0].Value)}%</b> probability of
			occurring per year and is expected to cost approximately{" "}
			<b>${formatNumber(data.Loss[0].Value)}</b>.
		</p>
	);
};

export default IndustryDynamicText;
