// import
import { useCallback, useState, useEffect } from "react";

function useMoveFocus(size, allowPress) {
  const [currentfocus, setCurrentFocus] = useState(0);

  const handleKeyDown = useCallback(
    (e) => {
      if (allowPress) {
        if (e.keyCode === 40) {
          // Down arrow
          e.preventDefault();
          setCurrentFocus(currentfocus === size - 1 ? 0 : currentfocus + 1);
        } else if (e.keyCode === 38) {
          // Up arrow
          e.preventDefault();
          setCurrentFocus(currentfocus === 0 ? size - 1 : currentfocus - 1);
        }
      }
    },
    [allowPress, currentfocus, size]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [handleKeyDown]);

  return [currentfocus, setCurrentFocus];
}

export default useMoveFocus;
