// imports
import React from "react";

// components
import Item from "./Item";
import IndustryDesc from "./IndustryDesc";
import BenchmarkDesc from "./BenchmarkDesc";

const FinancialLossCategories = ({
	staticData,
	industryData,
	benchmarkData,
	blur,
}) => {
	return (
		<div className="mb-5">
			<h6 className="mb-2 font-light text-primary text-lg leading-normal ">
				Financial Loss Categories
			</h6>
			<div className="w-100 flex flex-row gap-4">
				<Item
					staticData={staticData.industry}
					light={benchmarkData ? true : false}
					blur={blur ?? false}
					data={industryData}
				/>
				<Item
					staticData={staticData.company}
					light={benchmarkData ? false : true}
					blur={blur ? true : benchmarkData ? false : true}
					data={benchmarkData}
				/>
			</div>
			<div className="text-primary mt-3 mb-6 text-lg text-justify leading-normal">
				{benchmarkData ? (
					<BenchmarkDesc
						industryData={industryData}
						benchmarkData={benchmarkData}
					/>
				) : (
					<IndustryDesc data={industryData} />
				)}
			</div>
		</div>
	);
};

export default FinancialLossCategories;
