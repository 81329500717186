// imports
import React from "react";

// styles
import styles from "./styles.module.css";

const Input = (props) => {
  return (
    <label className='text-gray-700 font-medium text-sm flex flex-col mb-6 flex-1'>
      {props.label}
      <input
        {...props}
        className={`${styles.input} text-gray-900 text-base	py-2 px-3 w-96 rounded-md border border-gray-300 mt-1 focus:outline-none`}
      />
    </label>
  );
};

export default Input;
