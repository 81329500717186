// imports
import React, { useContext } from "react";

// styles
import styles from "./styles.module.css";

// assets
import LinkedinIcon from "./assets/linkedin.png";
import TwitterIcon from "./assets/twitter.png";
import MailIcon from "../common/Navbar/assets/mail.svg";
import ChartImg from "../LearnIndustryComponent/assets/img.svg";
import { ShareIcon } from "@heroicons/react/solid";

// components
import Button from "../common/Button";

// context
import Context from "../../context";

// constants
import { twitterShare } from "../../util/constants";

// config
import config from "../../config";

import axios from "axios";

const SocialShareCard = ({ order }) => {
	// context
	const { industryEmail, setShowSlideOver } = useContext(Context);

	const handleSocialSharingClick = async (sharingType) => {
		await axios.post(config.apiGateway.URL + "/socialshare", {
			sharedEmail: industryEmail,
			sharingType: sharingType
		})
	};

	return (
		<div
			className={`${styles.main} flex flex-row justify-between items-center bg-light-blue rounded-md mb-6 relative overflow-hidden`}
			style={{ order: order }}>
			<div className="py-8 px-12 z-10">
				<h1 className="text-white text-3xl mb-0.5 font-normal">
					Share to get My Cyber Risk Benchmark for free!
				</h1>
				<p className="text-sky-100 font-extralight mb-8">
					Earn discounts toward a FREE My Cyber Risk Benchmark subscription by
					sharing this report.
				</p>
				<div className="flex items-center justify-between">
					<p className="text-sky-100 font-extralight flex-1 pr-3">
						Don't wait, earn your free discount today by sharing this report!
					</p>
					<div className="flex flex-row items-center gap-2 flex-1">
						<ShareIcon className="mr-3 h-14 text-white" />
						<a
							href={`https://www.linkedin.com/sharing/share-offsite/?url=${document.location.origin}/` + encodeURIComponent("?sharedEmail=" + industryEmail + "&sharingType=linkedin")}
							target="_blank"
							className="block cursor-pointer flex items-center"
							rel="noreferrer">
							<img src={LinkedinIcon} alt="mail" className="mr-3 h-14"
							onClick={() => handleSocialSharingClick("linkedin")}/>
						</a>
						<a
							href={twitterShare(encodeURIComponent(industryEmail))}
							target="_blank"
							rel="noreferrer"
							className="block cursor-pointer flex items-center">
							<img src={TwitterIcon} alt="mail" className="mr-3 h-14"
							onClick={() => handleSocialSharingClick("twitter")}/>
						</a>
						<a
							href={`mailto:?subject=Here are our industry's most severe cyber event losses&body=${encodeURIComponent(document.location.origin + "/?sharedEmail=" + industryEmail + "&sharingType=email")}`}
							className="block cursor-pointer flex items-center">
							<img src={MailIcon} alt="mail" className="mr-1 h-14" />
						</a>
					</div>
					<Button
						title="Learn More"
						bgColor="bg-sky-200"
						textColor="text-light-blue"
						classes="py-4 px-6"
						onClick={() => setShowSlideOver(true)}
					/>
				</div>
			</div>
			<img
				src={ChartImg}
				className="absolute right-0 hidden 2xl:flex"
				alt="chart-img"
			/>
		</div>
	);
};

export default SocialShareCard;
