import React from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "./containers/NotFound";
import SignIn from "./pages/SignIn";
import BenchmarkSignIn from "./pages/BenchmarkSignIn";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import IndustryRoute from "./components/IndustryRoute";
import IndustryMarketingIngest from "./pages/IndustryMarketingIngest";

// pages
import IndustryPage from "./pages/Industry";
import BenchmarkPage from "./pages/Benchmark";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import IndustrySignUp from "./pages/IndustrySignUp";
import BenchmarkSignUp from "./pages/BenchmarkSignUp";
import BenchmarkForgotPassword from "./pages/BenchmarkForgotPassword";
import BenchmarkResetPassword from "./pages/BenchmarkResetPassword";

export default function Routes() {
	return (
		<Switch>
			<UnauthenticatedRoute exact path="/">
				<IndustrySignUp />
			</UnauthenticatedRoute>
			<Route exact path="/benchmarkSignUp">
				<BenchmarkSignUp />
			</Route>
			<UnauthenticatedRoute exact path="/signIn">
				<SignIn />
			</UnauthenticatedRoute>
			<UnauthenticatedRoute exact path="/industry-risk-report">
				<IndustryMarketingIngest />
			</UnauthenticatedRoute>
			<Route exact path="/benchmarkSignIn">
				<BenchmarkSignIn />
			</Route>
			<Route exact path="/benchmark/forgotPassword">
				<BenchmarkForgotPassword />
			</Route>
			<Route exact path="/benchmark/resetPassword">
				<BenchmarkResetPassword />
			</Route>
			<IndustryRoute exact path="/industry/report">
				<IndustryPage />
			</IndustryRoute>
			<AuthenticatedRoute exact path="/benchmark/report">
				<BenchmarkPage />
			</AuthenticatedRoute>
			<Route exact path="/terms-of-use">
				<TermsOfUse />
			</Route>
			<Route exact path="/privacy-policy">
				<PrivacyPolicy />
			</Route>
			<UnauthenticatedRoute>
				<NotFound />
			</UnauthenticatedRoute>
		</Switch>
	);
}
