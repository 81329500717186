import config from "../../config";

export const BASE_URL = config.apiGateway.URL;

export const ENDPOINTS = {
  benchmarkFilter: "/reports/benchmark/filters",
  industryFilter: "/reports/industry/filters",
  industryReport: "/reports/industry/",
  benchmarkReport: "/reports/benchmark/",
};
