// imports
import React, { useContext } from "react";

// context
import Context from "../../../context";

// utils
import { formatPercentage } from "../../../util/helpers";

const BenchmarkDesc = ({ industryData, benchmarkData }) => {
	// get context
	const { industry } = useContext(Context);

	return (
		<p>
			{benchmarkData.Theme === "Denial of Service" ? (
				<span></span>
			) : benchmarkData.DataBreachProbability[0].Value >
			  benchmarkData.BusinessInterruptionProbability[0].Value ? (
				<span>
					When looking more specifically at the resulting impact, data breach{" "}
					{benchmarkData.Theme} events are more likely to occur in the{" "}
					{industry.industryName} industry, with an estimated{" "}
					<b>
						{formatPercentage(industryData.DataBreachProbability[0].Value)}%
					</b>{" "}
					probability of occurrence per year, with business interruption{" "}
					{benchmarkData.Theme} events estimated at{" "}
					<b>
						{formatPercentage(
							industryData.BusinessInterruptionProbability[0].Value,
						)}
						%
					</b>{" "}
					probability per year. Comparatively, your company has an estimated{" "}
					<b>
						{formatPercentage(benchmarkData.DataBreachProbability[0].Value)}%
					</b>{" "}
					and{" "}
					<b>
						{formatPercentage(
							benchmarkData.BusinessInterruptionProbability[0].Value,
						)}
						%
					</b>{" "}
					probability of data breach and business interruption{" "}
					{benchmarkData.Theme} events occurring per year, respectively.
				</span>
			) : (
				<span>
					When looking more specifically at the resulting impact, business
					interruption {benchmarkData.Theme} events are more likely to occur in
					the {industry.industryName} industry, with an estimated{" "}
					<b>
						{formatPercentage(
							industryData.BusinessInterruptionProbability[0].Value,
						)}
						%
					</b>{" "}
					probability of occurrence per year, with data breach{" "}
					{benchmarkData.Theme} events estimated at{" "}
					<b>
						{formatPercentage(industryData.DataBreachProbability[0].Value)}%
					</b>{" "}
					probability per year. Comparatively, your company has an estimated{" "}
					<b>
						{formatPercentage(
							benchmarkData.BusinessInterruptionProbability[0].Value,
						)}
						%
					</b>{" "}
					and{" "}
					<b>
						{formatPercentage(benchmarkData.DataBreachProbability[0].Value)}%
					</b>{" "}
					probability of business interruption and data breach{" "}
					{benchmarkData.Theme} events occurring per year, respectively.
				</span>
			)}
		</p>
	);
};

export default BenchmarkDesc;
