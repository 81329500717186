// imports
import React, { useContext } from "react";

// context
import Context from "../../../context";

// utils
import { formatNumber, formatPercentage } from "../../../util/helpers";

const IndustryDesc = ({ data }) => {
	// get context
	const { industry } = useContext(Context);

	return (
		<p>
			{data.Theme === "Denial of Service" ? (
				<span>
					The estimated {data.Theme} event cost for the {industry.industryName}{" "}
					industry (<b>${formatNumber(data.Loss[0].Value)}</b>) is driven by two
					loss categories: incident management (
					<b>${formatNumber(data.ResponseCosts[0].Value)}</b>) and lost revenue
					(<b>${formatNumber(data.LostRevenue[0].Value)}</b>).
				</span>
			) : data.DataBreachProbability[0].Value >
			  data.BusinessInterruptionProbability[0].Value ? (
				<span>
					The <b>${formatNumber(data.Loss[0].Value)}</b> average per-event cost
					is driven by three loss categories: incident management (
					<b>${formatNumber(data.ResponseCosts[0].Value)}</b>), lost revenue (
					<b>${formatNumber(data.LostRevenue[0].Value)}</b>), and fines and
					judgements (<b>${formatNumber(data.Fines[0].Value)}</b>). The
					likelihood of fines and judgements occurring in an event is based on
					the event severity, applicable regulations, and company history, among
					other factors. The fines and judgements value shown below capture both
					the probability of fines and judgements occurring in this type of
					event (<b>{formatPercentage(data.FinesProbability[0].Value)}%</b>) as
					well as the cost (<b>${formatNumber(data.FinesAmount[0].Value)}</b>).
				</span>
			) : (
				<span>
					The <b>${formatNumber(data.Loss[0].Value)}</b> average per-event cost
					is driven by three loss categories: incident management (
					<b>${formatNumber(data.ResponseCosts[0].Value)}</b>), lost revenue (
					<b>${formatNumber(data.LostRevenue[0].Value)}</b>), and fines and
					judgements (<b>${formatNumber(data.Fines[0].Value)}</b>). The
					likelihood of fines and judgements occurring in an event is based on
					the event severity, applicable regulations, and company history, among
					other factors. The fines and judgements value shown below capture both
					the probability of fines and judgements occurring in this type of
					event (<b>{formatPercentage(data.FinesProbability[0].Value)}%</b>) as
					well as the cost (<b>${formatNumber(data.FinesAmount[0].Value)}</b>).
				</span>
			)}
		</p>
	);
};

export default IndustryDesc;
