// imports
import React from "react";

const SubHeading = ({ text, id }) => {
  return (
    <h6 id={id} className='text-slate-900 text-3xl leading-normal font-bold'>
      {text}
    </h6>
  );
};

export default SubHeading;
