import React, { cloneElement } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAppContext } from "../lib/contextLib";

export default function UnauthenticatedRoute(props) {
	const { children, ...rest } = props;
	const { isAuthenticated } = useAppContext();

	const userSignedIn = JSON.parse(localStorage.getItem("industryUserLoggedIn"));

	return (
		<Route {...rest}>
			{!isAuthenticated ? (
				userSignedIn && !window.location.search ? (
					<Redirect to={`/industry/report/${window.location.search}`} />
				) : (
					cloneElement(children, props)
				)
			) : (
				<Redirect to={`/benchmark/report/${window.location.search}`} />
			)}
		</Route>
	);
}
