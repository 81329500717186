// imports
import React from "react";

// styles
import styles from "./styles.module.css";

// components
import Item from "./Item";

const index = ({ industryData, benchmarkData, staticData, blur }) => {
  return (
    <div className={`${styles.chart} flex flex-row gap-7 mb-6 px-8 `}>
      <Item
        data={industryData}
        staticData={staticData.industry}
        light={benchmarkData ? true : false}
        blur={blur ?? false}
        type='industry'
      />
      <Item
        data={benchmarkData}
        staticData={staticData.company}
        light={benchmarkData ? false : true}
        blur={blur ? true : benchmarkData ? false : true}
        type='benchmark'
      />
    </div>
  );
};

export default index;
