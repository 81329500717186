// libraries
import React from "react";

// components
import CardBtn from "../../common/CardBtn";

// utils
import { formatNumber } from "../../../util/helpers";

// assets
import FinancialDark from "./assets/financial-dark.svg";
import FinancialLight from "./assets/financial-light.svg";

// styles
import styles from "./styles.module.css";

const Item = ({ staticData, blur, light, data }) => {
	return (
		<div className="flex-grow border overflow-hidden rounded-md px-4 pt-4 flex-1 flex-1 flex-shrink-0">
			<h6
				className={`${
					light ? "text-secondary" : "text-primary"
				} text-xs leading-normal mb-5`}>
				{staticData.name}
			</h6>
			<div className={`${light ? "text-secondary" : "text-primary"} relative`}>
				{blur && (
					<div
						className={`${styles.find_out_more} absolute inset-0 flex items-center justify-center z-10`}
						ß>
						<CardBtn />
					</div>
				)}
				{blur ? (
					<img
						src={light ? FinancialLight : FinancialDark}
						alt="blurred-chart"
						className="w-full"
					/>
				) : (
					<>
						{/* Response cost */}
						<div className="flex flex-row items-center justify-between mb-4">
							<p className="leading-normal font-light">
								Incident Management Expense
							</p>
							<h4 className="text-xl leading-normal">
								<span className="font-thin">$</span>
								<span className="font-bold">
									{data?.ResponseCosts[0]?.Value
										? formatNumber(data.ResponseCosts[0]?.Value)?.slice(0, -1)
										: "--"}
								</span>
								{data?.ResponseCosts[0]?.Value > 999 && (
									<span className="font-light">
										{data?.ResponseCosts[0]?.Value
											? formatNumber(data?.ResponseCosts[0]?.Value)?.slice(
													formatNumber(data?.ResponseCosts[0]?.Value)?.length -
														1,
													formatNumber(data?.ResponseCosts[0]?.Value)?.length,
											  )
											: ""}
									</span>
								)}
							</h4>
						</div>
						{/* Lost revenue */}
						<div className="flex flex-row items-center justify-between mb-4">
							<p className="leading-normal font-light">Lost Revenue</p>
							<h4 className="text-xl leading-normal">
								<span className="font-thin">$</span>
								<span className="font-bold">
									{data.LostRevenue[0]?.Value
										? formatNumber(data.LostRevenue[0]?.Value)?.slice(0, -1)
										: "--"}
								</span>
								{data?.LostRevenue[0]?.Value > 999 && (
									<span className="font-light">
										{data.LostRevenue[0]?.Value
											? formatNumber(data.LostRevenue[0]?.Value)?.slice(
													formatNumber(data.LostRevenue[0]?.Value)?.length - 1,
													formatNumber(data.LostRevenue[0]?.Value)?.length,
											  )
											: ""}
									</span>
								)}
							</h4>
						</div>
						{/* Fine & Judgements */}
						<div className="flex flex-row items-center justify-between mb-4">
							<p className="leading-normal font-light">Fines & Judgements</p>
							<h4 className="text-xl leading-normal">
								<span className="font-thin">$</span>
								<span className="font-bold">
									{data?.Theme === "Denial of Service"
										? "--"
										: data?.Fines[0]?.Value
										? formatNumber(data?.Fines[0]?.Value)?.slice(0, -1)
										: "--"}
								</span>
								{data?.Fines[0]?.Value > 999 && (
									<span className="font-light">
										{data?.Theme === "Denial of Service"
											? ""
											: data?.Fines[0]?.Value
											? formatNumber(data.Fines[0]?.Value)?.slice(
													formatNumber(data.Fines[0]?.Value)?.length - 1,
													formatNumber(data.Fines[0]?.Value)?.length,
											  )
											: ""}
									</span>
								)}
							</h4>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default Item;
