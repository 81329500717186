// imports
import {
	Page,
	View,
	Image,
	Text,
	Document,
	StyleSheet,
	Link,
} from "@react-pdf/renderer";
import moment from "moment";

// fake data
import {
	denialOfServiceCardDataBenchmark,
	errorCardDataBenchmark,
	insiderMisuseCardDataBenchmark,
	ransomwareCardDataBenchmark,
	socialEngineeringCardDataBenchmark,
	systemIntrusionCardDataBenchmark,
	webApplicationCardDataBenchmark,
} from "../../util/fakedata";

// helpers
import { getQuarterYYYYAfter15 } from "../../util/helpers";

// assets
import logo from "./assets/logo.png";

// components
import ReportCard from "./ReportCard";
import FiltersPills from "./FiltersPills";

const styles = StyleSheet.create({
	body: {
		paddingTop: 20,
		paddingBottom: 55,
		paddingHorizontal: 35,
	},
	logoWrap: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	logo: {
		marginBottom: 15,
		textAlign: "center",
		width: 80,
	},
	header: {
		fontSize: 20,
		marginBottom: 12,
		marginTop: 24,
		textAlign: "center",
		fontWeight: "extrabold",
		color: "#1A2B3C",
	},
	subHeader: {
		fontSize: 14,
		marginBottom: 12,
		textAlign: "center",
		fontWeight: "semibold",
		color: "#94A3B8",
	},
	caption: {
		fontSize: 12,
		marginBottom: 12,
		textAlign: "center",
		color: "#1A2B3C",
	},
	pageNumber: {
		position: "absolute",
		fontSize: 12,
		bottom: 30,
		left: 0,
		right: 0,
		textAlign: "center",
		color: "#94A3B8",
	},
});

const PDFFile = ({
	industryData,
	benchmarkData,
	industryEmail,
	images,
	industry,
	region,
	revenue,
	employee,
	recordsType,
	recordsNumber,
	grade,
}) => {
	const staticData = (_theme) => {
		switch (_theme) {
			case "Basic Web Application Attacks":
				return webApplicationCardDataBenchmark;
			case "Denial of Service":
				return denialOfServiceCardDataBenchmark;
			case "Error":
				return errorCardDataBenchmark;
			case "Insider Misuse":
				return insiderMisuseCardDataBenchmark;
			case "Ransomware":
				return ransomwareCardDataBenchmark;
			case "Social Engineering (Phishing)":
				return socialEngineeringCardDataBenchmark;
			case "System Intrusion":
				return systemIntrusionCardDataBenchmark;
			default:
				return errorCardDataBenchmark;
		}
	};

	return (
		<Document>
			<Page style={styles.body}>
				<View style={styles.logoWrap} fixed>
					<Image style={styles.logo} src={logo} />
				</View>
				<Text style={styles.header}>
					{benchmarkData ? benchmarkData[0].Industry : industryData[0].Industry}
				</Text>
				<Text style={styles.subHeader}>
					Financial Impact of Top Cyber Events |{" "}
					{
						benchmarkData
							? getQuarterYYYYAfter15(benchmarkData[0].DataLastUpdated)
							: getQuarterYYYYAfter15(industryData[0].DataLastUpdated)
					}
				</Text>
				<Text style={styles.caption}>
					This report was generated for {industryEmail} on{" "}
					{moment(new Date()).format("MMMM DD, yyyy")}. Get your own free
					industry report at{" "}
					<Link src="https://industry.risklens.com" break>
						industry.risklens.com
					</Link>
				</Text>
				<FiltersPills
					industry={industry}
					region={region}
					revenue={revenue}
					employee={employee}
					recordsType={recordsType}
					recordsNumber={recordsNumber}
					grade={grade}
					full={benchmarkData ? true : false}
				/>
				{benchmarkData
					? benchmarkData
							.sort((a, b) => a.ThemeExposureRank - b.ThemeExposureRank)
							.map((item, index) => (
								<ReportCard
									industry={industry.industryName}
									benchmarkData={item}
									industryData={industryData.find(
										(i) => i.Theme === item.Theme,
									)}
									key={index}
									staticData={staticData(item.Theme)}
									images={images}
								/>
							))
					: industryData
							.sort((a, b) => a.ThemeExposureRank - b.ThemeExposureRank)
							.map((item, index) => (
								<ReportCard
									industry={industry.industryName}
									industryData={item}
									key={index}
									staticData={staticData(item.Theme)}
									images={images}
								/>
							))}
				<Text
					style={styles.pageNumber}
					render={({ pageNumber }) =>
						`Copyright © 2022 RiskLens, Inc., All Rights Reserved | Page ${pageNumber}`
					}
					fixed
				/>
			</Page>
		</Document>
	);
};

export default PDFFile;
