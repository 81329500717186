/* eslint-disable react-hooks/exhaustive-deps */
// imports
import { useState, useEffect, useContext } from "react";
import axios from "axios";

// components
import Select from "../Select";
import Loading from "./Loading";
import Radio from "../Radio";

// context
import Context from "../../../context";

// config
import config from "../../../config";

const Filter = ({
	full,
	industries,
	regions,
	employees,
	revenues,
	recordsTypes,
	recordsNumbers,
	loading,
	error,
}) => {
	// context
	const {
		industry,
		setIndustry,
		region,
		setRegion,
		revenue,
		setRevenue,
		employee,
		setEmployee,
		recordsType,
		setRecordsType,
		recordsNumber,
		setRecordsNumber,
		grade,
		setGrade,
	} = useContext(Context);

	// states
	const [companyUrl, setCompanyUrl] = useState("");
	const [submitLoading, setSubmitLoading] = useState(false);
	const [companyUrlStatus, setCompanyUrlStatus] = useState(null);

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());

		if (params.industry) {
			setIndustry({
				industryId: params.industry,
				industryName: params.industry?.replace(/([A-Z])/g, " $1").trim(),
			});
		} else {
			setIndustry({
				industryId: full
					? localStorage.getItem("benchmarkindustryId") || "Information"
					: localStorage.getItem("industryindustryId") || "Information",
				industryName: full
					? localStorage.getItem("benchmarkindustryName") || "Information"
					: localStorage.getItem("industryindustryName") || "Information",
			});
		}
		setRegion({
			regionId: full
				? localStorage.getItem("benchmarkregionId") || "NA"
				: localStorage.getItem("industryregionId") || "NA",
			regionName: full
				? localStorage.getItem("benchmarkregionName") || "North America"
				: localStorage.getItem("industryregionName") || "North America",
		});
		setEmployee({
			employeesId: full
				? localStorage.getItem("benchmarkemployeesId") || "1_to_50"
				: localStorage.getItem("industryemployeesId") || "1_to_50",
			employeesName: full
				? localStorage.getItem("benchmarkemployeesName") || "1 to 50"
				: localStorage.getItem("industryemployeesName") || "1 to 50",
		});

		if (full) {
			setRevenue({
				revenueId: localStorage.getItem("benchmarkrevenueId") || "100M_1B",
				revenueName:
					localStorage.getItem("benchmarkrevenueName") || "$100M to $1B",
			});
			setRecordsType({
				recordTypeId: localStorage.getItem("benchmarkrecordTypeId") || "PCI",
				recordTypeName:
					localStorage.getItem("benchmarkrecordTypeName") || "PCI",
			});
			setRecordsNumber({
				recordsId: localStorage.getItem("benchmarkrecordsId") || "10K_100K",
				recordsName:
					localStorage.getItem("benchmarkrecordsName") || "10K to 100K",
			});
			setGrade(localStorage.getItem("benchmarkGrade") || "B");
		}
	}, []);

	const handleDomainKeyDown = (event) => {
		if (event.key === "Enter") {
			domainSearch();
		}
	};

	const domainSearch = async () => {
		const url = companyUrl
			.replace("www.", "")
			.replace("https://", "")
			.replace("http://", "");

		setSubmitLoading(true);

		try {
			const response = await axios.get(
				`${config.apiGateway.URL}/securitygrade/${url}`,
			);
			if (response.data.error) {
				setCompanyUrlStatus({ type: "error", message: response.data.error });
			} else {
				setGrade(response.data.grade);
				setCompanyUrlStatus({
					type: "success",
					message: `The score for ${url} is a ${response.data.grade} (${response.data.score}).`,
				});
			}
			setSubmitLoading(false);
		} catch (error) {
			setCompanyUrlStatus({
				type: "error",
				message: "An error has occurred. Please try again.",
			});
		}
	};

	return (
		<div>
			<div className="bg-primary w-96 py-6 px-4 rounded-md">
				<h6 className="text-slate-100 text-2.5xl leading-normal">Filter</h6>
				{error ? (
					<div className="h-96 flex items-center justify-center">
						<p className="text-white font-light leading-normal mb-6 text-center">
							An error has occurred. Please try again.
						</p>
					</div>
				) : (
					<>
						<p className="text-white font-light leading-normal mb-6">
							Get more customized information by telling us more about your
							company.
						</p>
						<Select
							state={industry}
							setState={setIndustry}
							label="Industry"
							values={industries}
							field="industry"
							loading={loading}
							report={full ? "benchmark" : "industry"}
						/>
						<Select
							state={region}
							setState={setRegion}
							label="Region"
							values={regions}
							field="region"
							loading={loading}
							report={full ? "benchmark" : "industry"}
						/>
						{full && (
							<Select
								label="Revenue"
								values={revenues}
								state={revenue}
								setState={setRevenue}
								field="revenue"
								loading={loading}
								report={full ? "benchmark" : "industry"}
							/>
						)}
						<Select
							label="Employees"
							values={employees}
							state={employee}
							setState={setEmployee}
							field="employees"
							loading={loading}
							report={full ? "benchmark" : "industry"}
						/>
						{full && (
							<>
								<div className="grid grid-cols-2 gap-4">
									<Select
										label="Type of Records"
										values={recordsTypes}
										state={recordsType}
										setState={setRecordsType}
										field="recordType"
										loading={loading}
										report={full ? "benchmark" : "industry"}
									/>
									<Select
										label="Number of Records"
										values={recordsNumbers}
										state={recordsNumber}
										setState={setRecordsNumber}
										field="records"
										loading={loading}
										report={full ? "benchmark" : "industry"}
									/>
								</div>
								<div className="border border-slate-100 p-4 rounded-md">
									{!submitLoading ? (
										<>
											<div className="text-white text-right mb-2">
												Powered by SecurityScorecard
											</div>
											<div className="mb-4">
												<h6 className="text-slate-400 leading-normal text-base">
													Company Domain
												</h6>
												<div className="flex flex-row items-center justify-between">
													<input
														value={companyUrl}
														onKeyPress={(e) =>
															handleDomainKeyDown(e, e.target.value)
														}
														onBlur={domainSearch}
														onChange={(e) => setCompanyUrl(e.target.value)}
														placeholder="eg. risklens.com"
														className="bg-transparent border border-slate-100 rounded-md focus:outline-none flex-grow text-slate-100 font-light text-base leading-9 pl-2"
													/>
												</div>
												{companyUrlStatus && (
													<div className="flex flex-row items-center justify-between mt-5">
														<p
															className={`${
																companyUrlStatus.type === "success"
																	? "text-slate-100"
																	: "text-red"
															} text-sm leading-5`}>
															{companyUrlStatus.message}
														</p>
													</div>
												)}
											</div>
											<Radio
												values={["A", "B", "C", "D", "F"]}
												state={grade}
												setState={setGrade}
												loading={loading}
											/>
										</>
									) : (
										<Loading />
									)}
									<div className="mt-8">
										{!submitLoading && (
											<>
												<p className="text-slate-100 text-sm leading-5 mb-6">
													Enter your domain to get customized results based on
													your SecurityScorecard grade.
													<br />
													<br />
													Interested in learning more about your
													SecurityScorecard grade? Sign-up for your free
													SecurityScorecard account{" "}
													<a
														href="https://securityscorecard.com/free-account"
														target="_blank"
														rel="noreferrer"
														className="underline">
														here
													</a>
													.
												</p>
											</>
										)}
									</div>
								</div>
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default Filter;
