// imports
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";

// config
import config from "../../config";

// styles
import styles from "./styles.module.css";

// components
import Footer from "../../components/common/Footer";
import Input from "../../components/common/Input";
import Select from "../../components/common/Select";

// api
import { useGetIndustryFilter } from "../../util/api";

// assets
import Logo from "../../assets/logo/logo.svg";

// context
import Context from "../../context";

export default function IndustrySignUp() {
	// use context
	const { setIndustryEmail } = useContext(Context);

	// states
	const [loading, setLoading] = useState(false);
	const [industry, setIndustry] = useState({
		industryId: null,
		industryName: null,
	});
	const [email, setEmail] = useState("");
	const [sharedEmail, setSharedEmail] = useState({
		sharedEmail: null,
	});
	const [sharingType, setSharingType] = useState({
		sharingType: null,
	});
	const [error, setError] = useState(false);

	// history
	const history = useHistory();

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());

		if (params.industry) {
			setIndustry({
				industryId: params.industry,
				industryName: params.industry?.replace(/([A-Z])/g, " $1").trim(),
			});
		}

		if (params.sharedEmail) {
			setSharedEmail(params.sharedEmail?.replace(/([A-Z])/g, " $1").trim());
		}

		if (params.sharingType) {
			setSharingType(params.sharingType?.trim());
		}
	}, []);

	async function onSubmit(e) {
		e.preventDefault();
		if (email && industry.industryId) {
			setLoading(true);
			await axios
				.post(config.apiGateway.URL + "/users", {
					email: email,
					industryId: industry.industryId,
					sharedEmail: sharedEmail,
					sharingType: sharingType
				})
				.then((response) => {
					if (!response.data) {
						setLoading(false);
						setError(true);
					} else {
						localStorage.setItem("industryUserLoggedIn", true);
						localStorage.setItem(
							"industryUserEmail",
							response.data.pk.replace("USER#", ""),
						);
						setIndustryEmail(response.data.pk.replace("USER#", ""));
						setLoading(false);
						const params = new URLSearchParams({
							industry: industry.industryId,
						});
						history.push(`/industry/report?${params.toString()}`);
					}
				})
				.catch((e) => console.log(e));
			return null;
		}
	}

	// get filter data hook
	const { data: filterData, isLoading: filterLoading } = useGetIndustryFilter();

	return (
		<div className="bg-slate-50 h-screen w-screen flex flex-col justify-between">
			<div className="flex flex-col items-center justify-center">
				<img src={Logo} alt="logo" className="h-12 mt-12 mb-2" />
				<h1 className="text-gray-900 text-2xl font-extrabold mt-6">
					Sign up for your free Industry Cyber Risk Report
				</h1>
				<p className="text-sm text-gray-500 mt-3">
					Paid User?{" "}
					<Link
						to={"./benchmarkSignIn"}
						className="text-blue-800 cursor-pointer">
						Sign in to My Cyber Risk Benchmark
					</Link>
				</p>
			</div>
			<div className="flex-1 py-8 flex flex-col items-center justify-center">
				<form
					onSubmit={onSubmit}
					className={`${styles.form} rounded-lg bg-white py-8 px-10`}>
					<Input
						label="Email Address"
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						placeholder="Email"
						pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
						required
						autoFocus
					/>
					<Select
						state={industry}
						setState={setIndustry}
						label="Industry"
						values={filterData && filterData.industries}
						field="industry"
						loading={filterLoading}
						light
						allowPress={true}
					/>
					<div className="mt-6">
						<div className="relative">
							<div className="relative flex justify-left text-sm mb-4">
								<p className="text-gray-500">
									By clicking Sign up, you agree to our{" "}
									<Link
										to="/terms-of-use"
										className="text-blue-800 cursor-pointer">
										Terms of Service
									</Link>
									.
								</p>
							</div>
						</div>
					</div>
					<button
						type="submit"
						className={`${styles.button} bg-blue-800 w-96	flex items-center justify-center text-white text-sm leading-5 font-medium py-2 rounded-md	`}>
						{loading ? "Loading" : "Sign up"}
					</button>
					{error && (
						<p className="text-red text-sm leading-5 font-medium py-4">
							An error has occurred. Please try again!
						</p>
					)}
					<p className="text-sm text-gray-500 text-center mt-4">
						Already have an account?{" "}
						<Link to={"./signIn"} className="text-blue-800 cursor-pointer">
							Sign in
						</Link>
					</p>
				</form>
			</div>
			<Footer transparent />
		</div>
	);
}
