// imports
import React, { useState, useRef, useEffect } from "react";
import BreakdownParagraph from "./BreakdownParagraph";

// styles
import styles from "./styles.module.css";

// components
import CardHeader from "./CardHeader";
import FinancialLossCategories from "./FinancialLossCategories";
import BusinessImpact from "./BusinessImpact";
import Charts from "./Charts";
import ToggleDetails from "./ToggleDetails";

const Card = ({ industryData, benchmarkData, staticData, order, blur }) => {
  // states
  const [showDetails, setShowDetails] = useState(false);

  // refs
  const topContentRef = useRef();
  const bottomContentRef = useRef();
  const cardRef = useRef();
  const descRef = useRef();
  const dynamicDescRef = useRef();

  const onClick = () => {
    setShowDetails(!showDetails);
  };

  useEffect(() => {
    setTimeout(() => {
      if (cardRef.current && descRef.current && topContentRef.current) {
        cardRef.current.style.height = `${
          (descRef?.current.getBoundingClientRect().height || 0) +
          (topContentRef?.current.getBoundingClientRect().height || 0) +
          140
        }px`;
      }
    }, 300);
  }, [descRef, topContentRef]);

  return (
    <div
      ref={cardRef}
      className={`${styles.card} ${
        showDetails ? styles.show : ""
      } bg-white flex flex-col mb-6`}
      style={{
        height: showDetails
          ? `${
              (descRef?.current?.getBoundingClientRect().height || 0) +
              (topContentRef?.current?.getBoundingClientRect()?.height || 0) +
              (bottomContentRef?.current?.getBoundingClientRect()?.height ||
                0) +
              (dynamicDescRef?.current?.getBoundingClientRect().height || 0) +
              180
            }px`
          : `${
              descRef?.current?.getBoundingClientRect().height +
              topContentRef?.current?.getBoundingClientRect()?.height +
              140
            }px`,
        order: order,
      }}
    >
      <div className={`${styles.content}`}>
        <div ref={topContentRef}>
          <CardHeader title={industryData.Theme} staticData={staticData} />
          <Charts
            industryData={industryData}
            benchmarkData={benchmarkData}
            staticData={staticData}
            blur={blur}
          />
        </div>
        <BreakdownParagraph
          data={staticData}
          industryData={industryData}
          benchmarkData={benchmarkData}
          blur={blur}
          descRef={descRef}
          dynamicDescRef={dynamicDescRef}
        />
        <div ref={bottomContentRef}>
          <BusinessImpact
            staticData={staticData}
            industryData={industryData}
            benchmarkData={benchmarkData}
            blur={blur}
          />
          <FinancialLossCategories
            industryData={industryData}
            benchmarkData={benchmarkData}
            staticData={staticData}
            blur={blur}
          />
        </div>
      </div>
      <div
        className={`${styles.toggleDetail} bg-white mx-auto flex justify-center flex-col p-6`}
      >
        <ToggleDetails showDetails={showDetails} onClick={onClick} />
      </div>
    </div>
  );
};

export default Card;
