// imports
import { View, Image, Text, StyleSheet } from "@react-pdf/renderer";

// assets
import ErrorImg from "./assets/error.png";
import InsiderMisuseImg from "./assets/insider-misuse.png";
import SystemIntrusionImg from "./assets/system-intrusion.png";
import RansomwareImg from "./assets/ransomware.png";
import DenialOfServiceImg from "./assets/denial-of-service.png";
import WebApplicationImg from "./assets/web-application-attack.png";
import SocialEngineeringImg from "./assets/social-engineering.png";

// styles
const styles = StyleSheet.create({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 4,
    fontWeight: "extrabold",
  },
  icon: {
    width: 16,
    marginRight: 8,
  },
  title: {
    fontSize: 16,
    marginBottom: 2,
    color: "#1A2B3C",
  },
  subtitle: {
    fontSize: 10,
    color: "#94A3B8",
  },
});

const ReportCardHeader = ({ title, subtitle }) => {
  // select icon
  const selectIcon = () => {
    switch (title) {
      case "Insider Error":
        return ErrorImg;
      case "Insider Misuse":
        return InsiderMisuseImg;
      case "System Intrusion":
        return SystemIntrusionImg;
      case "Ransomware":
        return RansomwareImg;
      case "Denial of Service":
        return DenialOfServiceImg;
      case "Basic Web Application Attacks":
        return WebApplicationImg;
      case "Social Engineering":
        return SocialEngineeringImg;
      default:
        return ErrorImg;
    }
  };

  return (
    <View style={styles.header}>
      <Image src={selectIcon(title)} style={styles.icon} />
      <View>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subtitle}>{subtitle}</Text>
      </View>
    </View>
  );
};

export default ReportCardHeader;
