// imports
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

// styles
import styles from "./styles.module.css";

// components
import Footer from "../../components/common/Footer";
import Input from "../../components/common/Input";

// assets
import Logo from "../../assets/logo/logo.svg";

// config
import config from "../../config";

export default function BenchmarkForgotPassword() {
	// states
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [error, setError] = useState(false);
	const [subscriptionError, setSubscriptionError] = useState(false);

	// history
	const history = useHistory();

	async function onSubmit(e) {
		e.preventDefault();
		setLoading(true);

		setError(false);
		setSubscriptionError(false);

		try {
			// Validate Stripe Payment
			let response = await (
				await fetch(`${config.apiGateway.URL}/subscriptions/${email}`)
			).json();
			if (response.subscriptionValid) {
				await Auth.forgotPassword(email);
				setLoading(false);
				history.push("/benchmark/resetPassword");
			} else {
				setSubscriptionError(true);
				setLoading(false);
			}
		} catch (e) {
			setError(e);
			setLoading(false);
		}
	}

	return (
		<div className="bg-slate-50 h-screen w-screen flex flex-col justify-between">
			<div className="flex flex-col items-center justify-center">
				<img src={Logo} alt="logo" className="h-12 mt-12 mb-2" />
				<h1 className="text-gray-900 text-3xl font-extrabold mt-6">
					Forgot password
				</h1>
				<p className="text-sm text-gray-600">
					Or{" "}
					<Link
						to={"/benchmarkSignUp"}
						className="text-blue-800 cursor-pointer">
						sign in to your account
					</Link>
				</p>
			</div>
			<div className="flex-1 py-8 flex flex-col items-center justify-center">
				<form
					onSubmit={onSubmit}
					className={`${styles.form} rounded-lg bg-white py-8 px-10`}>
					<Input
						label="Email Address"
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						placeholder="Email"
						pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
						required
					/>
					<button
						type="submit"
						className={`${styles.button} bg-blue-800 w-96 flex items-center justify-center text-white text-sm leading-5 font-medium py-2 rounded-md	`}>
						{loading ? "Loading" : "Send Email"}
					</button>
					{error && (
						<p className="text-red text-sm leading-5 font-medium pt-4 w-96">
							{error.message}
						</p>
					)}
					{subscriptionError && (
						<div>
							<p className="text-red text-sm leading-5 font-medium pt-4">
								Subscription Expired.
							</p>
							<p>
								<a
									href="https://buy.stripe.com/bIY4h96QB4lLaTmeUV"
									className="mr-10 text-base text-slate-500">
									Click here to renew
								</a>
							</p>
						</div>
					)}
				</form>
			</div>
			<Footer transparent />
		</div>
	);
}
