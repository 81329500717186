// imports
import React from "react";

// icons
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";

const ToggleDetails = ({ showDetails, onClick }) => {
  return (
    <div
      className='flex justify-end items-center row cursor-pointer z-10'
      onClick={onClick}
    >
      <span
        className={`${
          showDetails ? "text-blue" : "text-secondary"
        } mr-3 text-lg leading normal`}
      >
        {showDetails ? "Hide" : "Show"} Details
      </span>
      {showDetails ? (
        <ChevronUpIcon
          className={`w-5 h-5 ${showDetails ? "text-blue" : "text-secondary"}`}
        />
      ) : (
        <ChevronDownIcon
          className={`w-5 h-5 ${showDetails ? "text-blue" : "text-secondary"}`}
        />
      )}
    </div>
  );
};

export default ToggleDetails;
