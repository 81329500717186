// imports
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// styles
const styles = StyleSheet.create({
	desc: {
		marginBottom: 16,
	},
	textOne: {
		fontSize: 9,
		marginBottom: 8,
		color: "#1A2B3C",
	},
	textEnd: {
		fontSize: 9,
		color: "#1A2B3C",
	},
	bold: {
		fontWeight: "bold",
		color: "#1A2B3C",
	},
});

const ReportCardIndustryProbabilityDynamicText = ({ data }) => {
	return (
		<View style={styles.desc}>
			<Text style={styles.textEnd}>
				{data.Theme === "Denial of Service" ? (
					<Text></Text>
				) : data.DataBreachProbability[0].Value >
				  data.BusinessInterruptionProbability[0].Value ? (
					<Text style={styles.textOne}>
						When looking more specifically at the resulting event, data breach
						events are more likely at{" "}
						<Text style={styles.bold}>
							{(100 * data.DataBreachProbability[0].Value).toFixed(2)}%
						</Text>{" "}
						probability per year, with business interruption events being
						forecasted at{" "}
						<Text style={styles.bold}>
							{(100 * data.BusinessInterruptionProbability[0].Value).toFixed(2)}
							%
						</Text>{" "}
						probability per year.
					</Text>
				) : (
					<Text style={styles.textOne}>
						When looking more specifically at the resulting event, business
						interruption events are more likely at{" "}
						<Text style={styles.bold}>
							{(100 * data.BusinessInterruptionProbability[0].Value).toFixed(2)}
							%
						</Text>{" "}
						probability per year, with data breach events being forecasted at{" "}
						<Text style={styles.bold}>
							{(100 * data.DataBreachProbability[0].Value).toFixed(2)}%
						</Text>{" "}
						probability per year.
					</Text>
				)}
			</Text>
		</View>
	);
};

export default ReportCardIndustryProbabilityDynamicText;
