// imports
import React, { useRef, useState } from 'react';

// styles
import styles from './styles.module.css';

// icons
import { ChevronDownIcon } from '@heroicons/react/outline';
import LoadingImg from './assets/loader.gif';
import LoadingLightImg from './assets/loader-light.gif';

// hooks
import UseOnClickOutside from '../../../util/hooks/useOnClickOutside.ts';
import UseMoveFocus from '../../../util/hooks/useMoveFocus';
import Item from './Item';

const Select = ({
	label,
	values,
	state,
	setState,
	field,
	loading,
	light,
	allowPress,
	report,
}) => {
	// states
	const [showSelect, setShowSelect] = useState(false);

	// refs
	const selectRef = useRef(null);

	// focus
	const [focus, setfocus] = UseMoveFocus(values?.length, allowPress);

	const clickOutsidehandler = () => {
		setShowSelect(false);
	};

	UseOnClickOutside(selectRef, clickOutsidehandler);

	return (
		<label
			className={`${
				light
					? 'text-gray-700 font-medium text-sm'
					: 'text-slate-400 text-base leading-normal'
			} flex flex-col mb-6 flex-1`}>
			{label}
			<div
				tabIndex='0'
				onClick={() => setShowSelect((prevState) => !prevState)}
				onKeyPress={() => setShowSelect((prevState) => !prevState)}
				className={`flex border rounded-md ${
					light ? 'border-gray-300' : 'border-slate-100'
				} pr-2 transition-all duration-300 ease-linear relative p-2 cursor-pointer h-10 ${
					showSelect ? 'z-10' : 'z-0'
				} ${light && 'mt-1'}`}
				ref={selectRef}>
				<span
					className={`${
						light
							? 'text-gray-900 text-base px-2 font-normal'
							: 'text-base leading-normal text-white'
					} overflow-hidden whitespace-pre ${
						state[Object.keys(state)[0]] ? !light && 'text-white' : 'opacity-60'
					}`}>
					{loading ? (
						<img
							src={light ? LoadingLightImg : LoadingImg}
							alt='loading'
							className='h-5'
						/>
					) : state[`${field}Name`] ? (
						state[`${field}Name`]
					) : light ? (
						'None Selected'
					) : (
						`Select`
					)}
				</span>
				<div className='bg-transparent flex-grow cursor-pointer'>
					<div
						className={`${styles.select} absolute inset-x-0 top-1.1 ${
							light ? 'bg-white' : 'bg-slate-800'
						} py-1 z-10 rounded-md transition-all duration-200 ease-linear overflow-auto ${
							showSelect ? 'max-h-48' : 'max-h-0 opacity-0'
						}`}>
						{!loading && values?.length === 0 ? (
							<div
								className={`flex items-center justify-between py-2 text-sm text-white leading-5`}>
								<span className='text-white'>No items to show</span>
							</div>
						) : (
							values?.map((item, index) => (
								<Item
									key={index}
									light={light}
									field={field}
									state={state}
									setState={setState}
									item={item}
									setfocus={setfocus}
									index={index}
									focus={focus === index}
									report={report}
								/>
							))
						)}
					</div>
				</div>
				<ChevronDownIcon
					className={`w-5 ${
						light ? 'text-gray-500' : 'text-white'
					} flex-shrink-0 pl-1`}
				/>
			</div>
		</label>
	);
};

export default Select;
