// imports
import React from "react";

const PageHeading = ({ heading, subtitle, date }) => {
  return (
    <div className='mb-6'>
      <h1 className='text-5xl leading-normal text-slate-800'>{heading}</h1>
      <h6 className='text-secondary text-2xl leading-normal'>
        {subtitle}
        <span className='px-8'>|</span>
        {date}
      </h6>
    </div>
  );
};

export default PageHeading;
