// imports
import React, { useContext } from "react";

// context
import Context from "../../context";

// assets
import ScoreA from "./assets/factor_a.svg";
import ScoreB from "./assets/factor_b.svg";
import ScoreC from "./assets/factor_c.svg";
import ScoreD from "./assets/factor_d.svg";
import ScoreF from "./assets/factor_f.svg";

const Item = ({ data, grade }) => {
	// context
	const { grade: activeGrade } = useContext(Context);

	// select title
	const selectTitle = (theme) => {
		switch (theme) {
			case "Error":
				return "Insider Error";
			case "Insider Misuse":
				return "Insider Misuse";
			case "System Intrusion":
				return "System Intrusion";
			case "Ransomware":
				return "Ransomware";
			case "Denial of Service":
				return "Denial of Service";
			case "Basic Web Application Attacks":
				return "Basic Web Application Attacks";
			case "Social Engineering (Phishing)":
				return "Social Engineering";
			default:
				return "Insider Error";
		}
	};
	return (
		<div
			className={`py-8 px-2 rounded-xl border flex flex-col items-center cursor-default transition-colors duration-300 ${
				activeGrade === grade && "bg-blue"
			}`}>
			<div
				className={`${
					activeGrade === grade ? "text-white" : "text-blue"
				} text-7xl font-light -mb-4`}>
				<img
					className="h-28 w-28"
					src={
						grade === "A"
							? ScoreA
							: grade === "B"
							? ScoreB
							: grade === "C"
							? ScoreC
							: grade === "D"
							? ScoreD
							: ScoreF
					}
					alt={grade}
				/>
			</div>
			{data.map((item, index) => (
				<div key={index}>
					<h3
						className={`${
							activeGrade === grade ? "text-white" : "text-primary"
						} text-lg font-bold text-center h-20 flex items-end`}>
						{selectTitle(item.Theme)}
					</h3>
					<h6
						className={`${
							activeGrade === grade ? "text-white" : "text-blue"
						} text-2xl my-2  font-medium text-center`}>
						{(100 * item.Probability[0].Value).toFixed(1)}%
					</h6>
					<p
						className={`${
							activeGrade === grade ? "text-grey-blue" : "text-secondary"
						} text-xs text-center ${index !== 2 && "mb-8"}`}>
						Event Probability
					</p>
				</div>
			))}
		</div>
	);
};

export default Item;
