// imports
import React from "react";

const index = ({
  title,
  icon,
  bgColor,
  textColor,
  classes,
  onClick,
  disabled,
}) => {
  return (
    <div
      onClick={onClick}
      disabled={disabled}
      className={`flex flex-row items-center cursor-pointer ${bgColor} rounded-md ${textColor} shadow-inner shadow-md active:shadow-none hover:opacity-90 transition-all duration-500 ${classes} w-max`}
    >
      {icon} {title}
    </div>
  );
};

export default index;
