import "./App.css";
import Routes from "./Routes";
import React, { useState, useEffect } from "react";
import { AppContext } from "./lib/contextLib";
import { Auth } from "aws-amplify";
import ErrorBoundary from "./components/ErrorBoundary";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastProvider } from "react-toast-notifications";

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  // Create a client
  const queryClient = new QueryClient();

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch {
      userHasAuthenticated(false);
    }

    setIsAuthenticating(false);
  }

  return (
    !isAuthenticating && (
      <div className='App'>
        <header className='App-header'>
          {isAuthenticated ? <></> : <></>}
        </header>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <AppContext.Provider
              value={{ isAuthenticated, userHasAuthenticated }}
            >
              <ToastProvider autoDismiss>
                <Routes />
              </ToastProvider>
            </AppContext.Provider>
          </QueryClientProvider>
        </ErrorBoundary>
      </div>
    )
  );
}

export default App;
