export const errorCardDataIndustry = {
  id: "1",
  sub_title:
    "Data disclosure or business interruptions caused by unintentional employee actions",
  breakdown_paragraph: (
    <>
      <p className='mb-6'>
        When you think of a large-scale cyber event, an evil villain often comes
        to mind. The more likely, and often just as costly, cause of those bad
        days is human error. Things like misconfigurations, failure to renew
        expired certificates, and improper publishing can lead to business
        interruption or data breach events that can have damaging consequences
        to the bottom line.
      </p>
      <p>
        The results provided detail the estimated average probability and impact
        of events in the insider error theme based on your industry, region, and
        employee count selections.{" "}
        <a
          href='https://www.risklens.com/my-industry-cybersecurity-benchmark'
          className='font-bold text-blue'
        >
          Access More Content
        </a>
      </p>
    </>
  ),
  industry: {
    name: "Industry",
  },
  company: {
    name: "Your Company",
    light: true,
    blur: true,
  },
};
export const errorCardDataBenchmark = {
  id: "1",
  sub_title:
    "Data disclosure or business interruptions caused by unintentional employee actions",
  breakdown_paragraph: (
    <p>
      When you think of a large-scale cyber event, an evil villain often comes
      to mind. The more likely, and often just as costly, cause of those bad
      days is human error. Things like misconfigurations, failure to renew
      expired certificates, and improper publishing can lead to business
      interruption or data breach events that can have damaging consequences to
      the bottom line.
    </p>
  ),
  industry: {
    name: "Industry",
    light: true,
  },
  company: {
    name: "Your Company",
  },
};

export const insiderMisuseCardDataIndustry = {
  id: "2",
  sub_title:
    "Data disclosure or business interruption caused by intentional misuse of employee privileges",
  breakdown_paragraph: (
    <>
      <p className='mb-6'>
        Employees are valuable assets, entrusted with the knowledge, skills, and
        resources to ensure the success of the company in meeting its
        objectives. But what happens when the knowledge, skills, and resources
        are misused? Unfortunately, traditional preventative measures are often
        ineffective because users have elevated access to superseding
        countermeasures.
      </p>
      <p className='mb-6'>
        Whether it is the intentional and malicious disclosure or modification
        of sensitive data, a logic bomb taking down the network, or an innocent
        workaround that unwittingly compromises an asset, your most trusted
        employees have the ability to cause significant loss to the company.
      </p>
      <p>
        The results provided detail the estimated average probability and impact
        of events in the insider misuse theme based on your industry, region,
        and employee count selections.{" "}
        <a
          href='https://www.risklens.com/my-industry-cybersecurity-benchmark'
          className='font-bold text-blue'
        >
          Access More Content
        </a>
      </p>
    </>
  ),
  industry: {
    name: "Industry",
  },
  company: {
    name: "Your Company",
    light: true,
    blur: true,
  },
};
export const insiderMisuseCardDataBenchmark = {
  id: "2",
  sub_title:
    "Data disclosure or business interruption caused by intentional misuse of employee privileges",
  breakdown_paragraph: (
    <>
      <p className='mb-6'>
        Employees are valuable assets, entrusted with the knowledge, skills, and
        resources to ensure the success of the company in meeting its
        objectives. But what happens when the knowledge, skills, and resources
        are misused? Unfortunately, traditional preventative measures are often
        ineffective because users have elevated access to superseding
        countermeasures.
      </p>
      <p>
        Whether it is the intentional and malicious disclosure or modification
        of sensitive data, a logic bomb taking down the network, or an innocent
        workaround that unwittingly compromises an asset, your most trusted
        employees have the ability to cause significant loss to the company.
      </p>
    </>
  ),
  industry: {
    name: "Industry",
    light: true,
  },
  company: {
    name: "Your Company",
  },
};

export const systemIntrusionCardDataIndustry = {
  id: "3",
  sub_title:
    "Data breach or business interruption stemming from a bad actor gaining access to your network",
  breakdown_paragraph: (
    <>
      <p className='mb-6'>
        System intrusion events are every CISO's nightmare. Through code
        exploitation or good old-fashioned brute force password guessing, a bad
        actor gets a foothold in your network and is able to access your most
        sensitive assets. Whether resulting in a data breach or business
        interruption, this type of event is sure to make headlines. This
        category does not include ransomware or social engineering-based
        attacks.
      </p>
      <p>
        The results provided detail the estimated average probability and impact
        of events in the system intrusion theme based on your industry, region,
        and employee count selections.{" "}
        <a
          href='https://www.risklens.com/my-industry-cybersecurity-benchmark'
          className='font-bold text-blue'
        >
          Access More Content
        </a>
      </p>
    </>
  ),
  industry: {
    name: "Industry",
  },
  company: {
    name: "Your Company",
    light: true,
    blur: true,
  },
};
export const systemIntrusionCardDataBenchmark = {
  id: "3",
  sub_title:
    "Data breach or business interruption stemming from a bad actor gaining access to your network",
  breakdown_paragraph: (
    <p>
      System intrusion events are every CISO's nightmare. Through code
      exploitation or good old-fashioned brute force password guessing, a bad
      actor gets a foothold in your network and is able to access your most
      sensitive assets. Whether resulting in a data breach or business
      interruption, this type of event is sure to make headlines. This category
      does not include ransomware or social engineering-based attacks.
    </p>
  ),
  industry: {
    name: "Industry",
    light: true,
  },
  company: {
    name: "Your Company",
  },
};

export const ransomwareCardDataIndustry = {
  id: "4",
  sub_title:
    "Business interruption and potential data breach due to a bad actor using malware to encrypt critical systems",
  breakdown_paragraph: (
    <>
      <p className='mb-6'>
        Ransomware is a type of malware-based cyberattack that is designed to
        pressure a company to pay a ransom by encrypting and withholding access
        to systems or files. While traditionally thought of as a worm-based
        virus targeting low-hanging fruit, bad actors are now using more
        advanced forms referred to as ransomware 2.0 or double extortion
        ransomware. These new variants involve gaining a network foothold to
        target assets that are more sensitive in nature and then using data
        exfiltration and threatened leakage to apply additional pressure after
        the asset has been encrypted. This results in a data breach in addition
        to the original business interruption.
      </p>
      <p>
        The results provided detail the estimated average probability and impact
        of events in the ransomware theme based on your industry, region, and
        employee count selections.{" "}
        <a
          href='https://www.risklens.com/my-industry-cybersecurity-benchmark'
          className='font-bold text-blue'
        >
          Access More Content
        </a>
      </p>
    </>
  ),
  industry: {
    name: "Industry",
    blur: true,
  },
  company: {
    name: "Your Company",
    light: true,
    blur: true,
  },
};
export const ransomwareCardDataBenchmark = {
  id: "4",
  sub_title:
    "Business interruption and potential data breach due to a bad actor using malware to encrypt critical systems",
  breakdown_paragraph: (
    <p>
      Ransomware is a type of malware-based cyberattack that is designed to
      pressure a company to pay a ransom by encrypting and withholding access to
      systems or files. While traditionally thought of as a worm-based virus
      targeting low-hanging fruit, bad actors are now using more advanced forms
      referred to as ransomware 2.0 or double extortion ransomware. These new
      variants involve gaining a network foothold to target assets that are more
      sensitive in nature and then using data exfiltration and threatened
      leakage to apply additional pressure after the asset has been encrypted.
      This results in a data breach in addition to the original business
      interruption.
    </p>
  ),
  industry: {
    name: "Industry",
    light: true,
  },
  company: {
    name: "Your Company",
  },
};

export const denialOfServiceCardDataIndustry = {
  id: "5",
  sub_title:
    "Business interruption caused by a bad actor flooding the system with traffic in excess of system limitations",
  breakdown_paragraph: (
    <>
      <p className='mb-6'>
        Denial of service events are designed to do exactly that: deny service
        to the intended users of the system by making the system unavailable,
        leading to a business interruption. This type of attack accomplishes
        this by flooding the target with traffic or sending it information that
        triggers a crash.
      </p>
      <p>
        The results provided detail the estimated average probability and impact
        of events in the denial of service theme based on your industry, region,
        and employee count selections.{" "}
        <a
          href='https://www.risklens.com/my-industry-cybersecurity-benchmark'
          className='font-bold text-blue'
        >
          Access More Content
        </a>
      </p>
    </>
  ),
  industry: {
    name: "Industry",
    blur: true,
  },
  company: {
    name: "Your Company",
    light: true,
    blur: true,
  },
};

export const denialOfServiceCardDataBenchmark = {
  id: "5",
  sub_title:
    "Business interruption caused by a bad actor flooding the system with traffic in excess of system limitations",
  breakdown_paragraph: (
    <p>
      Denial of service events are designed to do exactly that: deny service to
      the intended users of the system by making the system unavailable, leading
      to a business interruption. This type of attack accomplishes this by
      flooding the target with traffic or sending it information that triggers a
      crash.
    </p>
  ),
  industry: {
    name: "Industry",
    light: true,
  },
  company: {
    name: "Your Company",
  },
};

export const socialEngineeringCardDataIndustry = {
  id: "6",
  sub_title:
    "Business interruption or data breach caused by a bad actor manipulating employees or users into performing an action or divulging sensitive information",
  breakdown_paragraph: (
    <>
      <p className='mb-6'>
        Social engineering attacks are one of the most basic forms of attack,
        preying on human nature to manipulate individuals into sharing
        confidential or personal information or to perform an action that would
        benefit the attacker. These attacks typically involve a well-designed
        email appearing to be sent from a friend, colleague, or well-known
        brand. Other attacks use different mediums such as voice or SMS to
        achieve similar results. These attacks often result in a data breach,
        but can also lead to a business interruption.
      </p>
      <p>
        The results provided detail the estimated average probability and impact
        of events in the social engineering theme based on your industry,
        region, and employee count selections.{" "}
        <a
          href='https://www.risklens.com/my-industry-cybersecurity-benchmark'
          className='font-bold text-blue'
        >
          Access More Content
        </a>
      </p>
    </>
  ),
  industry: {
    name: "Industry",
    blur: false,
  },
  company: {
    name: "Your Company",
    light: true,
    blur: true,
  },
};

export const socialEngineeringCardDataBenchmark = {
  id: "6",
  sub_title:
    "Business interruption or data breach caused by a bad actor manipulating employees or users into performing an action or divulging sensitive information",
  breakdown_paragraph: (
    <p>
      Social engineering attacks are one of the most basic forms of attack,
      preying on human nature to manipulate individuals into sharing
      confidential or personal information or to perform an action that would
      benefit the attacker. These attacks typically involve a well-designed
      email appearing to be sent from a friend, colleague, or well-known brand.
      Other attacks use different mediums such as voice or SMS to achieve
      similar results. These attacks often result in a data breach, but can also
      lead to a business interruption.
    </p>
  ),
  industry: {
    name: "Industry",
    light: true,
  },
  company: {
    name: "Your Company",
  },
};

export const webApplicationCardDataIndustry = {
  id: "7",
  sub_title:
    "Business interruption or data breach caused by a bad actor compromising a web application",
  breakdown_paragraph: (
    <>
      <p className='mb-6'>
        Web application attacks considered in this category are any attacks that
        target and compromise a web application, either via brute force or code
        exploitation. These attacks can result in a business interruption or
        data breach, depending on the goals of the attacker. Web application
        attacks can also be used to gain a foothold in the environment which can
        be used to target other critical assets. Those types of complex,
        secondary motive attacks are captured in the system intrusion category.
      </p>
      <p>
        The results provided detail the estimated average probability and impact
        of events in the basic web application theme based on your industry,
        region, and employee count selections.{" "}
        <a
          href='https://www.risklens.com/my-industry-cybersecurity-benchmark'
          className='font-bold text-blue'
        >
          Access More Content
        </a>
      </p>
    </>
  ),
  industry: {
    name: "Industry",
    blur: false,
  },
  company: {
    name: "Your Company",
    light: true,
    blur: true,
  },
};

export const webApplicationCardDataBenchmark = {
  id: "7",
  sub_title:
    "Business interruption or data breach caused by a bad actor compromising a web application",
  breakdown_paragraph: (
    <p className='mb-6'>
      Web application attacks considered in this category are any attacks that
      target and compromise a web application, either via brute force or code
      exploitation. These attacks can result in a business interruption or data
      breach, depending on the goals of the attacker. Web application attacks
      can also be used to gain a foothold in the environment which can be used
      to target other critical assets. Those types of complex, secondary motive
      attacks are captured in the system intrusion category.
    </p>
  ),
  industry: {
    name: "Industry",
    light: true,
  },
  company: {
    name: "Your Company",
  },
};
