// libraries
import React from "react";

// styles
import styles from "./styles.module.css";

// icons
import { ChevronRightIcon } from "@heroicons/react/outline";

// doc
import industryPdf from "./assets/My-Cyber-Risk-Industry-RiskLens.pdf";
import benchmarkPdf from "./assets/My-Cyber-Risk-Benchmark-RiskLens.pdf";

const LearnMore = ({ order, report }) => {
  return (
    <div
      className={`${styles.learn_more} px-10 py-8 bg-blue rounded-md flex flex-row items-center justify-between cursor-pointer mb-6`}
      style={{ order: order }}
    >
      <p className='text-white text-3xl font-light'>
        Want to know how these results were derived?
      </p>
      <a
        href={report === "Industry" ? industryPdf : benchmarkPdf}
        target='_blank'
        className='bg-white text-blue flex flex-row items-center px-6 py-3 flex flex-row align-items gap-4 rounded-md'
        rel='noreferrer'
      >
        Learn More <ChevronRightIcon className='w-5' />
      </a>
    </div>
  );
};

export default LearnMore;
