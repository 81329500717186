// imports
import React from "react";
import { formatPercentage } from "../../../util/helpers";

// components
import CardBtn from "../../common/CardBtn";

// assets
import ImpactDark from "./assets/impact-dark.svg";
import ImpactLight from "./assets/impact-light.svg";

// styles
import styles from "../FinancialLossCategories/styles.module.css";

const Item = ({ staticData, blur, light, data }) => {
	return (
		<div
			className={`${
				light ? "text-secondary" : "text-primary"
			} flex-grow border rounded-md p-4 flex-1 flex-shrink-0 overflow-hidden`}>
			<h6
				className={`${
					light ? "text-secondary" : "text-primary"
				} text-xs leading-normal mb-5`}>
				{staticData.name}
			</h6>
			<div
				className={`flex ${
					data?.DataBreachProbability[0]?.Value >=
					data?.BusinessInterruptionProbability[0]?.Value
						? "flex-col"
						: "flex-col-reverse"
				} relative gap-4`}>
				{blur && (
					<div
						className={`${styles.find_out_more} absolute inset-0 flex items-center justify-center z-10`}>
						<CardBtn />
					</div>
				)}
				{blur ? (
					<img
						src={light ? ImpactLight : ImpactDark}
						alt="blurred-chart"
						className="w-full"
					/>
				) : (
					<>
						<div className="flex items-center justify-between">
							<p className="leading-normal font-light">Data Breach</p>
							<h6 className="text-xl font-bold">
								{data?.DataBreachProbability[0]?.Value ? (
									<>
										{data.Theme === "Denial of Service"
											? "--"
											: data?.DataBreachProbability[0].Format === "percent" &&
											  formatPercentage(data?.DataBreachProbability[0]?.Value)}
										<span className="font-thin">%</span>
									</>
								) : (
									"--"
								)}
							</h6>
						</div>
						<div className="flex items-center justify-between">
							<p className="leading-normal font-light">Business Interruption</p>
							<h6 className="text-xl font-bold">
								{data?.BusinessInterruptionProbability[0]?.Value ? (
									<>
										{data.Theme === "Denial of Service"
											? formatPercentage(data?.Probability[0]?.Value)
											: data?.BusinessInterruptionProbability[0]?.Format ===
													"percent" &&
											  ((
													100 * data?.BusinessInterruptionProbability[0]?.Value
											  ).toFixed(1) ??
													"--")}
										<span className="font-thin">%</span>{" "}
									</>
								) : (
									"--"
								)}
							</h6>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default Item;
