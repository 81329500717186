// imports
import React from 'react';

// icons
import LoadingImg from './assets/loading.gif';

const Loading = () => {
	return (
		<div className='flex flex-col items-center justify-center py-16'>
			<img src={LoadingImg} alt='loading' className='h-16 mb-6' />
			<p className='text-slate-400'>
				Retrieving your SecurityScorecard grade...
			</p>
		</div>
	);
};

export default Loading;
