// imports
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// styles
const styles = StyleSheet.create({
  pills: {
    marginTop: 24,
    marginBottom: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
  pill: {
    borderRadius: 50,
    borderWidth: 1,
    marginRight: 6,
    paddingVertical: 6,
    paddingHorizontal: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 6,
    borderColor: "#E5E7EB",
  },
  label: {
    fontSize: 7,
    marginRight: 8,
    color: "#94A3B8",
  },
  value: {
    fontSize: 9,
    color: "#1A2B3C",
  },
});

const FiltersPills = ({
  industry,
  region,
  revenue,
  employee,
  recordsType,
  recordsNumber,
  grade,
  full,
}) => {
  return (
    <View style={styles.pills}>
      <View style={styles.pill}>
        <Text style={styles.label}>Industry</Text>
        <Text style={styles.value}>{industry.industryName}</Text>
      </View>
      <View style={styles.pill}>
        <Text style={styles.label}>Region</Text>
        <Text style={styles.value}>{region.regionName}</Text>
      </View>
      <View style={styles.pill}>
        <Text style={styles.label}>Employees</Text>
        <Text style={styles.value}>{employee.employeesName}</Text>
      </View>
      {full && (
        <>
          <View style={styles.pill}>
            <Text style={styles.label}>Revenue</Text>
            <Text style={styles.value}>{revenue.revenueName}</Text>
          </View>
          <View style={styles.pill}>
            <Text style={styles.label}>Records Type</Text>
            <Text style={styles.value}>{recordsType.recordTypeName}</Text>
          </View>
          <View style={styles.pill}>
            <Text style={styles.label}>Records Number</Text>
            <Text style={styles.value}>{recordsNumber.recordsName}</Text>
          </View>
          <View style={styles.pill}>
            <Text style={styles.label}>Security Posture</Text>
            <Text style={styles.value}>
              {grade} - Powered by Security Scoreboard
            </Text>
          </View>
        </>
      )}
    </View>
  );
};

export default FiltersPills;
