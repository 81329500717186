// imports
import React from 'react';

// styles
import styles from './styles.module.css';

const Radio = ({ values, state, setState }) => {
	return (
		<div className={`${styles.radio} text-slate-400 leading-normal`}>
			SecurityScorecard Grade
			<div className='flex flex-row items-center justify-between mt-2'>
				{values.map((item, index) => (
					<div
						key={index}
						className='flex flex-row items-center cursor-pointer'>
						<input
							type='radio'
							id={item}
							name={item}
							checked={state === item ? true : false}
							className='w-5 h-5'
							onChange={(e) => {
								setState(e.target.name);
								localStorage.setItem('benchmarkGrade', e.target.name);
							}}
						/>
						<label
							className='text-slate-100 font-light cursor-pointer pl-2'
							htmlFor={item}>
							{item}
						</label>
					</div>
				))}
			</div>
		</div>
	);
};

export default Radio;
