import { useState, createContext } from "react";

const Context = createContext();

export function ContextProvider({ children }) {
	// states
	const [industryEmail, setIndustryEmail] = useState(
		localStorage.getItem("industryUserEmail") ?? null,
	);
	const [activeScoreboard, setActiveScoreboard] = useState(null);
	const [industry, setIndustry] = useState({
		industryId: null,
		industryName: null,
	});

	const [region, setRegion] = useState({ regionId: null, regionName: null });
	const [revenue, setRevenue] = useState({
		revenueId: null,
		revenueName: null,
	});
	const [employee, setEmployee] = useState({
		employeesId: null,
		employeesName: null,
	});
	const [recordsType, setRecordsType] = useState({
		recordTypeId: null,
		recordTypeName: null,
	});
	const [recordsNumber, setRecordsNumber] = useState({
		recordsId: null,
		recordsName: null,
	});
	const [posture, setPosture] = useState({
		postureId: null,
		postureName: null,
	});
	const [grade, setGrade] = useState(null);
	const [images, setImages] = useState({});
	const [showBenchmarkPdf, setShowBenchmarkPdf] = useState(false);
	const [showIndustryPdf, setShowIndustryPdf] = useState(false);
	const [showSlideOver, setShowSlideOver] = useState(false);

	return (
		<Context.Provider
			value={{
				industryEmail,
				setIndustryEmail,
				activeScoreboard,
				setActiveScoreboard,
				industry,
				setIndustry,
				region,
				setRegion,
				revenue,
				setRevenue,
				employee,
				setEmployee,
				recordsType,
				setRecordsType,
				recordsNumber,
				setRecordsNumber,
				posture,
				setPosture,
				grade,
				setGrade,
				images,
				setImages,
				showBenchmarkPdf,
				setShowBenchmarkPdf,
				showIndustryPdf,
				setShowIndustryPdf,
				showSlideOver,
				setShowSlideOver,
			}}>
			{children}
		</Context.Provider>
	);
}
export default Context;
