/* eslint-disable react-hooks/exhaustive-deps */
// imports
import { useEffect, useContext } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

// context
import Context from "../../../context";
import { formatPercentage } from "../../../util/helpers";

const Charts = ({ id, probability, format, light, data, type }) => {
	// use context
	const { setImages } = useContext(Context);

	useEffect(() => {
		// Create chart instance
		am4core.addLicense("ch-custom-attribution");
		var chart = am4core.create(id, am4charts.PieChart);
		// Add data
		chart.data = [
			{
				probability: "Probability",
				value: format === "percent" && formatPercentage(probability),
			},
			{
				probability: "Remaining",
				value: format === "percent" && 100 - formatPercentage(probability),
			},
		];

		// Add and configure Series
		var pieSeries = chart.series.push(new am4charts.PieSeries());
		pieSeries.dataFields.value = "value";
		pieSeries.dataFields.category = "probability";
		pieSeries.labels.template.disabled = true;
		pieSeries.ticks.template.disabled = true;
		pieSeries.dataFields.hidden = true;
		pieSeries.scale = 1;
		pieSeries.colors.list = [
			am4core.color(light ? "#94A3B8" : "#1A2B3C"),
			am4core.color("#F1F5F9"),
		];
		pieSeries.tooltip.disabled = true;
		chart.radius = "90%";
		chart.innerRadius = am4core.percent(68);

		var label = pieSeries.createChild(am4core.Label);
		label.textAlign = "middle";
		label.verticalCenter = "middle";
		label.horizontalCenter = "middle";
		label.text = `[bold ${light ? "#94A3B8" : "#1A2B3C"} font-size:26px]${
			format === "percent" && formatPercentage(probability)
		}[/][300 ${light ? "#94A3B8" : "#1A2B3C"} font-size:22px]%[/]\n[100 ${
			light ? "#94A3B8" : "#1A2B3C"
		} font-size:16px]Probability[/]\n[light ${
			light ? "#94A3B8" : "#1A2B3C"
		} font-size:11px]per year[/]`;
		chart.exporting.getImage("png").then((res) => {
			const key = `${type}/${data.Theme}`;
			setImages((e) => ({ ...e, [key]: res }));
		});

		return () => {
			if (chart) {
				chart.dispose();
			}
		};
	}, [probability]);

	return <div id={id} className="w-42 h-48"></div>;
};
export default Charts;
