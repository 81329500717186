// imports
import React from "react";
import { Link } from "react-router-dom";

// styles
import styles from "./styles.module.css";

// components
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";

const TermsOfUse = () => {
	return (
		<div className="bg-slate-100">
			<Navbar onlyLogo />
			<div
				className={`m-16 max-w-screen-xl mx-auto bg-white rounded-md py-8 px-16 cursor-default ${styles.content}`}>
				<p className="text-blue-600 text-base font-medium text-center mb-2">
					RISKLENS WEB APPLICATION
				</p>
				<h1 className="text-slate-900 text-center text-5xl font-bold leading-normal mb-8">
					Terms of Use
				</h1>
				<p className="text-slate-700 text-lg leading-normal font-bold mb-12">
					Thank you for your interest in using the RiskLens Risk Benchmark
					Report offered by RiskLens, Inc. (the “Software”). While the Software
					may be accessed through{" "}
					<Link to="/" className="text-blue-600">
						http://industry.risklens.com
					</Link>
					, it is provided under a license from RiskLens, Inc. (“RiskLens”). In
					addition to the terms and conditions below, you must comply with all
					terms, conditions and policies of the RiskLens, including, without
					limitation, the{" "}
					<a className="text-blue-600" href="/privacy-policy" target="_blank">
						RiskLens Privacy Policy
					</a>
					.
					<br />
					<br /> THESE TERMS OF USE ARE A BINDING AGREEMENT BETWEEN YOU AND
					RISKLENS . PLEASE READ CAREFULLY BEFORE USING THE SOFTWARE. BY USING
					THE SOFTWARE, YOU AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT
					AGREE TO ALL OF THESE TERMS, CLICK “DISAGREE” AND DO NOT USE THE
					SOFTWARE.
					<br />
					<br />
					The Software may contain graphics, images, visualizations, document
					layouts, artwork, text, fonts, music, software tools, and other
					information (referred to herein as “Content”). The Software and all
					Content is the copyrighted, proprietary property of RiskLens, and/or
					its licensors. All rights in the Software and the Content are reserved
					worldwide. It is strictly prohibited to retain, copy, distribute,
					publish, or use any portion of the Software or the Content except as
					expressly allowed in this Agreement.
					<br />
					<br />
					By using the Software, you agree to the following:
				</p>
				<p className="text-slate-700 text-lg leading-normal mb-12">
					<b className="font-bold">1. General.</b> The Software and Content are
					licensed, not sold, to you by RiskLens. RiskLens and/or its licensors
					retain ownership of the Software itself and reserve all rights not
					expressly granted to you. These terms will govern any software
					upgrades provided by RiskLens, unless such upgrade is accompanied by a
					separate license in which case the terms and conditions of that
					license will govern. Title and intellectual property rights in and to
					the Software and any content displayed by or accessed through the
					Software belongs to RiskLens. The Software and the Content are
					protected by copyright or other intellectual property laws and
					treaties and may be subject to additional terms of use of the party
					providing the Software.
				</p>
				<p className="text-slate-700 text-lg leading-normal mb-12">
					<b className="font-bold">
						2. Permitted License Uses and Restrictions.
					</b>{" "}
					Subject to the terms and conditions of this Agreement you are granted
					a limited, revocable non-exclusive license to use the Software solely
					for its intended purpose and solely for your non-commercial, personal
					use. You may not profit from use of the Software or incorporate the
					Software into any other services you provide to third-parties. You may
					not use the Software for any other purpose, nor may you make copies
					of, link, compile or otherwise combine the Software with your own
					programs, modify, rent, release, lend, sublicense or otherwise
					redistribute the Software, in whole or in part. Any attempt to do so
					is a violation of the rights of RiskLens and its licensors. You agree
					that the Software contains proprietary content, information and
					material that is owned by RiskLens and/or its licensors, and is
					protected by applicable intellectual property and other laws,
					including but not limited to copyright, and that you will not use such
					proprietary content, information or materials in any way whatsoever
					except for permitted use of the Software or in any manner that is
					inconsistent with the terms of this Agreement or that infringes any
					intellectual property rights of a third party or RiskLens. No portion
					of the Software or Content may be reproduced in any form or by any
					means. You agree not to modify, rent, lease, loan, sell, distribute,
					or create derivative works based on the Software or Content, in any
					manner, and you shall not exploit the Software in any unauthorized way
					whatsoever, including but not limited to, using the Software to
					transmit any computer viruses, worms, Trojan horses or other malware,
					or by trespass or burdening network capacity. You further agree not to
					use the Software in any manner to harass, abuse, stalk, threaten,
					defame or otherwise infringe or violate the rights of any other party,
					and that RiskLens is not in any way responsible for any such use by
					you, nor for any harassing, threatening, defamatory, offensive,
					infringing or illegal messages or transmissions that you may receive
					as a result of using the Software. For the avoidance of doubt, any
					freemium level of the Software or Content is available solely at
					RiskLens discretion and RiskLens does not guaranty any future access
					to the Website, Software or Conent.
				</p>
				<p className="text-slate-700 text-lg leading-normal mb-12">
					<b className="font-bold">3. Consent to Use of Data.</b> You hereby
					grant to RiskLens a worldwide, non-exclusive, royalty-free license to
					collect, use, analyze, and create derivative works of data input into
					the Software, user behavior, and related metadata to provide, evaluate
					and improve the Software and to derive metrics, statistics and other
					data from the data provided as part of using the Software
					(collectively, “Analytics”) and use such Analytics for any lawful
					business purpose, including to improve and promote RiskLens’s products
					and services, provided that RiskLens will only disclose Analytics
					externally in an aggregated and/or anonymous form that does not
					identify you. Analytics do not constitute your data. You further agree
					that RiskLens may collect, maintain, process and use diagnostic,
					technical, usage and related information, including but not limited to
					information about your computer, system and application software, and
					peripherals, that is gathered periodically to facilitate the provision
					of software updates, product support and other services to you (if
					any) related to the Software, and to verify compliance with the terms
					of this License. In order to enable RiskLens' partners and third-party
					developers to improve their software and services designed for use
					with RiskLens products, RiskLens may also provide any such partner or
					third-party developer with a subset of diagnostic information that is
					relevant to that partner’s or developer’s software and/or services, as
					long as the diagnostic information is in a form that does not
					personally identify you. Any information collected relating to
					voluntary users of the system shall be the exclusive and confidential
					property of RiskLens. At all times your information will be treated in
					accordance with{" "}
					<a
						className="text-blue-600 font-normal"
						href="/privacy-policy"
						target="_blank">
						RiskLens' Privacy Policy
					</a>
				</p>
				<p className="text-slate-700 text-lg leading-normal mb-12">
					<b className="font-bold">4. Termination.</b> This Agreement is
					effective until terminated. Your right to access and use the Software
					will terminate automatically or otherwise cease to be effective
					without notice from RiskLens if you fail to comply with any term(s) of
					this Agreement. Upon the termination of this Agreement, you shall
					cease all use of the Software for any reason. Sections 3, 4, 5, 6, and
					8 of this Agreement survive any such termination.
				</p>
				<p className="text-slate-700 text-lg leading-normal mb-12">
					<b className="font-bold">5. Disclaimer of Warranties.</b>
					<br />
					<b className="font-bold">5.1</b> YOU EXPRESSLY ACKNOWLEDGE AND AGREE
					THAT USE OF THE SOFTWARE IS AT YOUR SOLE RISK AND THAT THE ENTIRE RISK
					AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH
					YOU.
					<br />
					<b className="font-bold">5.2</b> TO THE MAXIMUM EXTENT PERMITTED BY
					APPLICABLE LAW, THE SOFTWARE OR THIRD PARTY SERVICES ARE PROVIDED “AS
					IS” AND “AS AVAILABLE”, WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY
					KIND, AND RISKLENS AND RISKLENS' AFFILIATES AND LICENSORS
					(COLLECTIVELY REFERRED TO AS “RISKLENS” FOR THE PURPOSES OF SECTIONS 5
					& 6) HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE
					SOFTWARE, EITHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT
					LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF
					MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A PARTICULAR
					PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND NON-INFRINGEMENT OF
					THIRD PARTY RIGHTS.
					<br />
					<b className="font-bold">5.3</b> RISKLENS DOES NOT WARRANT AGAINST
					INTERFERENCE WITH YOUR ENJOYMENT OF THE SOFTWARE, THAT THE FUNCTIONS
					CONTAINED IN THE SOFTWARE WILL MEET YOUR REQUIREMENTS, THAT THE
					OPERATION OF THE SOFTWARE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT
					ANY SOFTWARE WILL CONTINUE TO BE MADE AVAILABLE, THAT THE SOFTWARE
					WILL BE COMPATIBLE WITH THIRD PARTY SOFTWARE OR OTHER THIRD PARTY
					SERVICES, OR THAT DEFECTS IN THE SOFTWARE WILL BE CORRECTED.
					<br />
					<b className="font-bold">5.4</b> NO ORAL OR WRITTEN INFORMATION OR
					ADVICE GIVEN BY RISKLENS OR AN AUTHORIZED REPRESENTATIVE OF EITHER
					PARTY SHALL CREATE A WARRANTY.
					<br />
					<b className="font-bold">5.5</b> RISKLENS MAY OFFER LIMITED UPPORT AND
					UPDATES ON A VOLUNTARY BASIS ACCORDING TO ITS OWN JUDGEMENT. YOU ARE
					NOT ENTITLED TO MORE EXTENSIVE SUPPORT OR UPDATES FROM RISKLENS UNLESS
					AGREED TO IN A SEPARATE CONTRACT BETWEEN YOU AND RISKLENS.
				</p>
				<p className="text-slate-700 text-lg leading-normal mb-12">
					<b className="font-bold">6. Limitation of Liability.</b> TO THE EXTENT
					NOT PROHIBITED BY APPLICABLE LAW, IN NO EVENT SHALL RISKLENS BE LIABLE
					FOR PERSONAL INJURY, OR ANY INCIDENTAL, SPECIAL, INDIRECT OR
					CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION,
					DAMAGES FOR LOSS OF PROFITS, CORRUPTION OR LOSS OF DATA, FAILURE TO
					TRANSMIT OR RECEIVE ANY DATA, BUSINESS INTERRUPTION OR ANY OTHER
					COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR
					INABILITY TO USE THE SOFTWARE OR ANY THIRD PARTY SOFTWARE OR
					APPLICATIONS IN CONJUNCTION WITH THE SOFTWARE, HOWEVER CAUSED,
					REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT OR OTHERWISE)
					AND EVEN IF OR RISKLENS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
					DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY
					FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
					THIS LIMITATION MAY NOT APPLY TO YOU. IN NO EVENT SHALL TOTAL
					LIABILITY TO YOU FOR ALL DAMAGES RELATED TO THIS AGREEMENT (OTHER THAN
					AS MAY BE REQUIRED BY APPLICABLE LAW IN CASES INVOLVING PERSONAL
					INJURY) EXCEED THE AMOUNT OF FIFTY DOLLARS ($50.00). THE FOREGOING
					LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS
					ESSENTIAL PURPOSE.
				</p>
				<p className="text-slate-700 text-lg leading-normal mb-12">
					<b className="font-bold">7. Export Control.</b> The Software may not
					be exported or re-exported (a) into any U.S. embargoed countries or
					(b) to anyone on the U.S. Treasury Department's list of Specially
					Designated Nationals or the U.S. Department of Commerce Denied
					Person’s List or Entity List. By using the Software, you represent and
					warrant that you are not located in any such country or on any such
					list.
				</p>
				<p className="text-slate-700 text-lg leading-normal mb-12">
					<b className="font-bold">8. Controlling Law.</b> This Agreement will
					be governed by and construed in accordance with the laws of the State
					of Delaware, excluding its conflict of law principles.
				</p>
				<p className="text-slate-700 text-lg leading-normal">
					<b className="font-bold">
						9. Complete Agreement; Governing Language.
					</b>{" "}
					This Terms of Use Agreement constitutes the entire agreement between
					you and RiskLens relating to the Software and supersedes all prior or
					contemporaneous understandings regarding such subject matter. No
					amendment to or modification of this Agreement will be binding unless
					in writing and signed by the RiskLens.
				</p>
			</div>
			<Footer />
		</div>
	);
};

export default TermsOfUse;
