// imports
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// utils
import { formatNumber } from "../../util/helpers";

// styles
const styles = StyleSheet.create({
	textOne: {
		fontSize: 9,
		marginBottom: 8,
		color: "#1A2B3C",
	},
	textEnd: {
		fontSize: 9,
		color: "#1A2B3C",
	},
	bold: {
		fontWeight: "bold",
		color: "#1A2B3C",
	},
});

const ReportCardDescIndustryDynamic = ({ data, industry }) => {
	return (
		<View style={styles.desc}>
			<Text style={styles.textEnd}>
				In the {industry} industry, a {data.Theme} event has a{" "}
				<Text style={styles.bold}>
					{(100 * data.Probability[0].Value).toFixed(2)}%
				</Text>{" "}
				probability of occurring per year and is expected to cost approximately{" "}
				<Text style={styles.bold}>${formatNumber(data.Loss[0].Value)}</Text>.
			</Text>
		</View>
	);
};

export default ReportCardDescIndustryDynamic;
