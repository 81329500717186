// imports
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// utils
import { formatNumber } from "../../util/helpers";

const ReportCardLoss = ({ data, benchmark, light }) => {
	// styles
	const styles = StyleSheet.create({
		loss: {
			marginTop: 4,
			width: "50%",
			marginBottom: 4,
		},
		subtitle: {
			fontSize: 8,
			marginTop: 6,
			color: light ? "#94A3B8" : "#1A2B3C",
		},
		wrap: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "flex-start",
			alignItems: "flex-start",
			marginTop: 8,
		},
		amount: {
			fontSize: 14,
			fontWeight: "extrabold",
			marginBottom: 2,
			color: light ? "#94A3B8" : "#1A2B3C",
		},
		text: {
			fontSize: 10,
			fontWeight: "extrabold",
			color: light ? "#94A3B8" : "#1A2B3C",
		},
		content: {
			marginBottom: 8,
		},
	});

	return (
		<View style={styles.loss}>
			{!benchmark ? (
				<Text style={styles.subtitle}>Industry</Text>
			) : (
				<Text style={styles.subtitle}>Benchmark</Text>
			)}
			<View style={styles.wrap}>
				<View style={styles.content}>
					<Text style={styles.amount}>
						${formatNumber(data?.ResponseCosts[0]?.Value)}
					</Text>
					<Text style={styles.text}>Incident Management Expense</Text>
				</View>
				<View style={styles.content}>
					<Text style={styles.amount}>
						${formatNumber(data?.LostRevenue[0]?.Value)}
					</Text>
					<Text style={styles.text}>Lost Revenue</Text>
				</View>
				<View style={styles.content}>
					<Text style={styles.amount}>
						${formatNumber(data?.Fines[0]?.Value)}
					</Text>
					<Text style={styles.text}>Fines & Judgements</Text>
				</View>
			</View>
		</View>
	);
};

export default ReportCardLoss;
