// imports
import React from "react";

// styles
import styles from "./styles.module.css";

// icons
import { ChevronDoubleRightIcon } from "@heroicons/react/solid";

// assets
import Img from "./assets/img.svg";

// components
import Button from "../common/Button";

const LearnIndustryComponent = ({ order }) => {
	return (
		<div
			className={`${styles.main} flex flex-row justify-between items-center bg-light-blue rounded-md mb-6`}
			style={{ order: order }}>
			<div className="py-8 pl-12">
				<h1 className="text-white text-3xl mb-0.5 font-normal">
					Now you know your industry risk, but how do you compare?
				</h1>
				<p className="mb-8 text-sky-100 font-extralight mb-8">
					Find out with the My Cyber Risk Benchmark Report
				</p>
				<Button
					title="Upgrade Now"
					icon={<ChevronDoubleRightIcon className="w-5 mr-2" />}
					bgColor="bg-sky-200"
					textColor="text-light-blue"
					classes="py-4 px-6"
					onClick={() =>
						window.open(
							"https://www.risklens.com/my-industry-cybersecurity-benchmark",
							"_blank",
						)
					}
				/>
			</div>
			<div>
				<img src={Img} alt="learn-practices-img" />
			</div>
		</div>
	);
};

export default LearnIndustryComponent;
