// imports
import React from 'react';

// styles
import styles from './styles.module.css';

// icons
import { ClockIcon } from '@heroicons/react/outline';

// assets
import Img from './assets/img.svg';

// components
import Button from '../common/Button';

const LearnPracticeComponent = ({ order }) => {
	return (
		<div
			className={`${styles.main} flex flex-row justify-between items-center bg-light-blue rounded-md mb-6`}
			style={{ order: order }}>
			<div className='py-8 px-12'>
				<h1 className='mb-8 text-white font-extralight text-3xl'>
					Learn how to quantify cyber risk on your own
				</h1>
				<Button
					title='Schedule a Demo'
					icon={<ClockIcon className='w-5 mr-3' />}
					bgColor='bg-white'
					textColor='text-light-blue'
					classes='py-4 px-6'
					onClick={() =>
						window.open('https://www.risklens.com/schedule-demo-lp', '_blank')
					}
				/>
			</div>
			<div>
				<img className='scale-100' src={Img} alt='learn-practices-img' />
			</div>
		</div>
	);
};

export default LearnPracticeComponent;
