/* eslint-disable eqeqeq */
// imports
import React, { useContext, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Auth } from "aws-amplify";

// assets
import logo from "../../../assets/logo/logo.svg";

// icons
import {
	ShareIcon,
	UserCircleIcon,
	LockOpenIcon,
} from "@heroicons/react/solid";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import MailIcon from "./assets/mail.svg";
import Loader from "./assets/loader.svg";
import DownloadIcon from "./assets/download.svg";
import TwitterIcon from "./assets/twitter.svg";
import LinkedinIcon from "./assets/linkedin.svg";

// components
import { Menu, Transition } from "@headlessui/react";
import Button from "../Button";

// context
import Context from "../../../context";

// context
import { useAppContext } from "../../../lib/contextLib";

// constants
import { twitterShare } from "../../../util/constants";

// styles
import styles from "./styles.module.css";

// config
import config from "../../../config";

import axios from "axios";

const Navbar = ({ active, onlyLogo }) => {
	// history
	let history = useHistory();

	// context
	const {
		industryEmail,
		industry,
		region,
		employee,
		revenue,
		recordsType,
		recordsNumber,
		grade,
		images,
		setShowBenchmarkPdf,
		setShowIndustryPdf,
		setShowSlideOver,
	} = useContext(Context);
	const { userHasAuthenticated } = useAppContext();

	// toasts
	const { addToast } = useToasts();

	// handle benchmark report download
	const handleBenchmarkDownloadReportClick = () => {
		if (
			industry.industryId &&
			region.regionId &&
			employee.employeesId &&
			revenue.revenueId &&
			recordsType.recordTypeId &&
			recordsNumber.recordsId &&
			grade
		) {
			if (Object.keys(images).length === 14) {
				setShowBenchmarkPdf(true);
			}
		} else {
			addToast("Please select all filter values first!", {
				appearance: "error",
			});
		}
	};

	//
	const handleSocialSharingClick = async (sharingType) => {
		await axios.post(config.apiGateway.URL + "/socialshare", {
			sharedEmail: industryEmail,
			sharingType: sharingType,
		});
	};

	// handle industry report download
	const handleIndustryDownloadReportClick = () => {
		if (industry.industryId && region.regionId && employee.employeesId) {
			if (Object.keys(images).length === 7) {
				setShowIndustryPdf(true);
			}
		} else {
			addToast("Please select all filter values first!", {
				appearance: "error",
			});
		}
	};

	const handleLogout = async () => {
		await Auth.signOut();
		userHasAuthenticated(false);
		if (active === "industry") {
			localStorage.removeItem("industryUserEmail");
			localStorage.removeItem("industryUserLoggedIn");
		}
		history.push(active === "industry" ? "/signIn" : "/benchmarkSignIn");
	};

	return (
		<div className="px-8 bg-white flex flex-row align-items justify-between">
			<div
				className={`flex flex-row items-center gap-12 ${
					onlyLogo ? "py-4" : ""
				}`}>
				<img
					src={logo}
					alt="logo"
					className="h-12 cursor-pointer"
					onClick={() => onlyLogo && history.push("/")}
				/>
				{!onlyLogo && (
					<>
						{active === "industry" && (
							<>
								<span
									className="cursor-pointer text-2xl py-6 border-b-3 border-red text-primary"
									onClick={() => history.push("/industry/report")}>
									Industry
								</span>
								<a
									className="cursor-pointer text-2xl py-6 text-secondary"
									href="https://www.risklens.com/my-industry-cybersecurity-benchmark"
									target="_blank"
									rel="noreferrer">
									Benchmark
								</a>
							</>
						)}
						{active === "benchmark" && (
							<span
								className="cursor-pointer text-2xl py-6 border-b-3 border-red text-primary"
								onClick={() => history.push("/benchmark/report")}>
								Benchmark
							</span>
						)}
					</>
				)}
			</div>
			{!onlyLogo && (
				<div className="flex flex-grow flex-row justify-end items-center gap-2 ">
					<span
						className={`${styles.share_btn} block flex items-center gap-1 bg-none h-8 px-3 rounded text-xl transition-all cursor-pointer relative`}>
						<ShareIcon className="mr-0 h-5" />
						Share
						{active === "industry" && (
							<div className={styles.share_btn_tooltip}>
								<div className="left-0 z-10 bg-white divide-y divide-gray-100 rounded shadow w-100 dark:bg-gray-700">
									<ul className="py-2 px-3 text-xs text-white bg-primary rounded-md w-full">
										<li>
											<div className="flex flex-col items-center">
												Share to get My Cyber Risk Benchmark for free!
												<br />
												<Button
													title="Learn More"
													icon={<LockOpenIcon className="w-5 mr-2" />}
													bgColor="bg-sky-200"
													textColor="text-light-blue"
													classes="py-2 px-4 mt-2"
													onClick={() => setShowSlideOver(true)}
												/>
											</div>
										</li>
									</ul>
								</div>
							</div>
						)}
					</span>
					<a
						href={
							"https://www.linkedin.com/sharing/share-offsite/?url=" +
							encodeURIComponent(
								document.location.origin +
									"/?sharedEmail=" +
									industryEmail +
									"&sharingType=linkedin",
							)
						}
						target="_blank"
						className="block cursor-pointer flex items-center"
						rel="noreferrer">
						<img
							src={LinkedinIcon}
							alt="mail"
							className="h-8.5 w-8.5"
							onClick={() => handleSocialSharingClick("linkedin")}
						/>
					</a>
					<a
						href={twitterShare(encodeURIComponent(industryEmail))}
						target="_blank"
						rel="noreferrer"
						className="block cursor-pointer flex items-center">
						<img
							src={TwitterIcon}
							alt="mail"
							className="h-8.5 w-8.5"
							onClick={() => handleSocialSharingClick("twitter")}
						/>
					</a>
					<a
						href={`mailto:?subject=Here are our industry's most severe cyber event losses&body=${encodeURIComponent(
							document.location.origin +
								"/?sharedEmail=" +
								industryEmail +
								"&sharingType=email",
						)}`}
						className="block cursor-pointer flex items-center">
						<img src={MailIcon} alt="mail" className="h-7 w-8.5" />
					</a>
					<span
						onClick={() => {
							window.location.href.includes("/benchmark/report")
								? handleBenchmarkDownloadReportClick()
								: handleIndustryDownloadReportClick();
						}}
						className={`${
							active === "industry"
								? Object.keys(images).length === 7
									? ""
									: "cursor-wait grayscale"
								: Object.keys(images).length === 14
								? ""
								: "cursor-wait grayscale"
						} block cursor-pointer flex items-center relative`}>
						{active === "industry" ? (
							Object.keys(images).length === 7 ? (
								""
							) : (
								<img
									src={Loader}
									alt="loader"
									className="h-6 w-6 absolute left-1 top-full"
								/>
							)
						) : Object.keys(images).length === 14 ? (
							""
						) : (
							<img
								src={Loader}
								alt="loader"
								className="h-6 w-6 absolute left-1.5 top-0.5"
							/>
						)}
						<img src={DownloadIcon} alt="mail" className="h-7 w-8.5" />
					</span>
					<UserCircleIcon className="w-6 cursor-pointer" />
					<Menu>
						<Transition
							as={Fragment}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95">
							<Menu.Items className="origin-top-right absolute right-7 top-14 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
								<div className="py-1">
									<Menu.Item>
										{() => (
											<a
												href="mailto:support@risklens.com?Subject=RiskLens Industry Report Feedback"
												className="text-gray-900 block px-4 py-2 text-sm cursor-pointer">
												Contact Support
											</a>
										)}
									</Menu.Item>
								</div>
								<div className="py-1">
									<Menu.Item>
										{() => (
											<span
												className="text-gray-900 block px-4 py-2 text-sm cursor-pointer"
												onClick={handleLogout}>
												Logout
											</span>
										)}
									</Menu.Item>
								</div>
							</Menu.Items>
						</Transition>
						<Menu.Button className="flex items-center">
							{industryEmail}
							<DotsVerticalIcon className="w-5 cursor-pointer ml-3 transition-all" />
						</Menu.Button>
					</Menu>
				</div>
			)}
		</div>
	);
};

export default Navbar;
