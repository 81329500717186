// imports
import React from 'react';

// components
import Item from './Item';

const List = ({
	gradeAData,
	gradeBData,
	gradeCData,
	gradeDData,
	gradeFData,
}) => {
	return (
		<div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3'>
			<Item data={gradeAData} grade='A' />
			<Item data={gradeBData} grade='B' />
			<Item data={gradeCData} grade='C' />
			<Item data={gradeDData} grade='D' />
			<Item data={gradeFData} grade='F' />
		</div>
	);
};

export default List;
