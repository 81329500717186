// imports
import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

// config
import config from "../../config";

// context
import Context from "../../context";

// assets
import Logo from "../../assets/logo/logo.svg";

// styles
import styles from "./styles.module.css";

// components
import Input from "../../components/common/Input";
import Footer from "../../components/common/Footer";

const SignIn = () => {
	// history
	const history = useHistory();

	// context
	const { setIndustryEmail } = useContext(Context);

	// states
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [loginError, setLoginError] = useState(false);

	const onSubmit = async (e) => {
		e.preventDefault();
		if (email.length > 0) {
			setLoading(true);
			await axios
				.get(config.apiGateway.URL + "/users/" + encodeURIComponent(email))
				.then((response) => {
					if (!response.data) {
						setLoading(false);
						setError(true);
					} else {
						if (response.data.length == 0) {
							setLoading(false);
							setLoginError(true);
							return null;
						}
						localStorage.setItem("industryUserLoggedIn", true);
						localStorage.setItem(
							"industryUserEmail",
							response.data[0].pk.replace("USER#", ""),
						);
						setIndustryEmail(response.data[0].pk.replace("USER#", ""));
						setLoading(false);
						history.push(`/industry/report/${window.location.search}`);
					}
				});
			return null;
		}
	};

	return (
		<div className="bg-slate-50 h-screen w-screen flex flex-col justify-between">
			<div className="2xl:mt-20">
				<div className="flex flex-col items-center justify-center mb-8">
					<img src={Logo} alt="logo" className="h-12 mt-12 mb-2" />
					<h1 className="text-gray-900 text-2xl font-extrabold mt-6">
						Sign in to your free Industry Cyber Risk Report
					</h1>
					<p className="text-sm text-gray-500 mt-3">
						Paid User?{" "}
						<Link
							to={"./benchmarkSignIn"}
							className="text-blue-800 cursor-pointer">
							Sign in to My Cyber Risk Benchmark
						</Link>
					</p>
				</div>
				<div className="flex-1 py-8 flex flex-col items-center justify-center">
					<form
						onSubmit={onSubmit}
						className={`${styles.form} rounded-lg bg-white py-8 px-10`}>
						<Input
							required
							label="Email Address"
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder="Email"
							pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
							autoFocus={true}
						/>
						<button
							type="submit"
							className={`${styles.button} bg-blue-800 w-96	flex items-center justify-center text-white text-sm leading-5 font-medium py-2 rounded-md	`}>
							{loading ? "Loading" : "Sign in"}
						</button>
						{error && (
							<p className="text-red text-sm leading-5 font-medium py-4">
								An error has occurred. Please try again!
							</p>
						)}
						{loginError && (
							<div className="text-red text-sm leading-5 font-medium py-4">
								The email provided is incorrect. <br></br>If you believe you
								have reached this message in error,<br></br>contact site&nbsp;
								<a
									href="mailto:support@risklens.com?subject=RiskLens Industry Report Sign In Support"
									className="text-blue-800 cursor-pointer">
									support
								</a>
								.
							</div>
						)}
						<p className="text-sm text-gray-500 text-center mt-4">
							New user?{" "}
							<Link to={"./"} className="text-blue-800 cursor-pointer">
								Sign up for your free Industry Cyber Risk Report
							</Link>
						</p>
					</form>
				</div>
			</div>
			<div className="max-w-xl mx-auto text-center py-4 px-4 sm:py-6 sm:px-6 lg:px-8">
				<p className="leading-6 text-primary">
					Want to know how you compare to your industry? Find out with the My
					Cyber Risk Benchmark Report
				</p>
				<a
					href="https://www.risklens.com/my-industry-cybersecurity-benchmark "
					target="_blank"
					className="mt-6 w-full inline-flex items-center justify-center px-5 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-800 hover:bg-blue-400 sm:w-auto"
					rel="noreferrer">
					Learn More
				</a>
			</div>
			<Footer transparent />
		</div>
	);
};

export default SignIn;
