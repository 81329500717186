// imports
import React, { useContext } from "react";

// context
import Context from "../../../context";

// utils
import { formatNumber, formatPercentage } from "../../../util/helpers";

const BenchmarkDesc = ({ industryData, benchmarkData }) => {
	// get context
	const { industry } = useContext(Context);

	return (
		<p>
			{benchmarkData.Theme === "Denial of Service" ? (
				<span>
					The estimated {benchmarkData.Theme} event cost for the{" "}
					{industry.industryName} industry (
					<b>${formatNumber(industryData.Loss[0].Value)}</b>) is driven by two
					loss categories: incident management (
					<b>${formatNumber(industryData.ResponseCosts[0].Value)}</b>) and lost
					revenue (<b>${formatNumber(industryData.LostRevenue[0].Value)}</b>). A{" "}
					{benchmarkData.Theme} event at your company shares the same two loss
					drivers, with the following breakdown: incident management (
					<b>${formatNumber(benchmarkData.ResponseCosts[0].Value)}</b>) and lost
					revenue (<b>${formatNumber(benchmarkData.LostRevenue[0].Value)}</b>).
				</span>
			) : benchmarkData.DataBreachProbability[0].Value >
			  benchmarkData.BusinessInterruptionProbability[0].Value ? (
				<span>
					The estimated {benchmarkData.Theme} event cost for the{" "}
					{industry.industryName} industry (
					<b>${formatNumber(industryData.Loss[0].Value)}</b>) is driven by three
					loss categories: incident management (
					<b>${formatNumber(industryData.ResponseCosts[0].Value)}</b>), lost
					revenue (<b>${formatNumber(industryData.LostRevenue[0].Value)}</b>),
					and fines and judgements (
					<b>${formatNumber(industryData.Fines[0].Value)}</b>). The likelihood
					of fines and judgements occurring in an event is based on the event
					severity, applicable regulations, and company history, among other
					factors. The fines and judgements value shown below capture both the
					probability of fines and judgements occurring in this type of event (
					<b>{formatPercentage(industryData.FinesProbability[0].Value)}%</b>) as
					well as the cost (
					<b>${formatNumber(industryData.FinesAmount[0].Value)}</b>). A{" "}
					{benchmarkData.Theme} event at your company shares the same three loss
					drivers, with the following breakdown: incident management (
					<b>${formatNumber(benchmarkData.ResponseCosts[0].Value)}</b>), lost
					revenue (<b>${formatNumber(benchmarkData.LostRevenue[0].Value)}</b>),
					and fines and judgements (
					<b>${formatNumber(benchmarkData.Fines[0].Value)}</b>). The probability
					of fines and judgements occurring in this type of event is{" "}
					<b>{formatPercentage(benchmarkData.FinesProbability[0].Value)}%</b>,
					with an estimated cost of{" "}
					<b>${formatNumber(benchmarkData.FinesAmount[0].Value)}</b>.
				</span>
			) : (
				<span>
					The estimated {benchmarkData.Theme} event cost for the{" "}
					{industry.industryName} industry (
					<b>{formatPercentage(industryData.Probability[0].Value)}%</b>) is
					driven by three loss categories: incident management (
					<b>${formatNumber(industryData.ResponseCosts[0].Value)}</b>), lost
					revenue (<b>${formatNumber(industryData.LostRevenue[0].Value)}</b>),
					and fines and judgements (
					<b>${formatNumber(industryData.Fines[0].Value)}</b>). The likelihood
					of fines and judgements occurring in an event is based on the event
					severity, applicable regulations, and company history, among other
					factors. The fines and judgements value shown below capture both the
					probability of fines and judgements occurring in this type of event (
					<b>{formatPercentage(industryData.FinesProbability[0].Value)}%</b>) as
					well as the cost (
					<b>${formatNumber(industryData.FinesAmount[0].Value)}</b>). A{" "}
					{benchmarkData.Theme} event at your company shares the same three loss
					drivers, with the following breakdown: incident management (
					<b>${formatNumber(benchmarkData.ResponseCosts[0].Value)}</b>), lost
					revenue (<b>${formatNumber(benchmarkData.LostRevenue[0].Value)}</b>),
					and fines and judgements (
					<b>${formatNumber(benchmarkData.Fines[0].Value)}</b>). The probability
					of fines and judgements occurring in this type of event is{" "}
					<b>{formatPercentage(benchmarkData.FinesProbability[0].Value)}%</b>,
					with an estimated cost of{" "}
					<b>${formatNumber(benchmarkData.FinesAmount[0].Value)}</b>.
				</span>
			)}
		</p>
	);
};

export default BenchmarkDesc;
