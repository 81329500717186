// imports
import { View, StyleSheet, Text } from "@react-pdf/renderer";

// components
import ReportCardDescription from "./ReportCardDescription";
import ReportCardHeader from "./ReportCardHeader";
import ReportCardIndustryLossDynamicText from "./ReportCardIndustryLossDynamicText";
import ReportCardIndustryProbabilityDynamicText from "./ReportCardIndustryProbabilityDynamicText";
import ReportCardLoss from "./ReportCardLoss";
import ReportCardProbability from "./ReportCardProbability";
import ReportCardBenchmarkLossDynamicText from "./ReportCardBenchmarkLossDynamicText";
import ReportCardBenchmarkProbabilityDynamicText from "./ReportCardBenchmarkProbabilityDynamicText";
import ReportCardChart from "./ReportCardChart";

const styles = StyleSheet.create({
	card: {
		borderWidth: 1,
		borderColor: "#e2e8f0",
		borderRadius: 8,
		paddingVertical: 18,
		paddingHorizontal: 12,
		marginBottom: 12,
	},
	row: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	heading: {
		fontSize: 12,
		marginBottom: 0,
		color: "#1A2B3C",
	},
});

const ReportCard = ({
	benchmarkData,
	industryData,
	staticData,
	industry,
	images,
}) => {
	// select title
	const selectTitle = (title) => {
		switch (title) {
			case "Error":
				return "Insider Error";
			case "Insider Misuse":
				return "Insider Misuse";
			case "System Intrusion":
				return "System Intrusion";
			case "Ransomware":
				return "Ransomware";
			case "Denial of Service":
				return "Denial of Service";
			case "Basic Web Application Attacks":
				return "Basic Web Application Attacks";
			case "Social Engineering (Phishing)":
				return "Social Engineering";
			default:
				return "Insider Error";
		}
	};

	return (
		<View style={styles.card} wrap={false}>
			<ReportCardHeader
				title={selectTitle(
					benchmarkData ? benchmarkData.Theme : industryData.Theme,
				)}
				subtitle={staticData.sub_title}
			/>
			<View style={styles.row}>
				<ReportCardChart
					data={industryData}
					image={images[`industry/${industryData.Theme}`]}
					light={benchmarkData ? true : false}
				/>
				{benchmarkData && (
					<ReportCardChart
						data={benchmarkData}
						image={images[`benchmark/${benchmarkData.Theme}`]}
						benchmark
					/>
				)}
			</View>
			<ReportCardDescription
				benchmarkData={benchmarkData}
				industryData={industryData}
				industry={industry}
			/>
			<Text style={styles.heading}>
				Probability of an Event Occurring per Year, by Type
			</Text>
			<View style={styles.row}>
				<ReportCardProbability
					data={industryData}
					light={benchmarkData ? true : false}
				/>
				{benchmarkData && (
					<ReportCardProbability data={benchmarkData} benchmark />
				)}
			</View>
			{benchmarkData ? (
				<ReportCardBenchmarkProbabilityDynamicText
					benchmarkData={benchmarkData}
					industryData={industryData}
					industry={industry}
				/>
			) : (
				<ReportCardIndustryProbabilityDynamicText data={industryData} />
			)}
			<Text style={styles.heading}>Financial Loss Categories</Text>
			<View style={styles.row}>
				<ReportCardLoss
					data={industryData}
					light={benchmarkData ? true : false}
				/>
				{benchmarkData && <ReportCardLoss data={benchmarkData} benchmark />}
			</View>
			{benchmarkData ? (
				<ReportCardBenchmarkLossDynamicText
					industryData={industryData}
					benchmarkData={benchmarkData}
					industry={industry}
				/>
			) : (
				<ReportCardIndustryLossDynamicText
					data={industryData}
					industry={industry}
				/>
			)}
		</View>
	);
};

export default ReportCard;
