/* eslint-disable jsx-a11y/anchor-is-valid */
// imports
import React from "react";

// icons
import { XIcon } from "@heroicons/react/solid";

// context
import Context from "../../context";

const HeaderBanner = ({ setShowBanner }) => {
	// use context
	const { setShowSlideOver } = React.useContext(Context);

	return (
		<div className="bg-light-blue">
			<div className="py-3 px-2 sm:px-4 lg:px-6">
				<div className="flex items-center justify-between flex-wrap">
					<div className="w-0 flex-1 flex items-center">
						<p className="font-medium text-white truncate">
							<span className="inline">
								Share via email, Twitter and LinkedIn to get up to 100% off a
								one-year subscription to My Cyber Risk Benchmark.
							</span>
						</p>
					</div>
					<div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
						<a
							onClick={() => setShowSlideOver(true)}
							className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-light-blue bg-sky-200 hover:opacity-80 transition-all cursor-pointer">
							Learn more
						</a>
					</div>
					<div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
						<button
							onClick={() => setShowBanner(false)}
							type="button"
							className="-mr-1 flex p-2 rounded-md hover:opacity-80 focus:outline-none sm:-mr-2">
							<span className="sr-only">Dismiss</span>
							<XIcon className="h-6 w-6 text-white" aria-hidden="true" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeaderBanner;
