/* eslint-disable eqeqeq */
// imports
import { useContext } from "react";

// styles
import styles from "./styles.module.css";

// components
import PDFFile from "../PDF";
import { PDFViewer } from "@react-pdf/renderer";

// assets
import LoadingImg from "../../components/common/Select/assets/loader-light.gif";

// context
import Context from "../../context";

const BenchmarkReport = ({
  data,
  isLoading,
  error,
  benchmarkReportData,
  benchmarkReportLoading,
  benchmarkReportError,
}) => {
  // use context
  const {
    industryEmail,
    images,
    industry,
    region,
    revenue,
    employee,
    recordsType,
    recordsNumber,
    grade,
    setShowBenchmarkPdf,
  } = useContext(Context);

  return (
    <div
      className={styles.wrap}
      onClick={(e) => {
        e.stopPropagation();
        setShowBenchmarkPdf(false);
      }}
    >
      {isLoading || benchmarkReportLoading ? (
        <div className={styles.loading}>
          <img src={LoadingImg} alt='loading' />
        </div>
      ) : error || benchmarkReportError ? (
        <div className={styles.loading}>
          <p>An error has occurred. Please try again!</p>
        </div>
      ) : (
        <PDFViewer className={styles.pdf}>
          <PDFFile
            industryData={data}
            benchmarkData={benchmarkReportData}
            industryEmail={industryEmail}
            images={images}
            industry={industry}
            region={region}
            revenue={revenue}
            employee={employee}
            recordsType={recordsType}
            recordsNumber={recordsNumber}
            grade={grade}
          />
        </PDFViewer>
      )}
    </div>
  );
};

export default BenchmarkReport;
