import React, { cloneElement } from "react";
import { Route, Redirect } from "react-router-dom";

export default function IndustryRoute(props) {
	const { children, ...rest } = props;
	const isAuthenticated = JSON.parse(
		localStorage.getItem("industryUserLoggedIn"),
	);

	return (
		<Route {...rest}>
			{isAuthenticated ? (
				cloneElement(children, props)
			) : (
				<Redirect to={`/${window.location.search}`} />
			)}
		</Route>
	);
}
