// imports
import React from "react";

// styles
import styles from "./styles.module.css";

// components
import Footer from "../../components/common/Footer";
import Navbar from "../../components/common/Navbar";
import DataTransferTable from "../../components/PrivacyPolicy/DataTransferTable";
import SubHeading from "../../components/PrivacyPolicy/SubHeading";

const PrivacyPolicy = () => {
  return (
    <div className='bg-slate-100'>
      <Navbar onlyLogo />
      <div
        className={`m-16 max-w-screen-xl mx-auto bg-white rounded-md py-8 px-16 cursor-default ${styles.content}`}
      >
        <p className='text-blue-600 text-base font-medium text-center mb-2'>
          RISKLENS BENCHMARK RISK REPORT
        </p>
        <h1 className='text-slate-900 text-center text-5xl font-bold leading-normal mb-8'>
          Privacy Policy
        </h1>
        <p className='text-slate-700 text-lg leading-normal font-normal mb-12'>
          Effective as of December 1, 2021
          <br />
          <br />
          This Privacy Policy describes how RiskLens, Inc. and our subsidiaries
          (collectively <b className='font-bold'>
            "RiskLens," "we", “us”
          </b> or <b className='font-bold'>"our"</b>) handle personal
          information that we collect though our RiskLens Risk Benchmark Report
          website (the <b className='font-bold'>“Service”</b>) in connection
          with activities described in this Privacy Policy.
          <br />
          <br />
          RiskLens provides a web-based software platform that businesses use to
          quantify business risk. This Privacy Policy does not apply to
          information that we process on behalf of our business customers while
          providing the RiskLens platform to them, and information on such
          collection is found in our{" "}
          <a
            className='text-blue-600 font-normal'
            href='/terms-of-use'
            target='_blank'
          >
            Terms of Service.
          </a>{" "}
          Our use of this information is restricted by our agreements with those
          business customers. If you have concerns regarding personal
          information that we process on behalf of a business, you should direct
          your concerns to that business.
        </p>
        <SubHeading text='Personal information we collect' />
        <p className='text-slate-700 text-lg leading-normal font-normal mb-12'>
          <b className='font-bold'>Information you provide to us.</b> Personal
          information you may provide to us through the Service or otherwise
          includes:
          <br />
          <li className='pl-8 list-inside'>
            <b className='font-bold'>Contact data,</b> such as your first and
            last name, and email address.
          </li>
          <li className='pl-8 list-inside'>
            <b className='font-bold'>Communications</b> that we exchange,
            including when you contact us with questions, feedback, or
            otherwise.
          </li>
          <li className='pl-8 list-inside'>
            <b className='font-bold'>Marketing data,</b> such as your
            preferences for receiving communications about our products and
            services, and details about how you engage with our communications.
          </li>
          <li className='pl-8 list-inside'>
            <b className='font-bold'>Other information</b> that we may collect
            which is not specifically listed here, which we will use as
            described in this Privacy Policy or as otherwise disclosed at the
            time of collection.
          </li>
          <b className='font-bold'>Automatic data collection.</b> We or our
          service providers may automatically log information about you, your
          computer or mobile device, and your activity over time on our sites
          and other sites and online services. This information (the “Usage
          Data”) may include:
          <br />
          <li className='pl-8 list-inside'>
            <b className='font-bold'>Device data,</b> such as your computer or
            mobile device operating system or IP address.
          </li>
          <li className='pl-8 list-inside'>
            <b className='font-bold'>Online activity data,</b> such as pages or
            screens you viewed, how long you spent on a page or screen,
            navigation paths between pages or screens, information about your
            activity on a page or screen, access times, and duration of access.
          </li>
          <b className='font-bold'>Cookies.</b> Some of our automatic data
          collection is facilitated by cookies and similar technologies. For
          more information, see our{" "}
          <a href='#' className='text-blue-600 font-normal' target='_blank'>
            Cookie Notice.
          </a>
        </p>
        <SubHeading text='How we use your personal information' />
        <p className='text-slate-700 text-lg leading-normal font-normal mb-12'>
          We use your personal information for the following purposes or as
          otherwise described at the time we collect it:
          <br />
          <b className='font-bold'>Service delivery.</b> We use your personal
          information to:
          <br />
          <li className='pl-8 list-inside'>
            provide, operate and improve the Service and our business;
          </li>
          <li className='pl-8 list-inside'>
            communicate with you about the Service;
          </li>
          <li className='pl-8 list-inside'>
            provide support for the Service, respond to your requests, questions
            and feedback.
          </li>
          <li className='pl-8 list-inside'>
            <b className='font-bold'>Direct marketing.</b> We may send you
            RiskLens related or other direct marketing communications as
            permitted by law, including by email. You may opt-out of our
            marketing communications as described in the{" "}
            <a href='#' className='text-blue-600 font-normal' target='_blank'>
              Opt-out_of_marketing
            </a>{" "}
            section below.
          </li>
          <br />
          <b className='font-bold'>Compliance and protection.</b> We may use
          your personal information to:
          <br />
          <li className='pl-8 list-inside'>
            comply with applicable laws, lawful requests, and legal process,
            such as to respond to subpoenas or requests from government
            authorities;
          </li>
          <li className='pl-8 list-inside'>
            protect our, your or others’ rights, privacy, safety or property
            (including by making and defending legal claims);
          </li>
          <li className='pl-8 list-inside'>
            audit our internal processes for compliance with legal and
            contractual requirements and internal policies;
          </li>
          <li className='pl-8 list-inside'>
            enforce the terms and conditions that govern the Service; and
          </li>
          <li className='pl-8 list-inside'>
            prevent, identify, investigate and deter fraudulent, harmful,
            unauthorized, unethical or illegal activity, including cyberattacks
            and identity theft.
          </li>
        </p>
        <SubHeading text='How we share your personal information' />
        <p className='text-slate-700 text-lg leading-normal font-normal mb-12'>
          We may share your personal information with the following parties and
          as otherwise described in this Privacy Policy or at the time of
          collection:
          <br />
          <b className='font-bold'>Affiliates.</b> Our subsidiaries and other
          corporate affiliates, for purposes consistent with this Privacy
          Policy.
          <br />
          <b className='font-bold'>Service providers.</b> Companies that provide
          services on our behalf or help us operate the Service or our business,
          such as hosting, information technology, email delivery and website
          analytics.
          <br />
          <b className='font-bold'>Authorities and others.</b> Law enforcement,
          government authorities, and private parties, as we believe in good
          faith to be necessary or appropriate for the compliance and protection
          purposes described above.
        </p>
        <SubHeading text='Your choices' />
        <p className='text-slate-700 text-lg leading-normal font-normal mb-12'>
          You have the following choices with respect to your personal
          information.
          <br />
          <b className='font-bold'>Opt-out of marketing communications.</b> You
          may opt out of marketing-related emails by following the opt-out or
          unsubscribe instructions provided in communications.
          <br />
          <b className='font-bold'>Cookies.</b> Most browsers let you remove
          and/or stop accepting cookies from the websites you visit. To do this,
          follow the instructions in your browser’s settings. Many browsers
          accept cookies by default until you change your settings. If you do
          not accept cookies, however, you may not be able to use all
          functionality of the Service and the Service may not work properly.
          For more information about cookies, including how to see what cookies
          have been set on your browser and how to manage and delete them, visit{" "}
          <a
            className='text-blue-600 font-normal'
            href='https://allaboutcookies.org'
            target='_blank'
            rel='noreferrer'
          >
            www.allaboutcookies.org.
          </a>
          <br />
          <b className='font-bold'>Declining to provide information.</b> We need
          to collect personal information to provide certain services. If you do
          not provide the information requested, we may not be able to provide
          those services.
        </p>
        <SubHeading text='Other sites and services' />
        <p className='text-slate-700 text-lg leading-normal font-normal mb-12'>
          The Service may contain links to websites and other online services
          operated by third parties. These links are not an endorsement of, or
          representation that we are affiliated with, any third party. In
          addition, our content may be included on web pages or in mobile
          applications or other online services that are not associated with us.
          We do not control websites, mobile applications or online services
          operated by third parties, and we are not responsible for their
          actions.
        </p>
        <SubHeading text='Security' />
        <p className='text-slate-700 text-lg leading-normal font-normal mb-12'>
          We employ a number of technical, organizational and physical
          safeguards designed to protect the personal information we collect.
          However, security risk is inherent in all internet and information
          technologies and we cannot guarantee the security of your personal
          information.
        </p>
        <SubHeading text='International data transfer' />
        <p className='text-slate-700 text-lg leading-normal font-normal mb-12'>
          We are headquartered in the United States. Your personal information
          may be transferred to the United States where privacy laws may not be
          as protective as those in your state, province, or country.
        </p>
        <SubHeading text='How to contact us' id='contact-us-section' />
        <p className='text-slate-700 text-lg leading-normal font-normal mb-12'>
          <b className='font-bold'>Email: </b>
          <a
            className='text-blue-600 font-normal'
            href='mailto:privacy@risklens.com'
          >
            privacy@risklens.com
          </a>
          <br />
          <b className='font-bold'>Mail: </b>
          <br />
          RiskLens Privacy
          <br />
          601 W. Main Ave, Suite 910
          <br />
          Spokane, WA 99201
          <br />
        </p>
        <DataTransferTable />
        <SubHeading text='Sensitive personal information' />
        <p className='text-slate-700 text-lg leading-normal font-normal mb-12'>
          We ask that you not provide us with any sensitive personal information
          (e.g., social security numbers, information related to racial or
          ethnic origin, political opinions, religion or other beliefs, health,
          biometrics or genetic characteristics, criminal background or trade
          union membership) on or through the Service, or otherwise to us. If
          you do not consent to our processing and use of such sensitive
          personal information, you must not submit such sensitive personal
          information through our Service.
        </p>
        <SubHeading text='Retention' />
        <p className='text-slate-700 text-lg leading-normal font-normal mb-12'>
          We retain personal information where we have an ongoing legitimate
          business need to do so (for example, to provide you with a service you
          have requested; to comply with applicable legal, tax or accounting
          requirements; to establish or defend legal claims; or for fraud
          prevention). When we have no ongoing legitimate business need to
          process your personal information, we will either delete or anonymize
          it or, if this is not possible (for example, because your personal
          information has been stored in backup archives), then we will securely
          store your personal information and isolate it from any further
          processing until deletion is possible. We will also retain Usage Data
          for internal analysis purposes. Usage Data is generally retained for a
          shorter period of time, except when this data is used to strengthen
          the security or to improve the functionality of the Service, or We are
          legally obligated to retain this data for longer time periods.
        </p>
        <SubHeading text='Your rights' />
        <p className='text-slate-700 text-lg leading-normal font-normal mb-12'>
          European data protection laws give you certain rights regarding your
          personal information. If you are located within the United Kingdom or
          European Economic Area, you may ask us to take the following actions
          in relation to your personal information that we hold:
          <br />
          <li className='pl-8 list-inside'>
            <b className='font-bold'>Access.</b> Provide you with information
            about our processing of your personal information and give you
            access to your personal information.
          </li>
          <li className='pl-8 list-inside'>
            <b className='font-bold'>Correct.</b> Update or correct inaccuracies
            in your personal information.
          </li>
          <li className='pl-8 list-inside'>
            <b className='font-bold'>Delete.</b> Delete your personal
            information.
          </li>
          <li className='pl-8 list-inside'>
            <b className='font-bold'>Transfer.</b> Transfer a machine-readable
            copy of your personal information to you or a third party of your
            choice.
          </li>
          <li className='pl-8 list-inside'>
            <b className='font-bold'>Restrict.</b> Restrict the processing of
            your personal information.
          </li>
          <li className='pl-8 list-inside'>
            <b className='font-bold'>Object.</b> Object to our reliance on our
            legitimate interests as the basis of our processing of your personal
            information that impacts your rights.
          </li>
          <br />
          <br />
          You may submit these requests by email to{" "}
          <a
            className='text-blue-600 font-normal'
            href='mailto:privacy@risklens.com'
          >
            privacy@risklens.com
          </a>{" "}
          or our postal address provided above in the{" "}
          <a className='text-blue-600 font-normal' href='#contact-us-section'>
            How to contact us
          </a>{" "}
          section. We may request specific information from you to help us
          confirm your identity and process your request. Applicable law may
          require or permit us to decline your request. If we decline your
          request, we will tell you why, subject to legal restrictions. If you
          would like to submit a complaint about our use of your personal
          information or our response to your requests regarding your personal
          information, you may{" "}
          <a
            href='mailto:support@risklens.com?subject=RiskLens Industry Report Feedback'
            className='text-blue-600 font-normal'
          >
            contact us
          </a>{" "}
          or submit a complaint to the data protection regulator in your
          jurisdiction. You can find your data protection regulator{" "}
          <a href='#' className='text-blue-600 font-normal'>
            here.
          </a>
        </p>
        <SubHeading text='Cross-Border Data Transfer' />
        <p className='text-slate-700 text-lg leading-normal font-normal mb-12'>
          If we transfer your personal information from the United Kingdom or
          European Economic Area to a country such that we are required to apply
          additional safeguards to your personal information under European data
          protection laws, we will do so.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
