// imports
import React from "react";

// assets
import SystemIntrusionImg from "./assets/system-intrusion.svg";
import InsiderMisuseImg from "./assets/insider-misuse.svg";
import DenialOfServiceImg from "./assets/denial-of-service.svg";
import ErrorImg from "./assets/error.svg";
import RansomwareImg from "./assets/ransomware.svg";
import SocialEngineeringImg from "./assets/social-engineering.svg";
import WebApplicationImg from "./assets/web-application-attack.svg";

const CardHeader = ({ title, staticData }) => {
  const selectIcon = () => {
    switch (title) {
      case "Error":
        return (
          <img
            src={ErrorImg}
            alt={title}
            className='text-primary w-9	h-8 mr-4 opacity-60'
          />
        );
      case "Insider Misuse":
        return (
          <img
            src={InsiderMisuseImg}
            alt={title}
            className='text-primary w-9	h-8 mr-4 opacity-60'
          />
        );
      case "System Intrusion":
        return (
          <img
            src={SystemIntrusionImg}
            alt={title}
            className='text-primary w-9	h-8 mr-4 opacity-60'
          />
        );
      case "Ransomware":
        return (
          <img
            src={RansomwareImg}
            alt={title}
            className='text-primary w-9	h-8 mr-4 opacity-60'
          />
        );
      case "Denial of Service":
        return (
          <img
            src={DenialOfServiceImg}
            alt={title}
            className='text-primary w-9	h-8 mr-4 opacity-60'
          />
        );
      case "Basic Web Application Attacks":
        return (
          <img
            src={WebApplicationImg}
            alt={title}
            className='text-primary w-9	h-8 mr-4 opacity-60'
          />
        );
      case "Social Engineering (Phishing)":
        return (
          <img
            src={SocialEngineeringImg}
            alt={title}
            className='text-primary w-9	h-8 mr-4 opacity-60'
          />
        );
      default:
        return (
          <img
            src={ErrorImg}
            alt={title}
            className='text-primary w-9	h-8 mr-4 opacity-60'
          />
        );
    }
  };

  const selectTitle = () => {
    switch (title) {
      case "Error":
        return "Insider Error";
      case "Insider Misuse":
        return "Insider Misuse";
      case "System Intrusion":
        return "System Intrusion";
      case "Ransomware":
        return "Ransomware";
      case "Denial of Service":
        return "Denial of Service";
      case "Basic Web Application Attacks":
        return "Basic Web Application Attacks";
      case "Social Engineering (Phishing)":
        return "Social Engineering";
      default:
        return "Insider Error";
    }
  };

  return (
    <div>
      <div className='w-100 flex items-center mb-8'>
        {selectIcon()}
        <span>
          <h1 className='font-light text-primary text-3xl'>
            {selectTitle(title)}
          </h1>
          <p className='text-sm text-secondary'>{staticData.sub_title}</p>
        </span>
      </div>
    </div>
  );
};

export default CardHeader;
