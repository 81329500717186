// imports
import { View, Text, Image, StyleSheet } from "@react-pdf/renderer";

// utils
import { formatNumber } from "../../util/helpers";

const ReportCardChart = ({ data, image, light, benchmark }) => {
  // styles
  const styles = StyleSheet.create({
    doc: {
      marginBottom: 4,
      width: "50%",
    },
    chart: {
      display: "flex",
      flexDirection: "row",
    },
    image: {
      width: 80,
    },
    wrap: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    main: {
      fontSize: 10,
      marginBottom: 4,
      color: light ? "#94A3B8" : "#1A2B3C",
    },
    secondary: {
      fontSize: 8,
      marginBottom: 4,
      color: light ? "#94A3B8" : "#1A2B3C",
    },
    cost: {
      fontSize: 14,
      fontWeight: "bold",
      color: light ? "#94A3B8" : "#1A2B3C",
    },
    subtitle: {
      fontSize: 8,
      marginTop: 6,
      color: light ? "#94A3B8" : "#1A2B3C",
      marginBottom: 8,
    },
  });
  return (
    <View style={styles.doc}>
      {!benchmark ? (
        <Text style={styles.subtitle}>Industry</Text>
      ) : (
        <Text style={styles.subtitle}>Benchmark</Text>
      )}
      <View style={styles.chart}>
        {image && <Image src={image} style={styles.image} />}
        <View style={styles.wrap}>
          <View>
            <Text style={styles.main}>of experiencing</Text>
            <Text style={styles.secondary}>an event that would cost</Text>
            <Text style={styles.cost}>${formatNumber(data.Loss[0].Value)}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default ReportCardChart;
