// imports
import React from "react";
import KSUID from "ksuid";

// styles
import styles from "./styles.module.css";

// components
import Chart from "./Chart";
import CardBtn from "../../common/CardBtn";

// utils
import { formatNumber } from "../../../util/helpers";

// assets
import BlurDark from "./assets/blur-dark.svg";
import BlurLight from "./assets/blur-light.svg";

const Item = ({ data, staticData, blur, light, type }) => {
  return (
    <div className={`flex-grow ${light ? "text-secondary" : "text-primary"}`}>
      <h3 className='mb-3 text-xs leading-normal'>{staticData.name}</h3>
      <div className={`flex flex-row gap-6 items-center relative`}>
        {blur && (
          <div
            className={`${styles.find_out_more} absolute inset-0 flex items-center justify-center z-10`}
          >
            <CardBtn />
          </div>
        )}
        {blur ? (
          <img src={light ? BlurLight : BlurDark} alt='blurred-chart' />
        ) : (
          <>
            <Chart
              id={KSUID.randomSync().string}
              probability={data.Probability[0].Value}
              format={data.Probability[0].Format}
              light={light}
              data={data}
              type={type}
            />
            <div>
              <h5 className='text-3xl leading-normal'>of experiencing</h5>
              <h6 className='text-lg leading-normal'>
                an event that would cost
              </h6>
              <h1 className='text-6xl flex items-center font-bold'>
                <span className='mr-1 font-extralight'>
                  {data?.Loss[0].Format === "dollar" && "$"}
                </span>
                {formatNumber(data?.Loss[0].Value).slice(0, -1)}
                <span className='ml-1 leading-normal font-thin text-5xl font-normal'>
                  {formatNumber(data?.Loss[0].Value).slice(
                    formatNumber(data?.Loss[0].Value).length - 1,
                    formatNumber(data?.Loss[0].Value).length
                  )}
                </span>
              </h1>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Item;
