// imports
import React, { useRef } from "react";

// icons
import { CheckIcon } from "@heroicons/react/outline";

const Item = ({
	light,
	item,
	field,
	state,
	setState,
	focus,
	index,
	report,
}) => {
	var ref = useRef(null);

	const handleSelect = () => {
		setState(item);
		localStorage.setItem(`${report}${field}Id`, item[`${field}Id`]);
		localStorage.setItem(`${report}${field}Name`, item[`${field}Name`]);
	};

	return (
		<div
			index={index}
			focus={focus === index ? "true" : "false"}
			role="button"
			ref={ref}
			onClick={handleSelect}
			onKeyPress={handleSelect}
			className={`flex items-center justify-between py-2 px-3 text-sm text-white ${
				state[`${field}Id`] === item[`${field}Id`] && "font-semibold"
			} leading-5`}>
			<span className={light ? "text-gray-500" : "text-white"}>
				{item[`${field}Name`]}
			</span>
			{state[`${field}Id`] === item[`${field}Id`] && (
				<CheckIcon className="text-blue-300 w-5" />
			)}
		</div>
	);
};

export default Item;
