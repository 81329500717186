// imports
import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ transparent }) => {
  return (
    <div className={`${!transparent && "bg-slate-100"} p-12`}>
      <div className='mb-8 flex items-center justify-center'>
        <Link to='/industry/report' className='mr-10 text-base text-slate-500'>
          Report
        </Link>
        <Link to='/terms-of-use' className='mr-10 text-base text-slate-500'>
          Terms of Service
        </Link>
        <Link to='/privacy-policy' className='mr-10 text-base text-slate-500'>
          Privacy Policy
        </Link>
        <a
          href='mailto:support@risklens.com?subject=RiskLens Industry Report Feedback'
          className='text-base text-slate-500'
        >
          Contact Us
        </a>
      </div>
      <p className='text-slate-400 text-base leading-6 text-center cursor-default'>
        © 2022 RiskLens, Inc. All rights reserved.
      </p>
    </div>
  );
};

export default Footer;
