// imports
import { useQuery } from "react-query";
import { API } from "aws-amplify";

// constants
import { BASE_URL, ENDPOINTS } from "./constants";

// request keys
export const RequestKeys = {
  benchmarkFilter: "BENCHMARK_FILTER",
  industryFilter: "INDUSTRY_FILTER",
  industryReport: "INDUSTRY_REPORT",
  benchmarkReport: "BENCHMARK_REPORT",
};

// get benchmark filters request
export const useGetBenchmarkFilter = () => {
 let filterData = useQuery(RequestKeys.benchmarkFilter, async () =>
    (await fetch(`${BASE_URL}${ENDPOINTS.benchmarkFilter}`)).json()
  );

  // Sort employees filter data
  filterData?.data?.employees.sort((a, b) => {
    let cleanA = a.employeesName.split(" ")[0].replace(',', '').replace('+', '');
    let cleanB = b.employeesName.split(" ")[0].replace(',', '').replace('+', '');
    return parseInt(cleanA) - parseInt(cleanB);
  });

  // Sort records filter data
  filterData?.data?.records.sort((a,b) => {
    return a.sort - b.sort; 
  });

  // Sort revenue filter data
  filterData?.data?.revenues.sort((a,b) => {
    return a.sort - b.sort;
  });

  return filterData;
}


// get industry filters request
export const useGetIndustryFilter = () => {
  let filterData =  useQuery(RequestKeys.industryFilter, async () =>
    (await fetch(`${BASE_URL}${ENDPOINTS.industryFilter}`)).json()
  );

  // Sort employeees filter data
  filterData?.data?.employees.sort((a, b) => {
    let cleanA = a.employeesName.split(" ")[0].replace(',', '').replace('+', '');
    let cleanB = b.employeesName.split(" ")[0].replace(',', '').replace('+', '');
    return parseInt(cleanA) - parseInt(cleanB);
  });

  return filterData;
}


// get industry request
export const useGetIndustryReport = ({
  email,
  industryId,
  regionId,
  employeesId,
}) => {
  return useQuery(
    [RequestKeys.industryReport, email, industryId, regionId, employeesId],
    async () =>
      (
        await fetch(
          `${BASE_URL}${ENDPOINTS.industryReport}${email}/${industryId}/${regionId}/${employeesId}`
        )
      ).json()
  );
};

// get benchmark request
export const useGetBenchmarkReport = ({
  email,
  industryId,
  regionId,
  employeesId,
  revenueId,
  recordsId,
  recordTypesId,
  SSCId,
}) => {

  return useQuery(
    [
      RequestKeys.benchmarkReport,
      email,
      industryId,
      regionId,
      employeesId,
      revenueId,
      recordsId,
      recordTypesId,
      SSCId,
    ],
    async () => {
      var reports = await API.get("mainStreet", `${ENDPOINTS.benchmarkReport}${email}/${industryId}/${regionId}/${employeesId}/${revenueId}/${recordsId}/${recordTypesId}/${SSCId}`, {response: true});
      return reports.data;
    }
  );
}

