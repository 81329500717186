import moment from "moment";
export function formatNumber(num) {

	return Math.abs(Number(num)) >= (1.0e9).toFixed(2)
		? (Math.abs(Number(num)) / 1.0e9).toFixed(1) + "B"
		: Math.abs(Number(num)) >= 1.0e6
		? (Math.abs(Number(num)) / 1.0e6).toFixed(1) + "M"
		: Math.abs(Number(num)) >= 1.0e3
		? (Math.abs(Number(num)) / 1.0e3).toFixed(1) + "K"
		: Math.abs(Number(num)).toFixed(2);
}

export function formatPercentage(num) {
	if (100 * num < 0.1) {
		return "<0.1";
	} else {
		return (100 * num).toFixed(1);
	}
}

export function getQuarterYYYYAfter15(datetime) {
	const quarter =
		"Q" + moment(new Date(datetime)).add(15, "days").quarter().toString();
	const year = moment(new Date(datetime))
		.add(15, "days")
		.format("yyyy")
		.toString();
	return `${quarter} ${year}`;
}
