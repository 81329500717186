// imports
import React, { useContext } from "react";
import { getQuarterYYYYAfter15 } from "../../util/helpers";

// components
import Navbar from "../../components/common/Navbar";
import Card from "../../components/Card";
import Filter from "../../components/common/Filter";
import PageHeading from "../../components/common/PageHeading";
import LearnIndustryComponent from "../../components/LearnIndustryComponent";
import LearnMore from "../../components/LearnMore";
import Footer from "../../components/common/Footer";
import IndustryPDFReport from "../../components/IndustryPDFReport";
import SocialShareCard from "../../components/SocialShareCard";
import SlideOver from "../../components/SlideOver";
import HeaderBanner from "../../components/HeaderBanner";

// fake data
import {
	denialOfServiceCardDataIndustry,
	errorCardDataIndustry,
	insiderMisuseCardDataIndustry,
	ransomwareCardDataIndustry,
	socialEngineeringCardDataIndustry,
	systemIntrusionCardDataIndustry,
	webApplicationCardDataIndustry,
} from "../../util/fakedata";

// api
import { useGetIndustryFilter, useGetIndustryReport } from "../../util/api";

// context
import Context from "../../context";

const Industry = () => {
	// states
	const [showBanner, setShowBanner] = React.useState(true);

	// context
	const { industry, region, employee, industryEmail, showIndustryPdf } =
		useContext(Context);

	// get filter data hook
	const {
		data: filterData,
		isLoading: filterLoading,
		error: filterError,
	} = useGetIndustryFilter();

	// get report data hook
	const {
		data: reportData,
		isLoading: reportLoading,
		error: reportError,
	} = useGetIndustryReport({
		email: industryEmail,
		industryId: industry.industryId,
		regionId: region.regionId,
		employeesId: employee.employeesId,
	});

	const staticData = (_theme) => {
		switch (_theme) {
			case "Basic Web Application Attacks":
				return webApplicationCardDataIndustry;
			case "Denial of Service":
				return denialOfServiceCardDataIndustry;
			case "Error":
				return errorCardDataIndustry;
			case "Insider Misuse":
				return insiderMisuseCardDataIndustry;
			case "Ransomware":
				return ransomwareCardDataIndustry;
			case "Social Engineering (Phishing)":
				return socialEngineeringCardDataIndustry;
			case "System Intrusion":
				return systemIntrusionCardDataIndustry;
			default:
				return errorCardDataIndustry;
		}
	};

	return (
		<div className="bg-slate-100">
			<div className="mx-auto">
				{showBanner && <HeaderBanner setShowBanner={setShowBanner} />}
				<Navbar active="industry" />
				<div className="py-6 px-4">
					<PageHeading
						heading={industry.industryName ?? "Loading"}
						subtitle="Financial Impact of Top Cyber Events"
						date={
							reportData?.length > 0
								? getQuarterYYYYAfter15(reportData[0].DataLastUpdated)
								: "---- ----"
						}
					/>
					<div className="flex justify-between">
						<div className="pr-4">
							{reportError ? (
								<p>An error has occurred</p>
							) : reportLoading ? (
								<p>Loading...</p>
							) : !reportData ? (
								<p>An error has occurred</p>
							) : reportData.length === 0 ? (
								<p>There is no data to show.</p>
							) : (
								<div className="flex flex-col">
									{reportData
										?.sort((a, b) => a.ThemeExposureRank - b.ThemeExposureRank)
										?.map((item, index) => (
											<Card
												key={index}
												industryData={item}
												staticData={staticData(item.Theme)}
												order={index}
												blur={false}
											/>
										))}
									<LearnMore
										order={9}
										report={reportData?.length > 0 && reportData[0].Report}
									/>
									<LearnIndustryComponent order={3} />
									<SocialShareCard order={0} />
								</div>
							)}
						</div>
						<Filter
							data={reportData && reportData[0]}
							industries={
								filterData?.industries?.sort((a, b) =>
									a.industryName.localeCompare(b.industryName),
								) ?? []
							}
							regions={filterData?.regions ?? []}
							employees={filterData?.employees ?? []}
							loading={filterLoading}
							error={filterError}
						/>
					</div>
				</div>
				<Footer />
			</div>
			{showIndustryPdf && (
				<IndustryPDFReport
					data={reportData}
					isLoading={reportLoading}
					error={reportError}
				/>
			)}
			<SlideOver />
		</div>
	);
};

export default Industry;
