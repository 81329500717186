// imports
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// utils
import { formatNumber } from "../../util/helpers";

// styles
const styles = StyleSheet.create({
	desc: {
		marginBottom: 16,
	},
	textOne: {
		fontSize: 9,
		marginBottom: 8,
		color: "#1A2B3C",
	},
	textEnd: {
		fontSize: 9,
		color: "#1A2B3C",
	},
	bold: {
		fontWeight: "bold",
		color: "#1A2B3C",
	},
});

const ReportCardBenchmarkLossDynamicText = ({
	industryData,
	benchmarkData,
	industry,
}) => {
	return (
		<View style={styles.desc}>
			<Text style={styles.textEnd}>
				{benchmarkData.Theme === "Denial of Service" ? (
					<Text style={styles.textOne}>
						The estimated {benchmarkData.Theme} event cost for the {industry}{" "}
						industry (
						<Text style={styles.bold}>
							${formatNumber(industryData.Loss[0].Value)}
						</Text>
						) is driven by two loss categories: incident management (
						<Text style={styles.bold}>
							${formatNumber(industryData.ResponseCosts[0].Value)}
						</Text>
						) and lost revenue (
						<Text style={styles.bold}>
							${formatNumber(industryData.LostRevenue[0].Value)}
						</Text>
						). A {benchmarkData.Theme} event at your company shares the same two
						loss drivers, with the following breakdown: incident management (
						<Text style={styles.bold}>
							${formatNumber(benchmarkData.ResponseCosts[0].Value)}
						</Text>
						) and lost revenue (
						<Text style={styles.bold}>
							${formatNumber(benchmarkData.LostRevenue[0].Value)}
						</Text>
						).
					</Text>
				) : benchmarkData.DataBreachProbability[0].Value >
				  benchmarkData.BusinessInterruptionProbability[0].Value ? (
					<Text style={styles.textOne}>
						The estimated {benchmarkData.Theme} event cost for the {industry}{" "}
						industry (
						<Text style={styles.bold}>
							${formatNumber(industryData.Loss[0].Value)}
						</Text>
						) is driven by three loss categories: incident management (
						<Text style={styles.bold}>
							${formatNumber(industryData.ResponseCosts[0].Value)}
						</Text>
						), lost revenue (
						<Text style={styles.bold}>
							${formatNumber(industryData.LostRevenue[0].Value)}
						</Text>
						), and fines and judgements (
						<Text style={styles.bold}>
							${formatNumber(industryData.Fines[0].Value)}
						</Text>
						). The likelihood of fines and judgements occurring in an event is
						based on the event severity, applicable regulations, and company
						history, among other factors. The fines and judgements value shown
						below capture both the probability of fines and judgements occurring
						in this type of event (
						<Text style={styles.bold}>
							{(100 * industryData.FinesProbability[0].Value).toFixed(2)}%
						</Text>
						) as well as the cost (
						<Text style={styles.bold}>
							${formatNumber(industryData.FinesAmount[0].Value)}
						</Text>
						). A {benchmarkData.Theme} event at your company shares the same
						three loss drivers, with the following breakdown: incident
						management (
						<Text style={styles.bold}>
							${formatNumber(benchmarkData.ResponseCosts[0].Value)}
						</Text>
						), lost revenue (
						<Text style={styles.bold}>
							${formatNumber(benchmarkData.LostRevenue[0].Value)}
						</Text>
						), and fines and judgements (
						<Text style={styles.bold}>
							${formatNumber(benchmarkData.Fines[0].Value)}
						</Text>
						). The probability of fines and judgements occurring in this type of
						event is{" "}
						<Text style={styles.bold}>
							{(100 * benchmarkData.FinesProbability[0].Value).toFixed(2)}%
						</Text>
						, with an estimated cost of{" "}
						<Text style={styles.bold}>
							${formatNumber(benchmarkData.FinesAmount[0].Value)}
						</Text>
					</Text>
				) : (
					<Text style={styles.textOne}>
						The estimated {benchmarkData.Theme} event cost for the {industry}{" "}
						industry (
						<Text style={styles.bold}>
							{(100 * industryData.Probability[0].Value).toFixed(2)}%
						</Text>
						) is driven by three loss categories: incident management (
						<Text style={styles.bold}>
							${formatNumber(industryData.ResponseCosts[0].Value)}
						</Text>
						), lost revenue (
						<Text style={styles.bold}>
							${formatNumber(industryData.LostRevenue[0].Value)}
						</Text>
						), and fines and judgements (
						<Text style={styles.bold}>
							${formatNumber(industryData.Fines[0].Value)}
						</Text>
						). The likelihood of fines and judgements occurring in an event is
						based on the event severity, applicable regulations, and company
						history, among other factors. The fines and judgements value shown
						below capture both the probability of fines and judgements occurring
						in this type of event (
						<Text style={styles.bold}>
							{(100 * industryData.FinesProbability[0].Value).toFixed(2)}%
						</Text>
						) as well as the cost (
						<Text style={styles.bold}>
							${formatNumber(industryData.FinesAmount[0].Value)}
						</Text>
						). A {benchmarkData.Theme} event at your company shares the same
						three loss drivers, with the following breakdown: incident
						management (
						<Text style={styles.bold}>
							${formatNumber(benchmarkData.ResponseCosts[0].Value)}
						</Text>
						), lost revenue (
						<Text style={styles.bold}>
							${formatNumber(benchmarkData.LostRevenue[0].Value)}
						</Text>
						), and fines and judgements (
						<Text style={styles.bold}>
							${formatNumber(benchmarkData.Fines[0].Value)}
						</Text>
						). The probability of fines and judgements occurring in this type of
						event is{" "}
						<Text style={styles.bold}>
							{(100 * benchmarkData.FinesProbability[0].Value).toFixed(2)}%
						</Text>
						, with an estimated cost of{" "}
						<Text style={styles.bold}>
							${formatNumber(benchmarkData.FinesAmount[0].Value)}
						</Text>
					</Text>
				)}
			</Text>
		</View>
	);
};

export default ReportCardBenchmarkLossDynamicText;
