// imports
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// styles
const styles = StyleSheet.create({
	desc: {
		marginBottom: 16,
	},
	textOne: {
		fontSize: 9,
		marginBottom: 8,
		color: "#1A2B3C",
	},
	textEnd: {
		fontSize: 9,
		color: "#1A2B3C",
	},
	bold: {
		fontWeight: "bold",
		color: "#1A2B3C",
	},
});

const ReportCardBenchmarkProbabilityDynamicText = ({
	industryData,
	benchmarkData,
	industry,
}) => {
	return (
		<View style={styles.desc}>
			<Text style={styles.textEnd}>
				{benchmarkData.Theme === "Denial of Service" ? (
					<Text></Text>
				) : benchmarkData.DataBreachProbability[0].Value >
				  benchmarkData.BusinessInterruptionProbability[0].Value ? (
					<Text style={styles.textOne}>
						When looking more specifically at the resulting impact, data breach{" "}
						{benchmarkData.Theme} events are more likely to occur in the{" "}
						{industry} industry, with an estimated{" "}
						<Text style={styles.bold}>
							{(100 * industryData.DataBreachProbability[0].Value).toFixed(2)}%
						</Text>{" "}
						probability of occurrence per year, with business interruption{" "}
						{benchmarkData.Theme} events estimated at{" "}
						<Text style={styles.bold}>
							{(
								100 * industryData.BusinessInterruptionProbability[0].Value
							).toFixed(2)}
							%
						</Text>{" "}
						probability per year. Comparatively, your company has an estimated{" "}
						<Text style={styles.bold}>
							{(100 * benchmarkData.DataBreachProbability[0].Value).toFixed(2)}%
						</Text>{" "}
						and{" "}
						<Text style={styles.bold}>
							{(
								100 * benchmarkData.BusinessInterruptionProbability[0].Value
							).toFixed(2)}
							%
						</Text>{" "}
						probability of data breach and business interruption{" "}
						{benchmarkData.Theme} events occurring per year, respectively.
					</Text>
				) : (
					<Text style={styles.textOne}>
						When looking more specifically at the resulting impact, business
						interruption {benchmarkData.Theme} events are more likely to occur
						in the {industry} industry, with an estimated{" "}
						<Text style={styles.bold}>
							{(
								100 * industryData.BusinessInterruptionProbability[0].Value
							).toFixed(2)}
							%
						</Text>{" "}
						probability of occurrence per year, with data breach{" "}
						{benchmarkData.Theme} events estimated at{" "}
						<Text style={styles.bold}>
							{(100 * industryData.DataBreachProbability[0].Value).toFixed(2)}%
						</Text>{" "}
						probability per year. Comparatively, your company has an estimated{" "}
						<Text style={styles.bold}>
							{(
								100 * benchmarkData.BusinessInterruptionProbability[0].Value
							).toFixed(2)}
							%
						</Text>{" "}
						and{" "}
						<Text style={styles.bold}>
							{(100 * benchmarkData.DataBreachProbability[0].Value).toFixed(2)}%
						</Text>{" "}
						probability of business interruption and data breach{" "}
						{benchmarkData.Theme} events occurring per year, respectively.
					</Text>
				)}
			</Text>
		</View>
	);
};

export default ReportCardBenchmarkProbabilityDynamicText;
