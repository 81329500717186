// imports
import React from "react";

// styles
import styles from "./styles.module.css";

const DataTransferTable = () => {
  return (
    <div className='mb-12'>
      <h6 className='text-slate-900 text-3xl leading-normal font-bold'>
        Information regarding the European Economic Area
      </h6>
      <p className='text-slate-700 text-lg leading-normal font-normal mb-8'>
        <b className='font-bold'>Notice to European users</b>
        <br />
        This section applies only to individuals in the United Kingdom and the
        European Economic Area.
        <br />
        <b className='font-bold'>Personal information.</b> References to
        “personal information” in this Privacy Policy are equivalent to
        “personal data” governed by European data protection legislation.
        <br />
        <b className='font-bold'>Controller.</b> RiskLens is the controller of
        your personal information covered by this Privacy Policy for purposes of
        European data protection legislation.
        <br />
        <b className='font-bold'>Legal bases for processing.</b> The legal bases
        of our processing of your personal information as described in this
        Privacy Policy will depend on the type of personal information and the
        specific context in which we process it. However, the legal bases we
        typically rely on are set out in the table below. We rely on our
        legitimate interests as our legal basis only where those interests are
        not overridden by the impact on you (unless we have your consent or our
        processing is otherwise required or permitted by law). If you have
        questions about the legal basis of how we process your personal
        information, contact us at{" "}
        <a
          className='text-blue-600 font-normal'
          href='mailto:privacy@risklens.com'
        >
          privacy@risklens.com
        </a>
      </p>
      <div
        className={`${styles.table} flex rounded-lg border border-gray-200 flex-wrap`}
      >
        <div className='flex-grow flex-shrink-0 w-3/6 flex flex-col justify-center items-center bg-gray-50 py-3 px-6 border-b border-gray-200'>
          <h6 className='text-slate-700 text-xs leading-normal font-bold'>
            PROCESSING PURPOSE
          </h6>
          <p className='text-center text-slate-700 text-xs leading-normal'>
            Details regarding each processing purpose listed below are provided
            in the section above titled “How we use your personal information”.
          </p>
        </div>
        <div className='flex-grow flex-shrink-0 w-3/6 flex flex-col justify-center items-center bg-gray-50 py-3 px-6 border-b border-gray-200'>
          <h6 className='text-slate-700 text-xs leading-normal font-bold'>
            LEGAL BASIS
          </h6>
        </div>
        <div className='flex-grow flex-shrink-0 w-3/6 flex flex-col justify-center py-4 px-6 border-b border-gray-200'>
          <h6 className='text-gray-900 text-sm leading-normal font-bold'>
            Service delivery
          </h6>
        </div>
        <div className='flex-grow flex-shrink-0 w-3/6 flex flex-col justify-center py-4 px-6 border-b border-gray-200'>
          <p className='text-gray-500 text-sm leading-normal font-light'>
            Processing is necessary to perform the contract governing our
            provision of the Service.
            <br />
            Where we cannot process your personal data as required to operate
            the Service on the grounds of contractual necessity, we process your
            personal information for this purpose based on our legitimate
            interest in providing you with the Service you access and request.
          </p>
        </div>
        <div className='flex-grow flex-shrink-0 w-3/6 flex flex-col justify-center py-4 px-6 border-b border-gray-200'>
          <h6 className='text-gray-900 text-sm leading-normal font-bold'>
            Research and development
            <br />
            Compliance and protection
          </h6>
        </div>
        <div className='flex-grow flex-shrink-0 w-3/6 flex flex-col justify-center py-4 px-6 border-b border-gray-200'>
          <p className='text-gray-500 text-sm leading-normal font-light'>
            These activities constitute our legitimate interests.
          </p>
        </div>
        <div className='flex-grow flex-shrink-0 w-3/6 flex flex-col justify-center py-4 px-6 border-b border-gray-200'>
          <h6 className='text-gray-900 text-sm leading-normal font-bold'>
            Compliance with legal obligations
          </h6>
        </div>
        <div className='flex-grow flex-shrink-0 w-3/6 flex flex-col justify-center py-4 px-6 border-b border-gray-200'>
          <p className='text-gray-500 text-sm leading-normal font-light'>
            Processing is necessary to comply with our legal obligations.
          </p>
        </div>
        <div className='flex-grow flex-shrink-0 w-3/6 flex flex-col justify-center py-4 px-6 border-b border-gray-200'>
          <h6 className='text-gray-900 text-sm leading-normal font-bold'>
            Actions we take with your consent
          </h6>
        </div>
        <div className='flex-grow flex-shrink-0 w-3/6 flex flex-col justify-center py-4 px-6 border-b border-gray-200'>
          <p className='text-gray-500 text-sm leading-normal font-light'>
            Processing is based on your consent. Where we rely on your consent
            you have the right to withdraw it any time in the manner indicated
            when you consent or in the Service.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DataTransferTable;
