// imports
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const ReportCardProbability = ({ data, benchmark, light }) => {
	// styles
	const styles = StyleSheet.create({
		probability: {
			marginTop: 4,
			width: "50%",
		},
		subtitle: {
			fontSize: 8,
			marginTop: 6,
			color: light ? "#94A3B8" : "#1A2B3C",
		},
		wrap: {
			display: "flex",
			marginTop: 8,
			flexDirection: "column",
			justifyContent: "flex-start",
			alignItems: "flex-start",
		},
		percent: {
			fontSize: 14,
			fontWeight: "extrabold",
			marginBottom: 2,
			color: light ? "#94A3B8" : "#1A2B3C",
		},
		text: {
			fontSize: 10,
			fontWeight: "extrabold",
			color: light ? "#94A3B8" : "#1A2B3C",
		},
		content: {
			marginBottom: 8,
		},
	});

	return (
		<View style={styles.probability}>
			{!benchmark ? (
				<Text style={styles.subtitle}>Industry</Text>
			) : (
				<Text style={styles.subtitle}>Benchmark</Text>
			)}
			<View style={styles.wrap}>
				<View style={styles.content}>
					<Text style={styles.percent}>
						{(100 * data.DataBreachProbability[0].Value).toFixed(2)}%
					</Text>
					<Text style={styles.text}>Data Breach</Text>
				</View>
				<View style={styles.content}>
					<Text style={styles.percent}>
						{(100 * data.BusinessInterruptionProbability[0].Value).toFixed(2)}%
					</Text>
					<Text style={styles.text}>Business Interruption</Text>
				</View>
			</View>
		</View>
	);
};

export default ReportCardProbability;
