import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAppContext } from "../lib/contextLib";

export default function AuthenticatedRoute({ children, ...rest }) {
	const { isAuthenticated } = useAppContext();

	return (
		<Route {...rest}>
			{isAuthenticated ? (
				children
			) : (
				<Redirect to={`/benchmarkSignIn/${window.location.search}`} />
			)}
		</Route>
	);
}
